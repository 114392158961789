import React, { useState, useEffect } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { ClipLoader } from 'react-spinners';
import { RootState, useAppDispatch } from '../../../store';
import { createVerificationSession } from '../../../store/actions/StripeActions';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../shared/TailwindComponents/Button';
import { userInformation } from '../../../store/actions/DataAction';


interface StripeVerificationButtonProps {
    stripePromise: any
}

const VerifyButton: React.FC<StripeVerificationButtonProps> = ({ stripePromise }) => {
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch()
    const userInfo = useSelector((state: RootState) => state.user.userInfo)
    const {t} = useTranslation()
    useEffect(() => {
        const loadStripeAsync = async () => {
            const stripeInstance = await stripePromise;
            setStripe(stripeInstance);
        };
        loadStripeAsync();
    }, [stripePromise]);



    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!stripe) {
            return;
        }
        setLoading(true);

        try {
            const response: any = await dispatch(createVerificationSession(userInfo.CustomerID))
            const session = response;

            const { error } = await stripe.verifyIdentity(session.client_secret);
            setLoading(false);

            if (error) {
            } else {
                await dispatch(userInformation());
            }
        } catch (error) {
            console.error('Error during verification:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        // disabled={!stripe}
        <Button variant='primary' role="link" onClick={handleClick}>{t('Verify')} {loading && <ClipLoader color={"#fff"} size={10} />}</Button>
    );
};

export default VerifyButton