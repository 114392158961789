import React, { useRef, useState, useEffect } from 'react';
import PlusIcon from '../icons/PlusIcon';
import { useTranslation } from 'react-i18next';

interface SingleFileInputProps {
  label?: string;
  accept?: string;
  file: File | null;
  setFile: (file: File | null) => void;
  subMsg?: string;
  existingFileUrl?: string;
  desc?: string;
}

const SingleFileInput: React.FC<SingleFileInputProps> = ({
  label,
  accept,
  file,
  setFile,
  subMsg,
  existingFileUrl,
  desc
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(existingFileUrl || null);
  const { t } = useTranslation();

  const handleAddFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
    }
  };

  useEffect(() => {
    // Clean up object URLs
    return () => {
      if (previewUrl && previewUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <div>
      {label && <label className="block text-sm font-medium">{label}</label>}
      <div className='flex flex-col space-y-1 mb-2'>
      {desc && (
        <p className="text-xs text-gray-500 dark:text-gray-300 text-left italic">{t(desc)}</p>
      )}
      {subMsg && (
        <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t(subMsg)}</p>
      )}
      </div>
      <div className="flex items-center space-x-4">
        <div className="relative w-20 h-20 group">
          {previewUrl ? (
            accept?.includes('image') ? (
              <img
                src={previewUrl}
                alt="Preview"
                className="w-full h-full object-contain bg-white dark:bg-gray-800 shadow-md dark:shadow-dark-md rounded p-2 cursor-pointer"
                onClick={handleAddFile}
              />
            ) : (
              <span className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-600 ring-gray-500/10">
                {file?.name || 'File'}
              </span>
            )
          ) : (
            <button
              type="button"
              onClick={handleAddFile}
              className="w-full h-full flex items-center justify-center rounded border border-dashed border-gray-300 text-gray-500 hover:bg-gray-100 focus:outline-none"
            >
              <PlusIcon className="w-6 h-6" />
            </button>
          )}
          <div
            className="absolute inset-0 flex items-center justify-center text-white text-sm font-medium opacity-0 group-hover:opacity-100 bg-black bg-opacity-50 transition-opacity duration-300 cursor-pointer"
            onClick={handleAddFile}
          >
            {t('Change')}
          </div>
        </div>
      </div>
      <input
        type="file"
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  );
};

export default SingleFileInput;
