import React, { useEffect, useState } from 'react';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { getLawFirmProfile, updateLawFirmProfile } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import Input from '../../shared/TailwindComponents/Input';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';
import TagInput from '../../shared/TailwindComponents/TagInput';
import Toggle from '../../shared/TailwindComponents/Toggle';
import SingleFileInput from '../../shared/TailwindComponents/SingleFileInput';

const LawFirmProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const lawFirmProfile = useSelector((state: RootState) => state.marketplaceProvider.lawFirmProfile);
  const isLoading = useSelector((state: RootState) => state.marketplaceProvider.isLoading);

  const [formData, setFormData] = useState<any>({
    Description: '',
    PracticeArea: [],
    ExperienceYears: '',
    is_marketplace_active: false,
    Website: '',
    LawFirmEmail: '',
    LawFirmOfficeAddress: '',
    LawFirmPhoneNumber: '',
    Logo: null,
    TermsOfServiceFile: null,
    existingLogoUrl: '',
    existingTermsUrl: '',
  });

  useEffect(() => {
    dispatch(getLawFirmProfile());
  }, [dispatch]);

  useEffect(() => {
    if (lawFirmProfile) {
      setFormData({
        ...formData,
        Description: lawFirmProfile.Description || '',
        PracticeArea: lawFirmProfile.PracticeArea ? lawFirmProfile.PracticeArea.split(',') : [],
        ExperienceYears: lawFirmProfile.ExperienceYears || '',
        is_marketplace_active: lawFirmProfile.is_marketplace_active || false,
        Website: lawFirmProfile.Website || '',
        LawFirmEmail: lawFirmProfile.LawFirmEmail || '',
        LawFirmOfficeAddress: lawFirmProfile.LawFirmOfficeAddress || '',
        LawFirmPhoneNumber: lawFirmProfile.LawFirmPhoneNumber || '',
        existingLogoUrl: lawFirmProfile.Logo || '',
        existingTermsUrl: lawFirmProfile.TermsOfServiceURL || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawFirmProfile]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTagChange = (name: string, values: string[]) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: values,
    }));
  };

  const handleFileChange = (name: string, file: File | null) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: file,
    }));
  };

  const handleToggleChange = (name: string, value: boolean) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const updateData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (['Logo', 'TermsOfServiceFile'].includes(key)) {
        if (formData[key]) {
          updateData.append(key, formData[key]);
        }
      } else if (Array.isArray(formData[key])) {
        formData[key].forEach((item: string) => {
          updateData.append(key, item);
        });
      } else {
        updateData.append(key, formData[key]);
      }
    });

    dispatch(updateLawFirmProfile(updateData));
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Company Marketplace Profile</h2>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <Input
                          label="Law Firm Email"
                          name="LawFirmEmail"
                          value={formData.LawFirmEmail}
                          onChange={handleInputChange} type={''}          />
          <Input
                          label="Law Firm Office Address"
                          name="LawFirmOfficeAddress"
                          value={formData.LawFirmOfficeAddress}
                          onChange={handleInputChange} type={''}          />
          <Input
                          label="Law Firm Phone Number"
                          name="LawFirmPhoneNumber"
                          value={formData.LawFirmPhoneNumber}
                          onChange={handleInputChange} type={''}          />
          <Textarea
                          label="Description"
                          name="Description"
                          value={formData.Description}
                          onChange={handleInputChange} id={''}          />
          <TagInput
                          label="Practice Areas"
                          tags={formData.PracticeArea}
                          onChange={(values) => handleTagChange('PracticeArea', values)} id={''}          />
          <Input
            label="Experience Years"
            name="ExperienceYears"
            value={formData.ExperienceYears}
            onChange={handleInputChange}
            type="number"
          />
          <Input
                          label="Website"
                          name="Website"
                          value={formData.Website}
                          onChange={handleInputChange} type={''}          />
          <div>
            <label className="block text-sm font-medium text-gray-700">Existing Logo</label>
            {formData.existingLogoUrl ? (
              <img src={formData.existingLogoUrl} alt="Company Logo" className="h-20 mt-2" />
            ) : (
              <p className="text-sm text-gray-500 mt-2">No logo uploaded.</p>
            )}
          </div>
          <SingleFileInput
            label="Upload New Logo"
            setFile={(file) => handleFileChange('Logo', file)}
            file={null}
          />
          <div>
            <label className="block text-sm font-medium text-gray-700">Existing Terms of Service</label>
            {formData.existingTermsUrl ? (
              <a
                href={formData.existingTermsUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-600 hover:text-primary-800 mt-2 block"
              >
                View Terms of Service
              </a>
            ) : (
              <p className="text-sm text-gray-500 mt-2">No Terms of Service uploaded.</p>
            )}
          </div>
          <SingleFileInput
            label="Upload New Terms of Service"
            setFile={(file) => handleFileChange('TermsOfServiceFile', file)}
            file={null}
          />
          <div className="flex items-center mt-4">
            <Toggle
                              enabled={formData.is_marketplace_active}
                              onToggle={(value) => handleToggleChange('is_marketplace_active', value)} value={false}            />
            <label className="ml-3 text-sm font-medium text-gray-700">
              Active on Marketplace
            </label>
          </div>
          <Button type="submit" variant="primary">
            Save Profile
          </Button>
        </form>
      )}
    </div>
  );
};

export default LawFirmProfile;
