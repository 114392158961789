import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import Badge from './Badge';

type FilterOption = {
  value: string;
  label: string;
  checked?: boolean;
};

type FilterSection = {
  id: string;
  name: string;
  options: FilterOption[];
};

type SortOption = {
  value: string;
  label: string;
  current: boolean;
};

type FilterBarProps = {
  filters: FilterSection[];
  sortOptions: SortOption[];
  selectedFilters: { [key: string]: string[] };
  onFilterChange: (filterName: string, selectedValues: string[]) => void;
  onSortChange: (value: string) => void;
  onSearchChange: (searchQuery: string) => void;
  sortValue?: string; 
  onClearAllFilters?: () => void;
  showSearchBar?: boolean;
  extraControls?: React.ReactNode;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const FilterSearchBar: React.FC<FilterBarProps> = ({
  filters,
  sortOptions,
  selectedFilters,
  onFilterChange,
  onSortChange,
  onSearchChange,
  sortValue,
  onClearAllFilters,
  showSearchBar = true,
  extraControls
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearchChange(query);
  };

  // Helper function to get the label of the current sort option
  const currentSortLabel = sortOptions.find((option) => option.value === sortValue)?.label || 'Sort';

  return (
    <div className="bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-4 py-3 flex items-center justify-between">
        {/* Filters and Sort */}
        <div className="flex flex-col space-y-2">
        <div className="flex space-x-2 items-center">
          {/* Filters */}
          {filters.map((section) => (
            <Menu as="div" key={section.name} className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center items-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-2 py-2 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none">
                  <FunnelIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                  {section.name}
                  <ChevronDownIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                  style={{ maxHeight: '200px', overflowY: 'auto' }} 
                  >
                  <div className="py-1">
                    {section.options.map((option) => (
                      <Menu.Item key={option.value}>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? 'bg-gray-100 dark:bg-gray-600' : '',
                              'flex items-center px-4 py-2 cursor-pointer'
                            )}
                            onClick={() => {
                              const currentSelections = selectedFilters[section.id] || [];
                              const updatedSelections = currentSelections.includes(option.value)
                                ? currentSelections.filter((val) => val !== option.value)
                                : [...currentSelections, option.value];
                              onFilterChange(section.id, updatedSelections);
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedFilters[section.id]?.includes(option.value) || false}
                              onChange={() => {}}
                              className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                            <span className="ml-2 text-gray-700 dark:text-gray-200">
                              {option.label}
                            </span>
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ))}

          {/* Sort Options */}
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex justify-center items-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-2 py-2 bg-white dark:bg-gray-700 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none">
                Sort: {currentSortLabel}
                <ChevronDownIcon className="ml-1 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
              style={{ maxHeight: '200px', overflowY: 'auto' }} 
              >
                <div className="py-1">
                  {sortOptions.map((option) => (
                    <Menu.Item key={option.value}>
                      {({ active }) => (
                        <div
                          onClick={() => onSortChange(option.value)}
                          className={classNames(
                            active ? 'bg-gray-100 dark:bg-gray-600' : '',
                            'cursor-pointer px-4 py-2 text-sm text-gray-700 dark:text-gray-200',
                            option.value === sortValue ? 'font-medium' : ''
                          )}
                        >
                          {option.label}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          </div>

          {/* Display Selected Filters */}
          <div className="flex items-center space-x-2">
            {Object.entries(selectedFilters).map(([filterName, values]) =>
              values.map((value) => (
                <Badge
                  key={`${filterName}-${value}`}
                  color="indigo"
                  onClose={() => {
                    const updatedValues = selectedFilters[filterName].filter((v) => v !== value);
                    onFilterChange(filterName, updatedValues);
                  }}
                >
                  {`${value}`}
                  {/* ${filterName}: */}
                </Badge>
              ))
            )}
          </div>
      </div>

        {/* Search Bar */}
        <div className="flex-1 space-y-2 flex justify-end">
          {extraControls}
          <div className="ml-1 w-64">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative text-gray-400 focus-within:text-gray-600 dark:text-gray-500">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search"
                className="block w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md py-2 pl-10 pr-3 leading-5 text-gray-900 dark:text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Search"
                type="search"
                name="search"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSearchBar;
