import React from 'react';
import { formatDistanceToNow } from 'date-fns';

interface Message {
  MessageID: string;
  EngagementID: string;
  SenderID: number;
  SenderName: string;
  Message: string;
  CreatedAt: string;
}

interface ActivityFeedProps {
  messages: Message[];
}

const ActivityFeed: React.FC<ActivityFeedProps> = ({ messages }) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md mb-6">
      <h2 className="text-xl font-semibold mb-4">Activity</h2>
      <ul role="list" className="space-y-6">
        {messages.map((message) => (
          <li key={message.MessageID} className="relative flex gap-x-4">
            <div className="flex items-center">
              <img
                alt=""
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(message.SenderName)}&background=random`}
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
              />
            </div>
            <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200 dark:ring-gray-700">
              <div className="flex justify-between gap-x-4">
                <div className="py-0.5 text-sm text-gray-500 dark:text-gray-300">
                  <span className="font-medium text-gray-900 dark:text-white">{message.SenderName}</span>
                </div>
                <time
                  dateTime={message.CreatedAt}
                  className="flex-none py-0.5 text-xs text-gray-500 dark:text-gray-400"
                >
                  {formatDistanceToNow(new Date(message.CreatedAt), { addSuffix: true })}
                </time>
              </div>
              <p className="mt-2 text-sm text-gray-700 dark:text-gray-200">{message.Message}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityFeed;
