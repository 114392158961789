import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { Case } from '../shared/types';
import ClipLoader from 'react-spinners/ClipLoader';
import FilterSearchBar from '../../shared/TailwindComponents/FilterSearchBar';
import { getPublishedCases, getRelevantCases } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import CaseCard from '../../shared/TailwindComponents/CaseCars';
import Button from '../../shared/TailwindComponents/Button';

const PublishedCasesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const publishedCases = useSelector((state: RootState) => state.marketplaceProvider.publishedCases) as Case[];
  const relevantCases = useSelector((state: RootState) => state.marketplaceProvider.relevantCases) as Case[];
  const error = useSelector((state: RootState) => state.marketplaceProvider.error);
  const isLoading = useSelector((state: RootState) => state.marketplaceProvider.isLoading);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortedCases, setSortedCases] = useState<Case[]>([]);
  const [sortKey, setSortKey] = useState<'AnonymizedDescription' | 'BudgetRange' | 'Deadline' | 'CaseType'>('Deadline');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [useRelevantCases, setUseRelevantCases] = useState<boolean>(false);

  useEffect(() => {
    if (useRelevantCases) {
      dispatch(getRelevantCases());
    } else {
      dispatch(getPublishedCases());
    }
  }, [dispatch, useRelevantCases]);

  const handleGetRelevantCases = () => {
    setUseRelevantCases(true);
  };

  const filters = [
    {
      id: 'CaseType',
      name: 'Case Type',
      options: [
        { value: 'Criminal', label: 'Criminal' },
        { value: 'Civil', label: 'Civil' },
        { value: 'Family', label: 'Family' },
        // ... more options
      ],
    },
    // Add more filters if needed
  ];

  const sortOptions = [
    { value: 'Deadline', label: 'Deadline', current: sortKey === 'Deadline' },
    { value: 'BudgetRange', label: 'Budget', current: sortKey === 'BudgetRange' },
    // ... more sort options
  ];

  const handleFilterChange = (filterName: string, values: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: values,
    }));
  };

  const handleSearchChange = (query: string) => {
    setSearchTerm(query);
  };

  const handleSortChange = (value: string) => {
    setSortKey(value as any);
  };

  useEffect(() => {
    const casesToUse = useRelevantCases ? relevantCases : publishedCases;
    let filteredCases = [...casesToUse];

    // Filter by search term
    if (searchTerm) {
      filteredCases = filteredCases.filter((caseItem) =>
        caseItem.AnonymizedDescription.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply selected filters
    if (Object.keys(selectedFilters).length > 0) {
      Object.entries(selectedFilters).forEach(([filterName, values]) => {
        if (filterName === 'CaseType') {
          filteredCases = filteredCases.filter((caseItem) =>
            values.includes(caseItem.CaseType)
          );
        }
        // Handle other filters
      });
    }

    // Sort the cases
    filteredCases.sort((a, b) => {
      let aValue: any = a[sortKey];
      let bValue: any = b[sortKey];

      // Handle null or undefined values
      if (aValue === null || aValue === undefined) aValue = '';
      if (bValue === null || bValue === undefined) bValue = '';

      // For dates, parse them to timestamps
      if (sortKey === 'Deadline') {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      }

      // For BudgetRange, use the average of Min and Max
      if (sortKey === 'BudgetRange') {
        const aBudget = (a.BudgetRange.Min + a.BudgetRange.Max) / 2;
        const bBudget = (b.BudgetRange.Min + b.BudgetRange.Max) / 2;
        aValue = aBudget;
        bValue = bBudget;
      }

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedCases(filteredCases);
  }, [publishedCases, relevantCases, useRelevantCases, searchTerm, sortKey, sortOrder, selectedFilters]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="px-4">
      <div className="mb-4">
        <FilterSearchBar
          filters={filters}
          sortOptions={sortOptions}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
          onSearchChange={handleSearchChange}
          sortValue={sortKey}
          extraControls={
            <Button
              onClick={handleGetRelevantCases}
              variant="primary"
              className="mr-2"
              tooltip="Smart cases"
            >
              <i className="fas fa-magic"></i>
            </Button>
          }
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <ClipLoader color="#000" loading={true} size={30} />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {sortedCases.length > 0 ? (
            sortedCases.map((caseItem) => (
              <CaseCard key={caseItem.CaseID} caseItem={caseItem} />
            ))
          ) : (
            <p className="text-center w-full">No published cases available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default PublishedCasesList;
