import React from 'react';
import Input from './Input';

interface CurrencyInputProps {
  id: string;
  name: string;
  label: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currencySymbol?: string;
  description?: string;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  id,
  name,
  label,
  placeholder,
  value,
  onChange,
  currencySymbol = '$',
  description,
}) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      {description && <p className="text-sm text-gray-500">{description}</p>}
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">{currencySymbol}</span>
        </div>
        <Input
          type="number"
          name={name}
          id={id}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder}
          value={value}
          onChange={onChange}

        />
      </div>
    </div>
  );
};

export default CurrencyInput;
