import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../store';
import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import Badge from '../../shared/TailwindComponents/Badge';
import Button from '../../shared/TailwindComponents/Button';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import {
  hideModal,
  showModal,
} from '../../../store/actions/modalActions';
import {
  acceptEngagement,
  declineEngagement,
  getCaseDetails,
  getLawyerProfile,
} from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';
import LawyerProfileView from './LawyerProfileView';
import Textarea from '../../shared/TailwindComponents/Textarea';
import { sendMessage } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import { formatCamelCase } from '../../../utils/functions';
import ActivityFeed from '../shared/ActivityFeed';
import CaseInformation from '../Lawyer/CaseInformation';
import { useSelector } from 'react-redux';

interface RouteParams {
  engagementId: string;
}

interface LocationState {
  engagement: any;
}

const ClientEngagementDetails: React.FC = () => {
  const { engagementId } = useParams<RouteParams>();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { setBreadcrumbs } = useBreadcrumbs();

  const [engagement, setEngagement] = useState<any>(location.state?.engagement || null);
  const [lawyerProfile, setLawyerProfile] = useState<any>(null);
  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [newMessage, setNewMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const user = useSelector((state: RootState) => state.user.userInfo)
  const fullName = `${user.FirstName} ${user.LastName}`

  useEffect(() => {
    if (!engagement) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: 'Engagement data not available.',
          modalType: 'error',
          duration: 3000,
        })
      );
      history.push('/marketplace?tab=engagements');
    }
  }, [engagement, dispatch, history]);

  useEffect(() => {
    setBreadcrumbs([
      { name: 'Marketplace', href: '/marketplace', current: false },
      { name: 'Engagement Details', href: `/marketplace/engagement/${engagementId}`, current: true },
    ]);
    return () => {
      setBreadcrumbs([]);
    };
  }, [setBreadcrumbs, engagementId]);

  useEffect(() => {
    if (engagement) {
      // Fetch lawyer profile
      dispatch(getLawyerProfile(engagement.LawyerID.toString())).then((profile: any) => {
        setLawyerProfile(profile);
      });
      // Fetch case details
      dispatch(getCaseDetails({ CaseID: engagement.CaseID })).then((response: any) => {
        setCaseDetails(response);
      });
    }
  }, [engagement, dispatch]);

  if (!engagement) {
    return null;
  }

  const {
    ProposedBudget,
    BudgetMin,
    BudgetMax,
    Message,
    EstimatedTimeline,
    AdditionalTerms,
    Status,
    Type,
    CreatedAt,
    UpdatedAt,
    LawyerName,
    Messages = [],
    EngagementID,
  } = engagement;

  const handleAccept = async () => {
    if (Type === 'Engagement' && Status === 'ProposalSubmitted') {
      await dispatch(acceptEngagement({ EngagementID: EngagementID, AcceptedTerms: true }));
      setEngagement({ ...engagement, Status: 'EngagementOngoing' });
    }
  };

  const handleDecline = async () => {
    if (Type === 'Engagement' && Status === 'ProposalSubmitted') {
      await dispatch(declineEngagement({ EngagementID: EngagementID }));
      setEngagement({ ...engagement, Status: 'ProposalDeclined' });
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    setIsSending(true);

    try {
      await dispatch(sendMessage(EngagementID, newMessage));
      // Append the new message to the local state
      const newMsg = {
        MessageID: Math.random().toString(36).substr(2, 9), // Temporary ID
        EngagementID: engagement.EngagementID,
        SenderID: user.UserID,
        SenderName: fullName,
        Message: newMessage,
        CreatedAt: new Date().toISOString(),
      };
      setEngagement({
        ...engagement,
        Messages: [...Messages, newMsg],
      });
      setNewMessage('');
    } catch (error) {
      // Error handling
    } finally {
      setIsSending(false);
    }
  };

  function getStatusColor(status: string): 'green' | 'yellow' | 'red' | 'gray' | 'blue' {
    switch (status) {
      case 'EngagementOngoing':
        return 'blue';
      case 'Accepted':
      case 'InvitationAccepted':
        return 'green';
      case 'Pending':
      case 'AccessRequested':
      case 'ProposalSubmitted':
      case 'Invited':
        return 'yellow';
      case 'Declined':
      case 'InvitationDeclined':
        return 'red';
      default:
        return 'gray';
    }
  }

  const renderEngagementInfo = () => {
    const infoFields = [
      { label: 'Type', value: formatCamelCase(Type) },
      { label: 'Status', value: formatCamelCase(Status) },
      {
        label: 'Created At',
        value: new Date(CreatedAt).toLocaleString(),
      },
      {
        label: 'Updated At',
        value: new Date(UpdatedAt).toLocaleString(),
      },
    ];

    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md mb-6">
        <h2 className="text-xl font-semibold mb-4">Engagement Information</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {infoFields.map(({ label, value }) => (
            <div key={label}>
              <p className="text-sm font-medium text-gray-500">{label}</p>
              {label === 'Status' ? (
                <Badge color={getStatusColor(Status)}>{value}</Badge>
              ) : (
                <p className="text-sm text-gray-700 dark:text-gray-200">{value}</p>
              )}
            </div>
          ))}
        </div>
        {/* Display agreed terms when engagement is ongoing */}
        {Status === 'EngagementOngoing' && (
          <>
            {ProposedBudget && (
              <div className="mt-4">
                <p className="text-sm font-medium text-gray-500">Agreed Budget</p>
                <p className="text-sm text-gray-700 dark:text-gray-200">${ProposedBudget}</p>
              </div>
            )}
            {EstimatedTimeline && (
              <div className="mt-4">
                <p className="text-sm font-medium text-gray-500">Estimated Timeline</p>
                <p className="text-sm text-gray-700 dark:text-gray-200">{EstimatedTimeline}</p>
              </div>
            )}
            {AdditionalTerms && (
              <div className="mt-4">
                <p className="text-sm font-medium text-gray-500">Additional Terms</p>
                <p className="text-sm text-gray-700 dark:text-gray-200">{AdditionalTerms}</p>
              </div>
            )}
          </>
        )}
        {/* Action Buttons Based on Status and Type */}
        {Type === 'Engagement' && Status === 'ProposalSubmitted' && (
          <div className="flex space-x-4 mt-6">
            <Button color="green" onClick={() => openModal('accept')}>
              Accept Engagement
            </Button>
            <Button color="red" onClick={() => openModal('decline')}>
              Decline Engagement
            </Button>
          </div>
        )}
      </div>
    );
  };

  const openModal = (action: 'accept' | 'decline') => {
    const actionMap = {
      accept: {
        title: 'Accept Engagement',
        message: 'Are you sure you want to accept this engagement?',
        onConfirm: handleAccept,
      },
      decline: {
        title: 'Decline Engagement',
        message: 'Are you sure you want to decline this engagement?',
        onConfirm: handleDecline,
      },
    };

    const modalDetails = actionMap[action];

    dispatch(
      showModal({
        showModal: true,
        type: 'confirmation',
        message: modalDetails.message,
        title: modalDetails.title,
        onConfirm: async () => {
          await modalDetails.onConfirm();
          dispatch(hideModal());
        },
        onCancel: () => {
          dispatch(hideModal());
        },
      })
    );
  };

  return (
    <div className="px-4 py-6">
      {renderEngagementInfo()}
      {lawyerProfile && (
        <div className="mb-6">
          <LawyerProfileView lawyerProfile={lawyerProfile} />
        </div>
      )}
      {caseDetails && (
        <div className="mb-6">
          <CaseInformation caseItem={caseDetails} />
        </div>
      )}
      {/* Display Activity Feed when engagement is ongoing */}
      {Status === 'EngagementOngoing' && (
        <div>
          <ActivityFeed messages={Messages} />
          {/* New message form */}
          <div className="mt-6 flex gap-x-3">
            <img
              alt=""
              src={`https://ui-avatars.com/api/?name=Your+Name&background=random`}
              className="h-10 w-10 flex-none rounded-full bg-gray-50"
            />
            <div className="relative flex-auto">
              <Textarea
                name="newMessage"
                placeholder="Add your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                rows={2} id={''} label={''}              />
              <div className="absolute right-0 bottom-0 flex items-center space-x-2 p-2">
                <Button
                  type="button"
                  onClick={handleSendMessage}
                  disabled={isSending || newMessage.trim() === ''}
                  variant="primary"
                >
                  {isSending ? 'Sending...' : 'Send'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientEngagementDetails;
