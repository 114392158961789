import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useAppDispatch, RootState } from '../store';
import { useSelector } from 'react-redux';
import { getUserID, getUserType } from '../store/actions/ChatAction';
import { createFolder, createSubFolder, deleteFolder, fetchStructure, renameFolder } from '../store/actions/FolderAction';
import { fetchRecommendedDocuments } from '../store/actions/DocumentsAction';
import FolderList from '../components/Documents/FolderList';
import RecommendedFiles from '../components/Documents/RecommendedFiles';
import DocumentList from '../components/Documents/DocumentList';
import Editor from '../components/Documents/Editor';
import { useParams } from 'react-router-dom';
import ApiLoader from '../components/ApiLoader';
import { FileProps, FolderProps } from '../components/Documents/types';
import { useTranslation } from 'react-i18next';
import { LayoutContext } from '../contexts/LayoutContext';
import FilterSearchBar from '../components/shared/TailwindComponents/FilterSearchBar'; 
import Button from '../components/shared/TailwindComponents/Button';
import { showAddFileSidePanel } from '../store/actions/sidePanelActions';
import { hideModal, showCreateFolderModal, showModal } from '../store/actions/modalActions';
import { Menu, Transition } from '@headlessui/react';
import { DocumentPlusIcon, EllipsisVerticalIcon, FolderPlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid';
import PlusIcon from '../components/shared/icons/PlusIcon';
import Input from '../components/shared/TailwindComponents/Input';
import { ClipLoader } from 'react-spinners';

type FilterableKeys = 'FileCategory' | 'Status' | 'UserID';

function FilesPage() {
  const dispatch = useAppDispatch();
  const userId = getUserID();
  const userType = getUserType();
  const { t } = useTranslation();
  const { documentId } = useParams<{ documentId: string }>();
  const [selectedFile, setSelectedFile] = useState<FileProps | null>(null);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<FolderProps | null>(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<{ [K in FilterableKeys]?: string[] }>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortValue, setSortValue] = useState<string>('name_asc');

  const structure = useSelector((state: RootState) => state.folders.structure);
  const recommendedDocuments = useSelector(
    (state: RootState) => state.documents.recommendedDocuments
  );
  const [isRenamingFolder, setIsRenamingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState(selectedFolder?.FolderName || '');

  const handleRenameFolder = () => {
    if (newFolderName.trim()) {
      dispatch(renameFolder(selectedFolderId!, newFolderName))
        .then(() => {
          setIsRenamingFolder(false);
          dispatch(fetchStructure()); // Refresh folder structure
        })
        .catch((error) => console.error('Failed to rename folder', error));
    }
  };

  const toggleRenameMode = () => {
    setIsRenamingFolder(!isRenamingFolder);
    setNewFolderName(selectedFolder?.FolderName || '');
  };


  const { setIsContentScrollable } = useContext(LayoutContext);

  useEffect(() => {
    setIsContentScrollable(true);
    return () => {
      setIsContentScrollable(true);
    };
  }, [setIsContentScrollable]);
  

  const fetchData = useCallback(async () => {
    if (userType) {
      setIsLoading(true);
      await dispatch(fetchStructure());
      await dispatch(fetchRecommendedDocuments());
      setIsLoading(false);
    }
  }, [dispatch,userType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (selectedFolderId && structure) {
      const folder = findFolderById(structure, selectedFolderId);
      setSelectedFolder(folder);
    } else {
      setSelectedFolder(null);
    }
  }, [selectedFolderId, structure]);

  const handleSelectFolder = (folderId: string) => {
    setSelectedFolderId(folderId);
  };

  const handleSelectFile = (file: FileProps) => {
    setSelectedFile(file);
    setEditorOpen(true);
  };

  const handleCreateFolder = async (folderName: string) => {
    if (folderName.trim()) {
      const resp: any = await dispatch(createFolder(folderName));
      if (resp) {
        await dispatch(fetchStructure());
      }
    }
  };


  const handleCreateSubFolder = async (folderName: string, parentFolderId: string) => {
    if (folderName.trim()) {
      const resp: any = await dispatch(createSubFolder(folderName, parentFolderId));
      if (resp) {
        await dispatch(fetchStructure());
      }
    }
  };

  const findFolderById = (folders: FolderProps[], folderId: string): FolderProps | null => {
    for (const folder of folders) {
      if (folder.FolderID === folderId) {
        return folder;
      }
      if (folder.SubFolders && folder.SubFolders.length > 0) {
        const found = findFolderById(folder.SubFolders, folderId);
        if (found) return found;
      }
    }
    return null;
  };

  const applyFiltersAndSearch = (documents: FileProps[]): FileProps[] => {
    let filteredDocs = [...documents];

    // Apply filters
    Object.entries(filters).forEach(([filterKey, filterValues]) => {
      const key = filterKey as FilterableKeys;
      filteredDocs = filteredDocs.filter((doc) => {
        const docValue = doc[key];
        return docValue && filterValues.includes(docValue);
      });
    });

    // Apply search
    if (searchTerm) {
      filteredDocs = filteredDocs.filter((doc) =>
        doc.FileName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply sorting
    filteredDocs.sort((a, b) => {
      if (sortValue === 'name_asc') return a.FileName.localeCompare(b.FileName);
      if (sortValue === 'name_desc') return b.FileName.localeCompare(a.FileName);
      if (sortValue === 'date_asc')
        return new Date(a.ModifiedAt).getTime() - new Date(b.ModifiedAt).getTime();
      if (sortValue === 'date_desc')
        return new Date(b.ModifiedAt).getTime() - new Date(a.ModifiedAt).getTime();
      return 0;
    });

    return filteredDocs;
  };

  const handleDeleteFolder = () => {
    dispatch(
      showModal({
        type: 'confirmation',
        message: t('Are you sure you want to delete this folder?'),
        onConfirm: () => confirmDeleteFolder(),
        onCancel: () => {},
        showModal: true,
      })
    );
  };
  
  const confirmDeleteFolder = async () => {
    if (selectedFolderId) {
      const userId = getUserID();
      const userType = getUserType();
      await dispatch(
        deleteFolder(selectedFolderId, () => {
          setSelectedFolderId(null);
          dispatch(fetchStructure());
          dispatch(hideModal())
        })
      );
    }
  };

  // Define filter options based on your data
  const filterOptions = [
    {
      id: 'FileCategory',
      name: t('File Category'),
      options: [
        { value: 'Contract', label: t('Contract') },
        { value: 'Legal Document', label: t('Legal Document') },
        { value: 'General', label: t('General') },
        { value: 'Template', label: t('Template') },
      ],
    },
  ];

  const sortOptions = [
    { value: 'name_asc', label: t('Name Ascending'), current: sortValue === 'name_asc' },
    { value: 'name_desc', label: t('Name Descending'), current: sortValue === 'name_desc' },
    { value: 'date_asc', label: t('Date Ascending'), current: sortValue === 'date_asc' },
    { value: 'date_desc', label: t('Date Descending'), current: sortValue === 'date_desc' },
  ];

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={isLoading} size={30} />
      </div>
    );
  }


  return (
    <div className="flex h-[calc(100vh-80px)] max-h-[calc(100vh-90px)]">
      {editorOpen && selectedFile ? (
        <Editor
          file={selectedFile}
          onClose={() => setEditorOpen(false)}
          setEditorOpen={setEditorOpen}
          onFileDeleted={() => setSelectedFile(null)}
        />
      ) : (
        <>
          <div className="w-1/4 border-r border-gray-300 dark:border-gray-700 flex flex-col">
            <FolderList
              folders={structure}
              onSelectFolder={handleSelectFolder}
              selectedFolderId={selectedFolderId}
              handleCreateFolder={handleCreateFolder}
              isLoading={isLoading}
            />
          </div>
          <div className="w-3/4 flex flex-col h-full">
          <div className="flex-shrink-0 overflow-auto">
          <RecommendedFiles
                documents={recommendedDocuments}
                onSelectFile={handleSelectFile}
                folders={structure}
              />
            </div>
            {selectedFolder ? (
              <div className="flex flex-col flex-1 overflow-hidden">
                {/* Header for selected folder */}
                <div className="flex items-center justify-between px-4 py-2 bg-white dark:bg-gray-800 border-b border-gray-300 dark:border-gray-700 flex-shrink-0">
                <h2 className="text-xl font-semibold">
                  {isRenamingFolder ? (
                    <Input
                      type="text"
                      value={newFolderName}
                      onChange={(e) => setNewFolderName(e.target.value)}
                      onBlur={handleRenameFolder}
                      onKeyDown={(e) => e.key === 'Enter' && handleRenameFolder()}
                    />
                  ) : (
                    `${selectedFolder.FolderName} (${selectedFolder.Files.length})`
                  )}
                </h2>
              <div className="flex items-center space-x-2">
                {/* Ellipsis Dropdown */}
                <div className="relative inline-block text-left">
                  <Menu as="div" className="relative z-10">
                    <Menu.Button className="inline-flex justify-center w-full text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900">
                      <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={React.Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={toggleRenameMode}
                          className={`${
                            active ? 'bg-gray-100 dark:bg-gray-700' : ''
                          } group flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                        >
                          <PencilIcon className="mr-3 h-5 w-5 text-primary-500" aria-hidden="true" />
                          {t('Rename Folder')}
                        </button>
                      )}
                    </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                            onClick={() =>
                              dispatch(
                                showCreateFolderModal(
                                  undefined,
                                  selectedFolderId as string,
                                  handleCreateSubFolder,
                                  true // Indicate that this is a subfolder
                                )
                              )
                            }
                              className={`${
                                active ? 'bg-gray-100 dark:bg-gray-700' : ''
                              } group flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                            >
                              <FolderPlusIcon className="mr-3 h-5 w-5 text-primary-500" aria-hidden="true" />
                              {t('Create New Subfolder')}
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                            onClick={() =>
                              dispatch(showAddFileSidePanel(structure, selectedFolderId as string))
                            }
                              className={`${
                                active ? 'bg-gray-100 dark:bg-gray-700' : ''
                              } group flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                            >
                              <DocumentPlusIcon className="mr-3 h-5 w-5 text-primary-500" aria-hidden="true" />
                              {t('Create New File')}
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={handleDeleteFolder}
                              className={`${
                                active ? 'bg-gray-100 dark:bg-gray-700' : ''
                              } group flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200`}
                            >
                              <TrashIcon className="mr-3 h-5 w-5 text-red-500" aria-hidden="true" />
                              {t('Delete Folder')}
                            </button>
                          )}
                        </Menu.Item>
                        {/* Add more menu items here if needed */}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
                {/* Filter Bar */}
                <div className="flex-shrink-0">
                  <FilterSearchBar
                    filters={filterOptions}
                    sortOptions={sortOptions}
                    selectedFilters={filters}
                    onFilterChange={(filterName, selectedValues) =>
                      setFilters((prev) => ({ ...prev, [filterName]: selectedValues }))
                    }
                    onSortChange={(value) => setSortValue(value)}
                    onSearchChange={(term) => setSearchTerm(term)}
                    sortValue={sortValue}
                  />
                </div>
                {/* Document List */}
                <div className="flex-1 overflow-auto">
                  <DocumentList
                    documents={applyFiltersAndSearch(selectedFolder.Files || [])}
                    onSelectFile={handleSelectFile}
                    folders={structure}
                    selectedFolderId={selectedFolderId!}
                  />
                </div>
              </div>
            ) : (
              <div className="p-4 flex-1 flex items-center justify-center">
                <p className="text-center text-gray-600 dark:text-gray-400">
                  {t('No folder selected. Please select a folder to view its documents.')}
                </p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default FilesPage;
