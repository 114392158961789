import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { ClipLoader } from 'react-spinners';
import { getUserType } from '../../../store/actions/ChatAction';
import { fetchAllEmployees } from '../../../store/actions/organization/employeeActions';
import { showOnboardingSidePanel } from '../../../store/actions/sidePanelActions';
import Badge from '../../shared/TailwindComponents/Badge';
import Header from '../../shared/TailwindComponents/Header';
import Table from '../../shared/TailwindComponents/Table';
import Pagination from '../../shared/TailwindComponents/Pagination';
import Stats, { StatItem } from '../../shared/TailwindComponents/Stats';
import { fetchUserTypes } from '../../../store/actions/DataAction';
import { useHistory } from 'react-router-dom';
import { usePermission } from '../../../hooks/userPermissions';

interface EmployeeRow {
  id: string;
  Name: string;
  Email: string;
  Designation: string;
  Status: React.ReactNode; 
}


const EmployeeList: React.FC = () => {
  const dispatch = useAppDispatch();

  const { employees, loading, error } = useSelector((state: RootState) => state.employees);

  const [currentPage, setCurrentPage] = useState(1);
  const employeesPerPage = 10;
  const userTypes = useSelector((state: RootState) => state.lists.userTypes);
  const userType = getUserType();
  const totalEmployees = employees.length;
  const activeEmployees = employees.filter((emp) => emp && emp.User && !emp.User.is_deleted).length;
  const inactiveEmployees = totalEmployees - activeEmployees;
  const history = useHistory();
  const { hasAccess, isReadOnly } = usePermission('admin');

  useEffect(() => {
    dispatch(fetchAllEmployees());
    dispatch(fetchUserTypes());
  }, [dispatch]);

  const handleAddEmployee = () => {
    dispatch(showOnboardingSidePanel());
  };

  // Pagination logic
  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = employees.slice(indexOfFirstEmployee, indexOfLastEmployee);
  const totalPages = Math.ceil(employees.length / employeesPerPage);

  // Table columns
  const columns = ['Name', 'Email', 'Designation', 'Status'];

  // Data for the table
  const data: EmployeeRow[] = currentEmployees
  .map((employee): EmployeeRow | null => {
    if (!employee || !employee.User) {
      console.warn('Employee or Employee.User is undefined:', employee);
      return null; // Skip this employee
    }
    const employeeStatus = employee.User.is_deleted ? 'Inactive' : 'Active';
    return {
      id: employee.User.UserID,
      Name: `${employee.User.FirstName} ${employee.User.LastName}`,
      Email: employee.User.Email,
      Designation: employee.Designation || 'N/A',
      Status: (
        <Badge color={employeeStatus === 'Active' ? 'green' : 'gray'}>
          {employeeStatus}
        </Badge>
      ),
    };
  })
  .filter((item): item is EmployeeRow => item !== null);


  const statsData: StatItem[] = [
    {
      name: 'Total Employees',
      stat: totalEmployees.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
    {
      name: 'Active Employees',
      stat: activeEmployees.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'increase',
    },
    {
      name: 'Inactive Employees',
      stat: inactiveEmployees.toString(),
      previousStat: 'N/A',
      change: 'N/A',
      changeType: 'decrease',
    },
  ];

  if (userType !== 'BusinessAdmin' && userType !== 'LawFirmAdmin') {
    return <div className="p-4">You do not have permission to view this page.</div>;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ClipLoader color="#33699f" loading={loading} size={30} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-600">Error: {error}</div>;
  }

  return (
    <div className="p-4">
        <section className="mb-1">
        <Stats stats={statsData} />
        </section>
    
    {/* Header */}
      <Header
        title="Employees"
        subtitle="Manage your employees"
        actionLabel="Add Employee"
        onActionClick={handleAddEmployee}
      />
      {/* Employee Table */}
      <Table
        columns={columns}
        data={data}
        selectFunction={(row) => {
          const employee = employees.find((emp) => emp.User.UserID === row.id);
          console.log(employee, "employeeemployees");
          
          if (employee) {
            history.push(`/management/employees/${employee.User.UserID}`);
          }
        }}
        selectLabel="View"
        disabled={isReadOnly}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default EmployeeList;
