import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Lawyer } from '../shared/types';
import LawyerCard from '../../shared/TailwindComponents/LawyerCard';
import ClipLoader from 'react-spinners/ClipLoader';
import FilterSearchBar from '../../shared/TailwindComponents/FilterSearchBar';
import Pagination from '../../shared/TailwindComponents/Pagination';
import apis from '../../../utils/apis';
import { RootState, useAppDispatch } from '../../../store';
import { fetchPracticeAreas } from '../../../store/actions/DropdownActions';
import { useSelector } from 'react-redux';
import { getAllLawyers, getRecommendedLawyers } from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';
import { fetchCases } from '../../../store/actions/marketplace2/caseActions';
import Dropdown, { DropdownOption } from '../../shared/TailwindComponents/Dropdown';

const LawyersList: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortedLawyers, setSortedLawyers] = useState<Lawyer[]>([]);
  const [sortValue, setSortValue] = useState<string>('RatingsAverage'); 
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [selectedCaseId, setSelectedCaseId] = useState<string>('');
  const dispatch = useAppDispatch();
  const practiceAreas = useSelector((state: RootState) => state.dropdown.practiceAreas || []);
  const lawyers = useSelector((state: RootState) => state.marketplaceReceiver.lawyers)
  const cases = useSelector((state: RootState) => state.casesM.cases || []);

  // Fetch practice areas for filters
  useEffect(() => {
    const getPracticeAreas = async () => {
      try {
            dispatch(fetchPracticeAreas());
      } catch (err) {
        console.error('Failed to fetch practice areas');
      }
    };
    getPracticeAreas();
  }, []);

  // Fetch lawyers from API
  useEffect(() => {
    dispatch(fetchPracticeAreas());
    dispatch(fetchCases());
  }, [dispatch]);

  // Fetch lawyers based on selected case
  useEffect(() => {
    if (selectedCaseId) {
      dispatch(getRecommendedLawyers(selectedCaseId));
    } else {
      dispatch(getAllLawyers());
    }
  }, [dispatch, selectedCaseId]);


  useEffect(() => {
    // Filter and sort lawyers
    let filteredLawyers = [...lawyers];

    // Filter by search term
    if (searchTerm) {
      filteredLawyers = filteredLawyers.filter((lawyer) =>
        `${lawyer.FirstName} ${lawyer.LastName}`.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply selected filters
    if (Object.keys(selectedFilters).length > 0) {
      Object.entries(selectedFilters).forEach(([filterName, values]) => {
        if (filterName === 'Specialization') {
          filteredLawyers = filteredLawyers.filter((lawyer) =>
            lawyer.Specializations?.some((spec: any) => values.includes(spec))
          );
        }
        if (filterName === 'Affiliation') {
          filteredLawyers = filteredLawyers.filter((lawyer) => {
            const isAffiliated = lawyer.CompanyRole ? 'Affiliated' : 'Independent';
            return values.includes(isAffiliated);
          });
        }
      });
    }

    // Sort the lawyers
    filteredLawyers.sort((a: any, b: any) => {
      let aValue: any = a[sortValue] || 0;
      let bValue: any = b[sortValue] || 0;

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      // For RatingsAverage and ExperienceYears, sort in descending order
      if (sortValue === 'RatingsAverage' || sortValue === 'ExperienceYears') {
        return bValue - aValue;
      }

      // For other values, sort in ascending order
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    });

    setSortedLawyers(filteredLawyers);
  }, [lawyers, searchTerm, sortValue, selectedFilters]);

  const handleFilterChange = (filterName: string, values: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: values,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  const handleSearchChange = (query: string) => {
    setSearchTerm(query);
  };

  const handleClearAllFilters = () => {
    setSelectedFilters({});
  };

  const filters = [
    {
      id: 'Specialization',
      name: 'Specialization',
      options: practiceAreas,
    },
    {
      id: 'Affiliation',
      name: 'Affiliation',
      options: [
        { value: 'Affiliated', label: 'Law Firm Affiliated' },
        { value: 'Independent', label: 'Independent Lawyer' },
      ],
    },
  ];

  const handleCaseChange = (caseId: string) => {
    setSelectedCaseId(caseId);
  };

  const sortOptions = [
    { value: 'RatingsAverage', label: 'Ratings', current: sortValue === 'RatingsAverage' },
    { value: 'ExperienceYears', label: 'Experience', current: sortValue === 'ExperienceYears' },
    { value: 'HourlyRate', label: 'Hourly Rate', current: sortValue === 'HourlyRate' },
  ];

  if (error) {
    return <div>Error: {error}</div>;
  }

  const caseOptions: DropdownOption[] = cases.map((caseItem) => ({
    value: caseItem.CaseID,
    label: caseItem.Title || caseItem.CaseName || `Case ${caseItem.CaseID}`,
  }));

  return (
    <div className="px-4 pb-3">
      {/* Filter, Sort, and Search Bar */}
      <div className="mb-4">
      <FilterSearchBar
          filters={filters}
          sortOptions={sortOptions}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
          onSearchChange={handleSearchChange}
          sortValue={sortValue}
          extraControls={
                    <Dropdown
                    options={caseOptions}
                    onChange={(e) => handleCaseChange(selectedCaseId)}
                    error={error as string}
                    value={selectedCaseId}
                  />
          }

        />
      </div>

      {/* Lawyers List */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <ClipLoader color="#000" loading={true} size={30} />
        </div>
      ) : (
        <>
          {sortedLawyers.length === 0 ? (
            <div className="text-center text-gray-500">No lawyers found.</div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-20">
              {sortedLawyers.map((lawyer) => (
                <LawyerCard
                  key={lawyer.UserID}
                  lawyer={lawyer}
                  onClick={() => history.push(`/marketplace/lawyer/${lawyer.UserID}`, { lawyer })}
                />
              ))}
            </div>
          )}

          {/* Pagination Controls */}
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(newPage) => setPage(newPage)}
          />
        </>
      )}
    </div>
  );
};

export default LawyersList;
