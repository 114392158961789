import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchAllClientDetails, fetchAllClients, fetchAllExternalCases } from '../../store/actions/ClientAction';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

// Other imports
import { showAddClientSidePanel } from '../../store/actions/sidePanelActions';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import Stats, { StatItem } from '../shared/TailwindComponents/Stats';
import Header from '../shared/TailwindComponents/Header';
import ListItem from '../shared/TailwindComponents/ListItem';
import { BadgeItem } from '../shared/TailwindComponents/types/badgeTypes';

const Clients: React.FC = () => {
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const allClients = useSelector((state: RootState) => state.client.allClients || []);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [totalCases, setTotalCases] = useState(0);
  const [activeCases, setActiveCases] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    const fetchCasesData = async () => {
      const cases = await dispatch(fetchAllExternalCases());
      setTotalCases(cases.length);
      const active = cases.filter((c: any) => c.case_status === 'Active').length;
      setActiveCases(active);
    };
    fetchCasesData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setIsClientsLoading(true);
      try {
        await dispatch(fetchAllClients());
      } finally {
        setIsClientsLoading(false);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleAddClientClick = () => {
    dispatch(showAddClientSidePanel());
  };

  const handleClientClick = (client: any) => {
    history.push(`/clients/${client.ClientID}`);
  };
  

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };


  // Compute stats
  const totalClients = allClients.length;
  const clientsPendingVerification = allClients.filter(
    (client) => client.IDVerificationStatus === 'Pending'
  ).length;
  const clientsVerified = totalClients - clientsPendingVerification;
  const percentagePendingVerification =
    totalClients > 0 ? (clientsPendingVerification / totalClients) * 100 : 0;
  const averageCasesPerClient = totalClients > 0 ? (totalCases / totalClients).toFixed(2) : '0';

  const statsData: StatItem[] = [
    {
      name: 'Total Clients',
      stat: totalClients.toString(),
      previousStat: '', // If you have previous data, you can include it here
      change: '', // Calculate change if possible
      changeType: 'increase',
    },
    {
      name: 'Clients Pending Verification',
      stat: `${clientsPendingVerification}`,
      previousStat: '',
      change: `${percentagePendingVerification.toFixed(1)}%`,
      changeType: 'increase',
    },
    {
      name: 'Average Cases per Client',
      stat: averageCasesPerClient.toString(),
      previousStat: '',
      change: '',
      changeType: 'increase',
    },
  ];

  // Filters and sort options
  const filters = [
    {
      id: 'IDVerificationStatus',
      name: 'Verification Status',
      options: [
        { value: 'Pending', label: 'Pending' },
        { value: 'Verified', label: 'Verified' },
      ],
    },
  ];

  const sortOptions = [
    { value: 'name_asc', label: 'Name Ascending', current: true },
    { value: 'name_desc', label: 'Name Descending', current: false },
  ];

  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortValue, setSortValue] = useState(sortOptions[0].value);

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  // Filter and sort clients
  const filteredClients = allClients.filter((client: any) => {
    let matches = true;

    // Filter by Verification Status
    if (
      selectedFilters['IDVerificationStatus'] &&
      selectedFilters['IDVerificationStatus'].length > 0
    ) {
      matches =
        matches && selectedFilters['IDVerificationStatus'].includes(client.IDVerificationStatus);
    }

    // Filter by Search Query
    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      const clientName =
        client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
          ? (client.BusinessName || '').toLowerCase()
          : (`${client.FirstName} ${client.LastName}` || '').toLowerCase();
      matches = matches && clientName.includes(searchLower);
    }

    return matches;
  });

  // Sort clients
  const sortedClients = filteredClients.sort((a: any, b: any) => {
    const nameA = `${a.FirstName} ${a.LastName}`.toLowerCase();
    const nameB = `${b.FirstName} ${b.LastName}`.toLowerCase();
    if (sortValue === 'name_asc') {
      return nameA.localeCompare(nameB);
    } else if (sortValue === 'name_desc') {
      return nameB.localeCompare(nameA);
    }
    return 0;
  });

  return (
    <div className="bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark transition-colors duration-300 h-[calc(100vh-90px)] flex flex-col overflow-hidden px-4">
      {/* Stats */}
      {/* <section className="mb-1">
        <Stats stats={statsData} />
      </section> */}

      {/* Header */}
      <Header
        title={t('Clients')}
        subtitle={t('Manage your client relationships and cases.')}
        actionLabel={t('Add Client')}
        onActionClick={handleAddClientClick}
      />

      {/* Filter Bar */}
      <section className="mb-5">
      <FilterSearchBar
      filters={filters}
      sortOptions={sortOptions}
      selectedFilters={selectedFilters}
      onFilterChange={handleFilterChange}
      onSortChange={handleSortChange}
      onSearchChange={handleSearchChange}
    />
      </section>

      {/* Clients List */}
      <section className="mb-8">
        {isClientsLoading ? (
          <div className="flex justify-center items-center h-64">
            <ClipLoader color="#000" loading={isClientsLoading} size={30} />
          </div>
        ) : (
          <div className="space-y-4 overflow-y-auto max-h-[calc(100vh-380px)] px-4">
            {sortedClients.length > 0 ? (
              sortedClients.map((client: any) => {
                // Generate badges
                const badges: BadgeItem[] = [];

                // Verification Status Badge
                const verificationStatus = client.IDVerificationStatus;
                badges.push({
                  label: verificationStatus,
                  color:
                    verificationStatus === 'Verified'
                      ? 'green'
                      : verificationStatus === 'Pending'
                      ? 'yellow'
                      : 'red',
                });

                // Client Type Badge
                const clientType =
                      client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
                        ? 'Business'
                        : 'Individual';

                    badges.push({
                      label: clientType,
                      color: clientType === 'Business' ? 'blue' : 'gray',
                    });

                    // Include Business Identity Number if available
                    // if (client.BusinessIdentityNumber) {
                    //   badges.push({
                    //     label: 'Business ID',
                    //     value: client.BusinessIdentityNumber,
                    //   });
                    // }

                // Prepare details
                const details = [
                  {
                    label: 'Email',
                    value: client.Email || 'No email provided',
                  },
                  {
                    label: 'Phone',
                    value: client.PhoneNumber || 'No phone number provided',
                  },
                ];

                return (
                  <ListItem
                    key={client.ClientID}
                    title={
                      client.UserTypeName === 'BusinessAdmin' || client.UserTypeName === 'BusinessEmployee'
                        ? client.BusinessName || 'No business name provided'
                        : `${client.FirstName} ${client.LastName}`.trim() || 'No name provided'
                    }
                    subtitle={client.Address || 'No address provided'}
                    details={details}
                    badges={badges}
                    onClick={() => handleClientClick(client)}
                    link={`clients/${client.ClientID}`}
                  />
                );
              })
            ) : (
              <p className="text-center">{t('No clients available')}</p>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default Clients;
