import React, { useCallback, useEffect, useState } from 'react';
import Badge from '../../shared/TailwindComponents/Badge';
import { RootState, useAppDispatch } from '../../../store';
import { fetchEmployeeDetails } from '../../../store/actions/organization/employeeActions';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';

interface EmployeeDetailParams {
  employeeId: string;
}

const EmployeeDetail: React.FC = () => {
  const { employeeId } = useParams<EmployeeDetailParams>();
  const dispatch = useAppDispatch();
  const { error } = useSelector((state: RootState) => state.employees);
  const { setBreadcrumbs } = useBreadcrumbs();
  const [employee, setEmployee] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const employeeStatus = employee?.is_deleted ? 'Inactive' : 'Active';
  const fetchDetails = useCallback(async () => {
    const details = await dispatch(fetchEmployeeDetails(employeeId));
    if (details) {
      setEmployee(details)
    }
    setLoading(false)
  }, [employeeId, dispatch])

  useEffect(() => {
    setLoading(true)
    fetchDetails()
  }, [fetchDetails]);



  useEffect(() => {
    if (employee) {
      const employeeName = `${employee.FirstName} ${employee.LastName}`;
      setBreadcrumbs([
        { name: 'Management', href: '/management', current: false },
        { name: 'Employees', href: '/management/employees', current: false },
        { name: employeeId, href: `/management/employees/${employeeId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [employee, employeeId, setBreadcrumbs]);


  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ClipLoader color="#33699f" loading={loading} size={30} />
      </div>
    );
  }


  if (error || !employee) {
    return <div className="text-red-600">Error: {error || 'Employee not found.'}</div>;
  }

  return (
    <div className="p-6 mx-2 bg-white dark:bg-gray-800 rounded-lg shadow transition-colors duration-300">
      <div className="flex items-center space-x-4 mb-6">
        {/* Profile Picture or Initials */}
        <div className="w-16 h-16 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center text-xl font-bold text-white">
          {employee.ProfilePicture ? (
            <img
              src={employee.ProfilePicture}
              alt={`${employee.FirstName} ${employee.LastName}`}
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <span className="text-gray-800 dark:text-gray-300">
              {employee.FirstName?.charAt(0)}
              {employee.LastName?.charAt(0)}
            </span>
          )}
        </div>

        {/* Employee Name and Status */}
        <div className="flex-1">
          <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
            {employee.FirstName} {employee.LastName}
          </h2>
          <Badge
            color={employeeStatus === 'Active' ? 'green' : 'gray'}
            className="text-sm font-medium"
          >
            {employeeStatus}
          </Badge>
        </div>
      </div>

      {/* Employee Details */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Email:</strong> {employee.Email}
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Phone Number:</strong> {employee.PhoneNumber}
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Designation:</strong>{' '}
          {employee.Designation || 'N/A'}
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Company Role:</strong>{' '}
          {employee.CompanyRole || 'N/A'}
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Experience Years:</strong>{' '}
          {employee.ExperienceYears || 'N/A'}
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Completed Cases:</strong>{' '}
          {employee.CompletedCases || 'N/A'}
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Ratings:</strong>{' '}
          {employee.RatingsAverage || 'N/A'} ({employee.RatingsCount || 0} reviews)
        </div>
        <div className="text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Specializations:</strong>{' '}
          {employee.Specializations
            ? employee.Specializations.join(', ')
            : 'N/A'}
        </div>
        <div className="col-span-1 md:col-span-2 text-gray-700 dark:text-gray-300">
          <strong className="font-medium">Bio:</strong> {employee.Bio || 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetail;
