
import React from 'react';
import Badge from '../../shared/TailwindComponents/Badge';
import { useHistory } from 'react-router-dom';
import { Lawyer } from '../../Marketplace/shared/types';
import { FaStar } from 'react-icons/fa';
import Button from './Button';

interface LawyerCardProps {
  lawyer: Lawyer;
  onClick?: () => void; 
}

const LawyerCard: React.FC<LawyerCardProps> = ({ lawyer, onClick }) => {
  const history = useHistory();

  const handleViewProfile = () => {
    if (onClick) {
      onClick();
    } else {
      history.push(`/marketplace/lawyer/${lawyer.UserID}`, { lawyer });
  };
};

  const {
    FirstName,
    LastName,
    ProfilePicture,
    RatingsAverage,
    RatingsCount,
    ExperienceYears,
    Specializations,
    Bio,
    CompanyRole,
  } = lawyer;

  const fullName = `${FirstName} ${LastName}`;

  return (
    <div className="relative hover:bg-gray-100 hover:dark:bg-gray-700 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 pt-16 transition-transform duration-300 card-hover mb-16 cursor-pointer transition-all" onClick={handleViewProfile}>
      {/* Profile Picture */}
      <div className="absolute -top-16 left-1/2 transform -translate-x-1/2">
        {ProfilePicture ? (
          <img
            src={ProfilePicture}
            alt={fullName}
            className="w-32 h-32 rounded-full border-4 border-white dark:border-gray-800 object-cover"
          />
        ) : (
          <div className="w-32 h-32 rounded-full bg-secondary-900 flex items-center justify-center text-3xl text-sky-300 border-4 border-white dark:border-gray-800">
            {FirstName[0]}
            {LastName[0]}
          </div>
        )}
      </div>

      <div className="text-center mt-5">
        <h3 className="text-2xl font-bold mb-2 text-gray-800 dark:text-white">{fullName}</h3>
        {/* Ratings */}
        <div className="flex items-center justify-center mb-3">
          <FaStar className="text-yellow-500 mr-1" />
          <span className="text-sm text-gray-600 dark:text-gray-300">
            {RatingsAverage?.toFixed(1) || 'N/A'} ({RatingsCount || 0} reviews)
          </span>
        </div>
        {/* Affiliation Badge */}
        <div className="">
          {CompanyRole ? (
            <Badge color="green">Law Firm Affiliated</Badge>
          ) : (
            <Badge color="blue">Independent Lawyer</Badge>
          )}
        </div>
        {/* Experience */}
        <p className="text-sm text-gray-600 dark:text-gray-300 mb-3">
          {ExperienceYears ? `${ExperienceYears} years of experience` : 'Experience not specified'}
        </p>
        {/* Specializations */}
        {Specializations && Specializations.length > 0 ? (
          <div className="flex flex-wrap justify-center mb-3">
            {Specializations.map((spec, index) => (
              <Badge key={index} color="blue" className="m-1">
                {spec}
              </Badge>
            ))}
          </div>
        ) : (
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-3">No specializations specified</p>
        )}
        {/* Bio */}
        {/* {Bio ? (
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-4 line-clamp-3">{Bio}</p>
        ) : (
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">No bio available</p>
        )} */}
        {/* Call to Action */}
        {/* <Button onClick={handleViewProfile} variant="primary">
          View Profile
        </Button> */}
      </div>
    </div>
  );
};

export default LawyerCard;
