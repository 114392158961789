
import { useTranslation } from "react-i18next";
import GetReports from "../components/Reports/GetReports";
import { config } from "../utils/config";

function ReportsPage() {
    const { t } = useTranslation();
    const isDemoEnvironment = window.location.hostname.includes('demo.pons.io') 
    || window.location.hostname.includes('beta.pons.io');
    
    return (
        <>
        {!isDemoEnvironment ? (
            <GetReports /> 
        ) : (
            <div className="flex h-calc-100vh-90px  justify-center items-center text-neutral-400">Coming soon</div>
        )} 
        </>
    )
}

export default ReportsPage;