import React, { useState } from 'react';
import {
  DocumentIcon,
  DocumentTextIcon,
  XMarkIcon,
  PaperClipIcon,
  CameraIcon,
} from '@heroicons/react/24/solid';

interface FileInputWithPreviewProps {
  accept?: string;
  file: File | null;
  setFile: (file: File | null) => void;
}

const getFileIcon = (fileType: string) => {
  if (fileType.includes('pdf')) {
    return <DocumentIcon className="h-6 w-6 text-red-500" />;
  } else if (
    fileType.includes('wordprocessingml') ||
    fileType.includes('msword') ||
    fileType.includes('officedocument')
  ) {
    return <DocumentTextIcon className="h-6 w-6 text-blue-500" />;
  } else if (fileType.includes('image')) {
    return <CameraIcon className="h-6 w-6 text-green-500" />;
  } else if (fileType.includes('text')) {
    return <DocumentTextIcon className="h-6 w-6 text-gray-500" />;
  } else {
    return <DocumentIcon className="h-6 w-6 text-gray-500" />;
  }
};

const FileInputWithPreview: React.FC<FileInputWithPreviewProps> = ({ accept, file, setFile }) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFile = e.dataTransfer.files[0];
      setFile(droppedFile);
      e.dataTransfer.clearData();
    }
  };

  return (
    <div className="flex flex-col items-center">
      <label
        className={`w-full border-2 border-dashed rounded-md p-4 text-center cursor-pointer ${
          isDragOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <PaperClipIcon className="h-8 w-8 mx-auto text-gray-400" />
        <p className="mt-2 text-sm text-gray-600">
          {file ? 'Drag & drop to replace file, or click to select file' : 'Drag & drop a file here, or click to select file'}
        </p>
        <input
          type="file"
          accept={accept}
          className="hidden"
          onChange={handleFileChange}
        />
      </label>
      {file && (
        <div className="flex items-center mt-4">
          {getFileIcon(file.type)}
          <span className="ml-2 text-sm">{file.name}</span>
          <button onClick={handleRemoveFile} className="ml-2 text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
};

export default FileInputWithPreview;
