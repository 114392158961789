import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLike, AiOutlineDislike, AiOutlineCopy, AiOutlineArrowDown } from 'react-icons/ai';
import { BsPersonCircle } from 'react-icons/bs';
import { Message } from './types';
import WelcomeScreen from './WelcomeScreen';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import PromptList from './PromptList';
import MessageBubble from './MessageBubble';
import { submitChatFeedback } from '../../store/actions/ChatAction';
import { ShowPromptFeedback } from '../../store/actions/modalActions';

interface ChatBotProps {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  isTyping: boolean;
  showPrompts: boolean;
  onSendMessage: (text: string) => void;
  onFileSelect: (index: number, file: File | null) => void;
  files: (File | null)[];
  className?: string;
  speechKey: string;
  serviceRegion: string;
  aiSettings: any; 
}

const ChatBot: React.FC<ChatBotProps> = ({
  messages,
  setMessages,
  isTyping,
  showPrompts,
  onSendMessage,
  onFileSelect,
  files,
  className,
  speechKey,
  serviceRegion,
  aiSettings
}) => {
  const { t } = useTranslation();
  const messageEndRef = useRef<HTMLDivElement>(null);
  const selectedModel = useSelector((state: RootState) => state.model.selectedModel);
  const [isAutoScroll, setIsAutoScroll] = useState(true);
  const messageListRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const userName = `${userInfo.FirstName} ${userInfo.LastName}`;
  const [showScrollDownButton, setShowScrollDownButton] = useState(false);


  // useEffect(() => {
  //   messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [messages]);

  // useEffect(() => {
  //   if (isAutoScroll) {
  //     messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [messages]);

  useEffect(() => {
    if (isAutoScroll && messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10;
      setIsAutoScroll(atBottom);
      setShowScrollDownButton(!atBottom);
    }
  };


  const handleLike = (messageId: string) => {
    // Update message state
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId
          ? { ...msg, liked: !msg.liked, disliked: false }
          : msg
      )
    );

    // Dispatch feedback action
    const message = messages.find((msg) => msg.id === messageId);
    if (message) {
      dispatch(ShowPromptFeedback()).then((comment: string) => {

      dispatch(submitChatFeedback(messageId.toString(), message.ChatGroupID!, 'yes', '', '' ));
      })
    }
  };

  const handleDislike = (messageId: string) => {
    // Update message state
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === messageId
          ? { ...msg, disliked: !msg.disliked, liked: false }
          : msg
      )
    );

    // Dispatch feedback action with comment (optional)
    const message = messages.find((msg) => msg.id === messageId);
    if (message) {
      // Optionally prompt user for comment
      if (message) {
        dispatch(ShowPromptFeedback()).then((comment: string) => {
  
        dispatch(submitChatFeedback(messageId.toString(), message.ChatGroupID!, 'yes', '', comment ));
        })
    }
  };
  };

  const handleCopy = (messageId: string) => {
    // The copy action is handled in MessageBubble
  };

  const scrollToBottom = (force: boolean = false) => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({
        top: messageListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    if (force) {
      setIsAutoScroll(true);
    }
  };

  const scrollToBottomButtonClick = () => {
    scrollToBottom(true); // Set isAutoScroll to true when user clicks the button
  };


  // Display WelcomeScreen if no messages and not browsing prompts
  if (messages.length === 0 && !showPrompts) {
    return      ( 
    <WelcomeScreen
    onFileSelect={onFileSelect}
    files={files}
  />
    );
  }

  return (
    <div className={`relative flex flex-col flex-1 overflow-hidden ${className}`}>
      {/* Display PromptList if browsing prompts */}
      {showPrompts ? (
        <PromptList
          model={selectedModel}
          onPromptSelect={onSendMessage}
        />
      ) : (
        <>
          <div
            className="p-2 flex flex-col flex-1 overflow-y-auto"
            ref={messageListRef}
            onScroll={handleScroll}
          >
            {messages.map((message) => (
              <MessageBubble
                key={message.id! + message.text.length}
                message={message}
                onDislike={handleDislike}
                onLike={handleLike}
                onCopy={handleCopy}
                userName={userName}
                isAutoScroll={isAutoScroll}
                scrollToBottom={scrollToBottom}
                speechKey={speechKey}
                serviceRegion={serviceRegion}
                aiSettings={aiSettings}
              />
            ))}
            <div ref={messageEndRef} />
          </div>
          {showScrollDownButton && (
      <button
        className="absolute bottom-5 right-5 bg-neutral-400 text-white p-2 rounded-full shadow-md hover:bg-neutral-300 transition"
        onClick={scrollToBottomButtonClick}
      >
        <AiOutlineArrowDown size={18} />
      </button>
    )}
        </>
      )}
    </div>
  );
};
export default ChatBot;
