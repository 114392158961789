import React from 'react';

interface CheckboxProps {
  id?: string;
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({ id, label, checked, onChange, className }) => {
  const uniqueId = id || `checkbox-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div className={`flex items-center ${className}`}>
      <input
        id={uniqueId}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="form-checkbox h-4 w-4 text-primary-600 transition duration-150 ease-in-out"
      />
      <label htmlFor={uniqueId} className="ml-2 block text-sm leading-5">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
