import React from 'react';

interface ColorInputProps {
  label: string;
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ColorInput: React.FC<ColorInputProps> = ({ label, id, name, value, onChange }) => {
  return (
    <div className="flex items-center space-x-4">
      {label && (
        <label htmlFor={id} className="text-sm font-medium">
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        {/* Custom Color Swatch */}
        <div
          className="w-10 h-10 rounded-full cursor-pointer transition-transform duration-200 transform hover:scale-110 shadow-md"
          style={{ backgroundColor: value }}
        ></div>
        {/* Hidden Color Input */}
        <input
          type="color"
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className="absolute top-0 left-0 w-10 h-10 opacity-0 cursor-pointer"
        />
        {/* Hex Code Display */}
        <span className="ml-4 text-sm font-mono">{value.toUpperCase()}</span>
      </div>
    </div>
  );
};

export default ColorInput;
