import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../store';
import { getUserType } from '../../utils/authUtils';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Button from '../shared/TailwindComponents/Button';
import { editCase } from '../../store/actions/marketplace2/caseActions';
import { useTranslation } from 'react-i18next';

interface FinancialDataSectionProps {
  financialData: any;
  caseId: string;
}

const FinancialDataSection: React.FC<FinancialDataSectionProps> = ({ financialData, caseId }) => {
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const { t } = useTranslation();
  const isLawyer = userType === 'IndependentLawyer' || userType === 'LawFirmAdmin' || userType === 'LawFirmEmployee';

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<any>(financialData || {});

  useEffect(() => {
    setFormData(financialData || {});
  }, [financialData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    const updatedCaseData = {
      CaseID: caseId,
      FinancialData: formData,
    };
    await dispatch(editCase(updatedCaseData));
    setIsEditing(false);
  };

  const handleCancel = () => {
    setFormData(financialData || {});
    setIsEditing(false);
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">{t('Financial Data')}</h3>
        {isLawyer && !isEditing && (
          <Button variant="secondary" onClick={() => setIsEditing(true)}>
            {t('Edit')}
          </Button>
        )}
      </div>
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {Object.entries(formData || {}).map(([key, value]) => (
          <div key={key}>
            <p className="text-sm font-medium text-gray-500">{t(key.replace(/([A-Z])/g, ' $1'))}</p>
            {isEditing ? (
              key === 'FinancialNotes' ? (
                <Textarea
                  id={key}
                  name={key}
                  value={value as string}
                  onChange={handleInputChange} label={''}                />
              ) : (
                <Input
                  id={key}
                  name={key}
                  value={value as string}
                  onChange={handleInputChange}
                  type="text"
                />
              )
            ) : (
              <p className="text-sm">{value as string}</p>
            )}
          </div>
        ))}
      </div>
      {isEditing && (
        <div className="mt-4 flex gap-x-4">
          <Button variant="primary" onClick={handleSave}>
            {t('Save')}
          </Button>
          <Button variant="destructive" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FinancialDataSection;
