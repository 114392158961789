// EngagementDetailsWrapper.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getUserType } from '../../../utils/authUtils';
import LawyerEngagementDetails from '../Lawyer/EngagementDetails';
import ClientEngagementDetails from '../Client/ClientEngagementDetails.tsx';

const EngagementDetailsWrapper: React.FC = () => {
  const userType = getUserType();

  if (userType === 'IndependentLawyer' || userType === 'LawFirmAdmin' || userType === 'LawFirmEmployee') {
    return <LawyerEngagementDetails />;
  } else if (userType === 'IndividualClient' || userType === 'BusinessAdmin' || userType === 'BusinessEmployee') {
    return <ClientEngagementDetails />;
  } else {
    return <div>Unauthorized</div>;
  }
};

export default EngagementDetailsWrapper;
