import React, { useState } from 'react';
import { useAppDispatch } from '../../../store';
import { requestCaseAccess } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';

interface RequestAccessProps {
  caseId: string;
}

const RequestAccessComponent: React.FC<RequestAccessProps> = ({ caseId }) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRequestAccess = async () => {
    setLoading(true);
    await dispatch(requestCaseAccess({ CaseID: caseId, Message: message }));
    setLoading(false);
    setMessage('');
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-800 p-6 rounded-md">
      <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Request Access</h2>
      <Textarea
              rows={5}
              placeholder="Add a message (optional)"
              value={message}
              onChange={(e) => setMessage(e.target.value)} id={''} label={''}      />
      <Button
        onClick={handleRequestAccess}
        disabled={loading}
        variant='primary'
        className='w-full mt-2'
      >
        {loading ? 'Requesting...' : 'Request Access'}
      </Button>
    </div>
  );
};

export default RequestAccessComponent;
