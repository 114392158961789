import React from 'react';
import clsx from 'clsx';
import { BadgeColor } from './types/badgeTypes';
import { Tooltip } from '@mui/material';


interface BadgeProps {
  color?: BadgeColor;
  children: React.ReactNode;
  onClose?: () => void;
  className?: string; // Allows additional custom classes
  tooltip?: string;
}

const colorStyles: Record<
  NonNullable<BadgeProps['color']>,
  {
    light: {
      bg: string;
      text: string;
      ring: string;
    };
    dark: {
      bg: string;
      text: string;
      ring: string;
    };
  }
> = {
  gray: {
    light: {
      bg: 'bg-gray-50',
      text: 'text-gray-600',
      ring: 'ring-gray-500/10',
    },
    dark: {
      bg: 'dark:bg-gray-400/10',
      text: 'dark:text-gray-400',
      ring: 'dark:ring-gray-400/20',
    },
  },
  red: {
    light: {
      bg: 'bg-red-50',
      text: 'text-red-700',
      ring: 'ring-red-600/10',
    },
    dark: {
      bg: 'dark:bg-red-400/10',
      text: 'dark:text-red-400',
      ring: 'dark:ring-red-400/20',
    },
  },
  yellow: {
    light: {
      bg: 'bg-yellow-50',
      text: 'text-yellow-800',
      ring: 'ring-yellow-600/20',
    },
    dark: {
      bg: 'dark:bg-yellow-400/10',
      text: 'dark:text-yellow-500',
      ring: 'dark:ring-yellow-400/20',
    },
  },
  green: {
    light: {
      bg: 'bg-green-50',
      text: 'text-green-700',
      ring: 'ring-green-600/20',
    },
    dark: {
      bg: 'dark:bg-green-500/10',
      text: 'dark:text-green-400',
      ring: 'dark:ring-green-500/20',
    },
  },
  blue: {
    light: {
      bg: 'bg-blue-50',
      text: 'text-blue-700',
      ring: 'ring-blue-700/10',
    },
    dark: {
      bg: 'dark:bg-blue-400/10',
      text: 'dark:text-blue-400',
      ring: 'dark:ring-blue-400/30',
    },
  },
  indigo: {
    light: {
      bg: 'bg-indigo-50',
      text: 'text-indigo-700',
      ring: 'ring-indigo-700/10',
    },
    dark: {
      bg: 'dark:bg-indigo-400/10',
      text: 'dark:text-indigo-400',
      ring: 'dark:ring-indigo-400/30',
    },
  },
  purple: {
    light: {
      bg: 'bg-purple-50',
      text: 'text-purple-700',
      ring: 'ring-purple-700/10',
    },
    dark: {
      bg: 'dark:bg-purple-400/10',
      text: 'dark:text-purple-400',
      ring: 'dark:ring-purple-400/30',
    },
  },
  pink: {
    light: {
      bg: 'bg-pink-50',
      text: 'text-pink-700',
      ring: 'ring-pink-700/10',
    },
    dark: {
      bg: 'dark:bg-pink-400/10',
      text: 'dark:text-pink-400',
      ring: 'dark:ring-pink-400/20',
    },
  },
};

const Badge: React.FC<BadgeProps> = ({ color = 'gray', children, onClose, className, tooltip }) => {
  const styles = colorStyles[color];

  const classes = clsx(
    'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
    styles.light.bg,
    styles.light.text,
    styles.light.ring,
    styles.dark.bg,
    styles.dark.text,
    styles.dark.ring,
    className // Apply additional classes if provided
  );

  return (
      <Tooltip title={tooltip && tooltip}>
    <span className={classes}>
      {children}
      {onClose && (
        <button
        type="button"
        className="ml-1 text-xs text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
        onClick={onClose}
        aria-label="Remove badge"
        >
          ✕
        </button>
      )}
    </span>
      </Tooltip>
  );
};

export default Badge;