import React from 'react';
import { Link } from 'react-router-dom';
import { getUserType } from '../store/actions/ChatAction';

const VaultPage: React.FC = () => {
    const userType = getUserType();
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Vault</h1>
      <p>Select a section to proceed:</p>
      <ul className="mt-4 space-y-2">
        <li>
          <Link to="/cases" className="text-blue-500 hover:underline">
            Cases
          </Link>
        </li>
        {userType.includes('Business') && 
        <li>
          <Link to="/vault/projects" className="text-blue-500 hover:underline">
            Projects
          </Link>
        </li>
        }
        {userType.includes('Law') && 
        <li>
          <Link to="/vault/clients" className="text-blue-500 hover:underline">
            Clients
          </Link>
        </li>
        }
        <li>
          <Link to="/vault/files" className="text-blue-500 hover:underline">
            Files
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default VaultPage;
