import { useContext, useEffect } from "react";
import Cases from "../components/Cases/Cases";
import { LayoutContext } from "../contexts/LayoutContext";

function CaseManagementPage() {
  const { setIsContentScrollable } = useContext(LayoutContext);

  useEffect(() => {
    setIsContentScrollable(false);

    return () => {
      setIsContentScrollable(true);
    };
  }, [setIsContentScrollable]);



  return (
    <Cases />
  )
}

export default CaseManagementPage;