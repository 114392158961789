import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { ClipLoader } from 'react-spinners';
import { format } from 'date-fns';
import { getUserType, getUserID } from '../../store/actions/ChatAction';
import { completeTask, deleteTask, fetchAllTasks } from '../../store/actions/TasksActions';
import Badge from '../shared/TailwindComponents/Badge';
import Button from '../shared/TailwindComponents/Button';
import { Task } from './types';
import AddTask from './AddTaskComponent'; // Ensure the import path is correct
import StatCard from '../shared/TailwindComponents/StatCard';
import FilterSearchBar from '../shared/TailwindComponents/FilterSearchBar';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { usePermission } from '../../hooks/userPermissions';

const TaskDeadlineTracker: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const userId = getUserID();
  const [isTasksLoading, setIsTasksLoading] = useState(false);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const tasks = useSelector((state: RootState) => state.tasks.tasks || []);
  const [searchQuery, setSearchQuery] = useState('');
  const history = useHistory();
  const { hasAccess, isReadOnly, loading } = usePermission('dashboard');
  const userInfo = useSelector((state: RootState) => state.user.userInfo);


  // Default Filters and Sort
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({
    Status: ['Active'], // Default filter to 'Active' tasks
  });
  const [sortValue, setSortValue] = useState('deadline_asc'); // Default sort


  useEffect(() => {
    const fetchTasks = async () => {
      setIsTasksLoading(true);
      try {
        await dispatch(fetchAllTasks());
      } finally {
        setIsTasksLoading(false);
      }
    };
    fetchTasks();
  }, [dispatch]);

  const handleOpenAddTask = () => {
    setIsAddingTask(true);
  };

  const handleCancelAddTask = () => {
    setIsAddingTask(false);
  };

  const handleTaskAdded = () => {
    setIsAddingTask(false);
    dispatch(fetchAllTasks());
  };

  const handleCompleteTask = async (taskId: string) => {
    try {
      await dispatch(completeTask(taskId));
      dispatch(fetchAllTasks());
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await dispatch(deleteTask(taskId));
      await dispatch(fetchAllTasks());
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  // Process and sort tasks
  const sortedTasks = tasks
    .map((task: Task) => {
      const taskDeadline = new Date(task.TaskDeadline);
      const timeDifference = taskDeadline.getTime() - new Date().getTime();
      return {
        ...task,
        timeDifference: isNaN(timeDifference) ? Infinity : timeDifference,
        formattedDeadline: !isNaN(taskDeadline.getTime())
          ? format(taskDeadline, 'dd-MM-yyyy HH:mm')
          : t('Invalid date'),
      };
    })
    .sort((a, b) => a.timeDifference - b.timeDifference);

  // Task Filters and Sort Options
  const taskFilters = [
    {
      id: 'Status',
      name: 'Status',
      options: [
        { value: 'Active', label: 'Active' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Deleted', label: 'Deleted' },
      ],
    },
  ];

  const taskSortOptions = [
    { value: 'deadline_asc', label: 'Deadline Ascending', current: true },
    { value: 'deadline_desc', label: 'Deadline Descending', current: false },
    { value: 'title_asc', label: 'Title Ascending', current: false },
    { value: 'title_desc', label: 'Title Descending', current: false },
  ];

  const handleFilterChange = (filterName: string, selectedValues: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: selectedValues,
    }));
  };

  const handleSortChange = (value: string) => {
    setSortValue(value);
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  // Process and sort tasks
  const filteredSortedTasks = tasks
    .filter((task: Task) => {
      const statusFilter = selectedFilters['Status'];
      const matchesStatus = statusFilter ? statusFilter.includes(task.Status as string) : true;
      const matchesSearch =
        (task.TaskTitle ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
        (task.TaskDescription ?? '').toLowerCase().includes(searchQuery.toLowerCase());
      return matchesStatus && matchesSearch;
    })
    .sort((a, b) => {
      // First, prioritize tasks with status 'Active'
      if (a.Status === 'Active' && b.Status !== 'Active') return -1;
      if (a.Status !== 'Active' && b.Status === 'Active') return 1;

      // Then, prioritize 'Completed' over 'Deleted'
      if (a.Status === 'Completed' && b.Status === 'Deleted') return -1;
      if (a.Status === 'Deleted' && b.Status === 'Completed') return 1;

      // If statuses are the same, proceed with the selected sort option
      if (sortValue === 'deadline_asc')
        return new Date(a.TaskDeadline).getTime() - new Date(b.TaskDeadline).getTime();
      if (sortValue === 'deadline_desc')
        return new Date(b.TaskDeadline).getTime() - new Date(a.TaskDeadline).getTime();
      if (sortValue === 'title_asc') return a.TaskTitle.localeCompare(b.TaskTitle);
      if (sortValue === 'title_desc') return b.TaskTitle.localeCompare(a.TaskTitle);

      // Default to deadline ascending if no sort option matches
      return new Date(a.TaskDeadline).getTime() - new Date(b.TaskDeadline).getTime();
    });

  console.log(filteredSortedTasks, "filteredSortedTasks");

  const handleDetails = (taskId: string) => {
    history.push(`/tasks/${taskId}`);
  }


  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 h-[calc(100vh-95px)] mx-4 flex flex-col">
      {/* Header */}
      {!isAddingTask && (
        <>
          <div className="grid grid-cols-2 divide-x rounded-lg overflow-hidden border mb-2">
            <StatCard
              title="Active Tasks"
              value={tasks.filter((task: Task) => task.Status === 'Active').length.toString()}
              icon={<i className="w-6 h-6 text-2xl text-pink-500 fas fa-calendar"></i>}
            />
            <StatCard
              title="Completed Tasks"
              value={tasks.filter((task: Task) => task.Status === 'Completed').length.toString()}
              icon={<i className="w-6 h-6 text-2xl text-green-500 fas fa-check-circle"></i>}
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
              {t('Task and Deadline Tracker')}
            </h2>
            {!isAddingTask && (
              <Button disabled={isReadOnly} variant="primary" onClick={handleOpenAddTask}>
                {t('Add Task')}
              </Button>
            )}
          </div>

          <section className="mb-5">
            <FilterSearchBar
              filters={taskFilters}
              sortOptions={taskSortOptions}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              onSortChange={handleSortChange}
              onSearchChange={handleSearchChange}
              sortValue={sortValue} // Pass the sort value
            />
          </section>
        </>
      )}

      {isAddingTask ? (
        <AddTask onCancel={handleCancelAddTask} onAdd={handleTaskAdded} />
      ) : (
        <div className="flex-1 overflow-hidden">
          {isTasksLoading ? (
            <div className="flex justify-center items-center h-full">
              <ClipLoader color="#33699f" loading={isTasksLoading} size={30} />
            </div>
          ) : filteredSortedTasks.length > 0 ? (
            <ul className="space-y-4 overflow-auto h-full">
              {filteredSortedTasks.map((task: Task) => (
                <li
                  key={task.TaskID}
                  className={`bg-gray-50 dark:bg-gray-700 p-4 rounded-md ${task.Status !== 'Active' ? 'opacity-50' : ''
                    }`}
                  onClick={() => handleDetails(task.TaskID)}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-medium text-gray-800 dark:text-white">
                        {task.TaskTitle}
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        {t('Deadline')}: {task.TaskDeadline ? dayjs(task.TaskDeadline).format('MMMM D, YYYY h:mm A') : 'N/A'}
                      </p>
                      <Badge
                        color={
                          task.Status === 'Active'
                            ? 'blue'
                            : task.Status === 'Completed'
                              ? 'green'
                              : task.Status === 'Deleted'
                                ? 'red'
                                : 'gray'
                        }
                        className="mt-1"
                      >
                        {task.Status}
                      </Badge>
                    </div>
                    <div className="flex space-x-2">
                      {(userType === 'LawFirmAdmin' ||
                        userType === 'BusinessAdmin' ||
                        (task.UserID === userInfo.UserID &&
                        task.AssignedBy === userInfo.UserID)) &&
                        task.Status === 'Active' && (
                          <>

                            <Button
                              variant="destructive"
                              onClick={(e) => { e.stopPropagation(); handleDeleteTask(task.TaskID) }}
                            >
                              {t('Delete')}
                            </Button>
                          </>
                        )}
                      <Button
                        variant="success"
                        onClick={(e) => { e.stopPropagation(); handleCompleteTask(task.TaskID) }}
                      >
                        {t('Complete')}
                      </Button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-center text-gray-600 dark:text-gray-300">
              {t('No tasks available')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskDeadlineTracker;
