import { AnyAction } from 'redux';
import { SUBMIT_BID_SUCCESS, ACCEPT_BID_SUCCESS, REJECT_BID_SUCCESS, WITHDRAW_BID_SUCCESS, SUBMIT_BID_FAILURE, ACCEPT_BID_FAILURE, REJECT_BID_FAILURE, WITHDRAW_BID_FAILURE, GET_CASE_BIDS_SUCCESS, GET_CASE_BIDS_FAILURE, GET_CLIENT_BIDS_REQUEST, GET_CLIENT_BIDS_SUCCESS, GET_CLIENT_BIDS_FAILURE } from '../../types';
import { Bid } from '../../../components/Marketplace/shared/types';

interface BidsState {
    bidsByCaseId: { [caseId: string]: any[] };
    clientBids: Bid[]; 
    isLoading: boolean;
    error: string | null;
  }
  
  const initialState: BidsState = {
    bidsByCaseId: {},
    clientBids: [], // Initialize
    isLoading: false,
    error: null,
  }
export const marketplaceBidsReducer = (state = initialState, action: AnyAction): BidsState => {
  switch (action.type) {
    case SUBMIT_BID_SUCCESS:
    case ACCEPT_BID_SUCCESS:
    case REJECT_BID_SUCCESS:
    case WITHDRAW_BID_SUCCESS:
      return { ...state, error: null };
    case SUBMIT_BID_FAILURE:
    case ACCEPT_BID_FAILURE:
    case REJECT_BID_FAILURE:
    case WITHDRAW_BID_FAILURE:
      return { ...state, error: action.payload };
    case GET_CASE_BIDS_SUCCESS:
      return {
        ...state,
        bidsByCaseId: {
          ...state.bidsByCaseId,
          [action.payload.caseId]: action.payload.bids,
        },
        error: null,
      };
    case GET_CASE_BIDS_FAILURE:
      return { ...state, error: action.payload };
      case GET_CLIENT_BIDS_REQUEST:
        return { ...state, isLoading: true, error: null };
      case GET_CLIENT_BIDS_SUCCESS:
        return { ...state, clientBids: action.payload, isLoading: false, error: null };
      case GET_CLIENT_BIDS_FAILURE:
        return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
