import React from 'react';
import { LawyerProfile } from '../shared/types';

interface LawyerProfileViewProps {
  lawyerProfile: LawyerProfile;
}

const LawyerProfileView: React.FC<LawyerProfileViewProps> = ({ lawyerProfile }) => {
  const {
    User,
    Bio,
    ExperienceYears,
    Specializations,
    LanguagesSpoken,
    Education,
    Certifications,
    Awards,
    Publications,
    ProfessionalMemberships,
    Portfolio,
    RatingsAverage,
    RatingsCount,
  } = lawyerProfile;

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-md shadow-md">
      <h2 className="text-xl font-semibold mb-4">Lawyer Profile</h2>
      <div className="flex items-center mb-4">
        {User && User.ProfilePicture && (
          <img
            src={User.ProfilePicture}
            alt={`${User.FirstName} ${User.LastName}`}
            className="h-16 w-16 rounded-full mr-4"
          />
        )}
        <div>
          <h3 className="text-lg font-medium">{User && `${User.FirstName} ${User.LastName}`}</h3>
          {RatingsAverage && (
            <p className="text-sm text-gray-500">
              {RatingsAverage} stars ({RatingsCount} reviews)
            </p>
          )}
        </div>
      </div>
      {Bio && (
        <div className="mb-4">
          <p className="text-sm text-gray-700 dark:text-gray-200 whitespace-pre-wrap">{Bio}</p>
        </div>
      )}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {ExperienceYears && (
          <div>
            <p className="text-sm font-medium text-gray-500">Experience Years</p>
            <p className="text-sm text-gray-700 dark:text-gray-200">{ExperienceYears}</p>
          </div>
        )}
        {Specializations && Specializations.length > 0 && (
          <div>
            <p className="text-sm font-medium text-gray-500">Specializations</p>
            <p className="text-sm text-gray-700 dark:text-gray-200">{Specializations.join(', ')}</p>
          </div>
        )}
        {LanguagesSpoken && LanguagesSpoken.length > 0 && (
          <div>
            <p className="text-sm font-medium text-gray-500">Languages Spoken</p>
            <p className="text-sm text-gray-700 dark:text-gray-200">{LanguagesSpoken.join(', ')}</p>
          </div>
        )}
        {Education && (
          <div>
            <p className="text-sm font-medium text-gray-500">Education</p>
            <p className="text-sm text-gray-700 dark:text-gray-200">{Education}</p>
          </div>
        )}
        {/* Add other fields as needed */}
      </div>
    </div>
  );
};

export default LawyerProfileView;
