import { IProjectState, IProject } from "../../components/Projects/ProjectsInterfaces";
import { ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, ADD_PROJECT_FAILURE, EDIT_PROJECT_REQUEST, EDIT_PROJECT_SUCCESS, EDIT_PROJECT_FAILURE, ADD_PROJECT_ASSIGNEE_REQUEST, ADD_PROJECT_ASSIGNEE_SUCCESS, ADD_PROJECT_ASSIGNEE_FAILURE, FETCH_PROJECTS_REQUEST, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE, FETCH_PROJECT_DETAILS_REQUEST, FETCH_PROJECT_DETAILS_SUCCESS, FETCH_PROJECT_DETAILS_FAILURE, DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE, APPROVE_PROJECT_REQUEST, APPROVE_PROJECT_SUCCESS, APPROVE_PROJECT_FAILURE, FETCH_USER_PROJECTS_REQUEST, FETCH_USER_PROJECTS_SUCCESS, FETCH_USER_PROJECTS_FAILURE } from "../types";

  
  interface ProjectAction {
    type: string;
    payload?: any;
  }
  
  const initialState: IProjectState = {
    projects: {},
    projectDetails: null,
    userProjects: {},
    loading: false,
    error: null,
  };
  
  export const projectReducer = (state = initialState, action: ProjectAction): IProjectState => {
    switch (action.type) {
      // Add Project
      case ADD_PROJECT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case ADD_PROJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          projects: {
            ...state.projects,
            [action.payload.ProjectID]: action.payload,
          },
        };
      case ADD_PROJECT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Edit Project
      case EDIT_PROJECT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case EDIT_PROJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          projects: {
            ...state.projects,
            [action.payload.ProjectID]: action.payload,
          },
        };
      case EDIT_PROJECT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Add Project Assignee
      case ADD_PROJECT_ASSIGNEE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case ADD_PROJECT_ASSIGNEE_SUCCESS:
        // Assuming payload contains updated project with new assignees
        return {
          ...state,
          loading: false,
          projects: {
            ...state.projects,
            [action.payload.ProjectID]: action.payload,
          },
        };
      case ADD_PROJECT_ASSIGNEE_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Fetch All Projects
      case FETCH_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_PROJECTS_SUCCESS:
            const fetchedProjects: Record<string, IProject> = action.payload.reduce((acc: any, project: IProject) => {
                acc[project.ProjectID] = project;
                return acc;
            }, {});
            return {
                ...state,
                loading: false,
                // projects: {
                //     ...state.projects,
                //     ...fetchedProjects,
                // },
                projects:  action.payload
            };
        case FETCH_PROJECTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

  
      // Fetch Project Details
      case FETCH_PROJECT_DETAILS_REQUEST:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case FETCH_PROJECT_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          projectDetails: action.payload,
        };
      case FETCH_PROJECT_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Delete Project
      case DELETE_PROJECT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
        case DELETE_PROJECT_SUCCESS:
          const { ProjectID } = action.payload;
          const { [ProjectID]: removedProject, ...remainingProjects } = state.projects;
          return {
            ...state,
            loading: false,
            projects: remainingProjects,
          };        
      case DELETE_PROJECT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Approve Project
      case APPROVE_PROJECT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case APPROVE_PROJECT_SUCCESS:
        return {
          ...state,
          loading: false,
          projects: {
            ...state.projects,
            [action.payload.ProjectID]: action.payload,
          },
        };
      case APPROVE_PROJECT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Fetch User Projects
      case FETCH_USER_PROJECTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_USER_PROJECTS_SUCCESS:
        const fetchedUserProjects: Record<string, IProject> = action.payload.reduce((acc: any, project: IProject) => {
          acc[project.ProjectID] = project;
          return acc;
        }, {});
        return {
          ...state,
          loading: false,
          userProjects: {
            ...state.userProjects,
            ...fetchedUserProjects,
          },
        };
      case FETCH_USER_PROJECTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  