import { AnyAction } from 'redux';
import { FETCH_CASE_FAILURE, FETCH_CASE_REQUEST, FETCH_CASE_SUCCESS, FETCH_CASES_FAILURE, FETCH_CASES_REQUEST, FETCH_CASES_SUCCESS,GENERATE_DOCUMENT_REQUEST,
    GENERATE_DOCUMENT_SUCCESS,
    GENERATE_DOCUMENT_FAILURE, 
    FETCH_MARKETPLACE_CASE_REQUEST,
    FETCH_MARKETPLACE_CASE_SUCCESS,
    FETCH_MARKETPLACE_CASE_FAILURE} from '../../actions/marketplace2/caseActions';

interface CaseState {
    isLoading: boolean;
    cases: any[];
    selectedCase: any | null;
    error: string | null;
    isGeneratingDocument: boolean;
    documentUrls: {
      pdf_url: string;
      docx_url: string;
    } | null;
    selectedMarketplaceCase: any | null;

  }
  
  const initialState: CaseState = {
    isLoading: false,
    cases: [],
    selectedCase: null,
    error: null,
    isGeneratingDocument: false,
    documentUrls: null,
    selectedMarketplaceCase: null,
  };
  

export const casesReducer = (state = initialState, action: AnyAction): CaseState => {
  switch (action.type) {
    case FETCH_CASES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_CASES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cases: action.payload,
        error: null,
      };
    case FETCH_CASES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      case FETCH_CASE_REQUEST:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case FETCH_CASE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          selectedCase: action.payload,
          error: null,
        };
      case FETCH_CASE_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload,
        };
        case GENERATE_DOCUMENT_REQUEST:
            return {
              ...state,
              isGeneratingDocument: true,
              documentUrls: null,
            };
          case GENERATE_DOCUMENT_SUCCESS:
            return {
              ...state,
              isGeneratingDocument: false,
              documentUrls: action.payload,
            };
          case GENERATE_DOCUMENT_FAILURE:
            return {
              ...state,
              isGeneratingDocument: false,
            };
            case FETCH_MARKETPLACE_CASE_REQUEST:
              return {
                ...state,
                isLoading: true,
                error: null,
              };
            case FETCH_MARKETPLACE_CASE_SUCCESS:
              return {
                ...state,
                isLoading: false,
                selectedMarketplaceCase: action.payload,
                error: null,
              };
            case FETCH_MARKETPLACE_CASE_FAILURE:
              return {
                ...state,
                isLoading: false,
                error: action.payload,
              };
    default:
      return state;
  }
};

export default casesReducer;
