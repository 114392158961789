import {
  PUBLISH_CASE_REQUEST,
  PUBLISH_CASE_SUCCESS,
  PUBLISH_CASE_FAILURE,
  RESPOND_TO_ACCESS_REQUEST_REQUEST,
  RESPOND_TO_ACCESS_REQUEST_SUCCESS,
  RESPOND_TO_ACCESS_REQUEST_FAILURE,
  INVITE_LAWYER_REQUEST,
  INVITE_LAWYER_SUCCESS,
  INVITE_LAWYER_FAILURE,
  GET_CASE_DETAILS_REQUEST,
  GET_CASE_DETAILS_SUCCESS,
  GET_CASE_DETAILS_FAILURE,
  PROPOSE_ENGAGEMENT_REQUEST,
  PROPOSE_ENGAGEMENT_SUCCESS,
  PROPOSE_ENGAGEMENT_FAILURE,
  ACCEPT_ENGAGEMENT_REQUEST,
  ACCEPT_ENGAGEMENT_SUCCESS,
  ACCEPT_ENGAGEMENT_FAILURE,
  DECLINE_ENGAGEMENT_REQUEST,
  DECLINE_ENGAGEMENT_SUCCESS,
  DECLINE_ENGAGEMENT_FAILURE,
  GET_FINAL_DOCUMENTS_REQUEST,
  GET_FINAL_DOCUMENTS_SUCCESS,
  GET_FINAL_DOCUMENTS_FAILURE,
  SUBMIT_LAWYER_RATING_REQUEST,
  SUBMIT_LAWYER_RATING_SUCCESS,
  SUBMIT_LAWYER_RATING_FAILURE,
  UPDATE_CASE_STATUS_REQUEST,
  UPDATE_CASE_STATUS_SUCCESS,
  UPDATE_CASE_STATUS_FAILURE,
  GET_MY_ACCESS_REQUESTS_REQUEST,
  GET_MY_ACCESS_REQUESTS_SUCCESS,
  GET_MY_ACCESS_REQUESTS_FAILURE,
  GET_MY_ENGAGEMENTS_REQUEST,
  GET_MY_ENGAGEMENTS_SUCCESS,
  GET_MY_ENGAGEMENTS_FAILURE,
  GET_ALL_LAWYERS_REQUEST,
  GET_ALL_LAWYERS_SUCCESS,
  GET_ALL_LAWYERS_FAILURE,
} from './MarketplaceReceiverTypes';

interface MarketplaceReceiverState {
  accessRequests: any[];
  engagements: any[];
  lawyers: any[];
  caseDetails: any | null;
  finalDocuments: any[];
  isLoading: boolean;
  error: string | null;
}

const initialState: MarketplaceReceiverState = {
  accessRequests: [],
  engagements: [],
  lawyers: [],
  caseDetails: null,
  finalDocuments: [],
  isLoading: false,
  error: null,
};

const marketplaceReceiverReducer = (state = initialState, action: any): MarketplaceReceiverState => {
  switch (action.type) {
    // Handle request actions
    case PUBLISH_CASE_REQUEST:
    case RESPOND_TO_ACCESS_REQUEST_REQUEST:
    case INVITE_LAWYER_REQUEST:
    case GET_CASE_DETAILS_REQUEST:
    case PROPOSE_ENGAGEMENT_REQUEST:
    case ACCEPT_ENGAGEMENT_REQUEST:
    case DECLINE_ENGAGEMENT_REQUEST:
    case GET_FINAL_DOCUMENTS_REQUEST:
    case SUBMIT_LAWYER_RATING_REQUEST:
    case UPDATE_CASE_STATUS_REQUEST:
    case GET_MY_ACCESS_REQUESTS_REQUEST:
    case GET_MY_ENGAGEMENTS_REQUEST:
    case GET_ALL_LAWYERS_REQUEST:
      return { ...state, isLoading: true, error: null };

    // Handle success actions
    case PUBLISH_CASE_SUCCESS:
    case RESPOND_TO_ACCESS_REQUEST_SUCCESS:
    case INVITE_LAWYER_SUCCESS:
    case PROPOSE_ENGAGEMENT_SUCCESS:
    case ACCEPT_ENGAGEMENT_SUCCESS:
    case DECLINE_ENGAGEMENT_SUCCESS:
    case SUBMIT_LAWYER_RATING_SUCCESS:
    case UPDATE_CASE_STATUS_SUCCESS:
      return { ...state, isLoading: false };

    case GET_CASE_DETAILS_SUCCESS:
      return { ...state, isLoading: false, caseDetails: action.payload };

    case GET_FINAL_DOCUMENTS_SUCCESS:
      return { ...state, isLoading: false, finalDocuments: action.payload };

    case GET_MY_ACCESS_REQUESTS_SUCCESS:
      return { ...state, isLoading: false, accessRequests: action.payload };

    case GET_MY_ENGAGEMENTS_SUCCESS:
      return { ...state, isLoading: false, engagements: action.payload };

    case GET_ALL_LAWYERS_SUCCESS:
      return { ...state, isLoading: false, lawyers: action.payload };

    // Handle failure actions
    case PUBLISH_CASE_FAILURE:
    case RESPOND_TO_ACCESS_REQUEST_FAILURE:
    case INVITE_LAWYER_FAILURE:
    case GET_CASE_DETAILS_FAILURE:
    case PROPOSE_ENGAGEMENT_FAILURE:
    case ACCEPT_ENGAGEMENT_FAILURE:
    case DECLINE_ENGAGEMENT_FAILURE:
    case GET_FINAL_DOCUMENTS_FAILURE:
    case SUBMIT_LAWYER_RATING_FAILURE:
    case UPDATE_CASE_STATUS_FAILURE:
    case GET_MY_ACCESS_REQUESTS_FAILURE:
    case GET_MY_ENGAGEMENTS_FAILURE:
    case GET_ALL_LAWYERS_FAILURE:
      return { ...state, isLoading: false, error: 'An error occurred' };

    default:
      return state;
  }
};

export default marketplaceReceiverReducer;
