// src/contexts/PermissionsContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import apis from '../utils/apis';

interface AccessRights {
  admin_access: boolean,
  ai_assistant_access: boolean,
  analytics_access: boolean,
  case_management_access: boolean,
  client_portal_access: boolean,
  dashboard_access: boolean,
  documents_access: boolean,
  feedback_access: boolean,
  finance_access: boolean,
  help_center_access: boolean,
  marketplace_access: boolean,
  message_center_access: boolean,
  projects_access: boolean,
  settings_access: boolean,
  time_tracking_access: boolean
}

interface ReadOnlyRights {
  admin_read_only: boolean,
  ai_assistant_read_only: boolean,
  analytics_read_only: boolean,
  case_management_read_only: boolean,
  client_portal_read_only: boolean,
  dashboard_read_only: boolean,
  documents_read_only: boolean,
  feedback_read_only: boolean,
  finance_read_only: boolean,
  help_center_read_only: boolean,
  marketplace_read_only: boolean,
  message_center_read_only: boolean,
  projects_read_only: boolean,
  settings_read_only: boolean,
  time_tracking_read_only: boolean
}

interface PermissionsContextProps {
  accessRights: AccessRights;
  readOnlyRights: ReadOnlyRights;
  loading: boolean;
}

const PermissionsContext = createContext<PermissionsContextProps | undefined>(undefined);

export const PermissionsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [accessRights, setAccessRights] = useState<AccessRights>({} as AccessRights);
  const [readOnlyRights, setReadOnlyRights] = useState<ReadOnlyRights>({} as ReadOnlyRights);
  const [loading, setLoading] = useState<boolean>(true);

  let userCred = localStorage.getItem('token') || sessionStorage.getItem('token');
  const accessToken = userCred ? JSON.parse(userCred).token : '';

  useEffect(() => {
    // Fetch the license details from your API
    const fetchPermissions = async () => {
      try {
        if (accessToken) {
          const response = await apis({
            method: "GET",
            url: "getLicenseDetails"
          });
          const data = response.data

          setAccessRights(data.license_details.general_license.access_rights);
          setReadOnlyRights(data.license_details.general_license.read_only_rights);
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPermissions();
  }, [accessToken]);

  return (
    <PermissionsContext.Provider value={{ accessRights, readOnlyRights, loading }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = (): PermissionsContextProps => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }
  return context;
};
