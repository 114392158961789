import React, { useEffect, useState } from 'react';
import Dropdown, { DropdownOption } from './Dropdown';
import Textarea from './Textarea';
import Input from './Input';
import Button from './Button';
import Checkbox from './CheckBox';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { fetchCases } from '../../../store/actions/marketplace2/caseActions';
import { inviteLawyerToCase } from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';


interface ProposeCaseModalProps {
  lawyerId: string;
  onClose: () => void;
}

const ProposeCaseModal: React.FC<ProposeCaseModalProps> = ({ lawyerId, onClose }) => {
  const dispatch = useAppDispatch();
  const cases = useSelector((state: RootState) => state.casesM.cases || []);
  const [selectedCaseId, setSelectedCaseId] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [budgetMin, setBudgetMin] = useState<number | null>(null);
  const [budgetMax, setBudgetMax] = useState<number | null>(null);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    dispatch(fetchCases());
  }, [dispatch]);

  const handleSubmit = async () => {
    if (!selectedCaseId) {
      setError('Please select a case.');
      return;
    }
    if (!acceptedTerms) {
      setError('You must accept the terms and conditions.');
      return;
    }
    try {
      await dispatch(
        inviteLawyerToCase({
          LawyerID: parseInt(lawyerId),
          CaseID: selectedCaseId,
          Message: description || undefined,
          BudgetMin: budgetMin || undefined,
          BudgetMax: budgetMax || undefined,
        })
      );
      onClose();
    } catch (error) {
      // Error handling is already done in the action
    }
  };

  const caseOptions: DropdownOption[] = cases.map((caseItem) => ({
    value: caseItem.CaseID,
    label: caseItem.Title || caseItem.CaseName || `Case ${caseItem.CaseID}`,
  }));

  return (
      <div className="bg-white dark:bg-gray-800 rounded-md space-y-4">
        <h2 className="text-xl font-semibold mb-4">Propose a Case</h2>
        <Dropdown
          label="Select a Case"
          options={caseOptions}
          onChange={(value) => setSelectedCaseId(value as string)}
          error={error}
          value={selectedCaseId}
        />
        <Textarea
          label="Message"
          placeholder="Enter a message to the lawyer..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          id="message-input"
        />
        <Input
          type="number"
          label="Minimum Budget"
          placeholder="Enter minimum budget"
          value={budgetMin !== null ? budgetMin.toString() : ''}
          onChange={(e) => setBudgetMin(e.target.value ? parseFloat(e.target.value) : null)}
        />
        <Input
          type="number"
          label="Maximum Budget"
          placeholder="Enter maximum budget"
          value={budgetMax !== null ? budgetMax.toString() : ''}
          onChange={(e) => setBudgetMax(e.target.value ? parseFloat(e.target.value) : null)}
        />
        <Checkbox
          label="I accept the terms and conditions"
          checked={acceptedTerms}
          onChange={(e) => setAcceptedTerms(e.target.checked)}
        />
        {error && <div className="text-red-500">{error}</div>}
        <div className="flex justify-end space-x-2">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Send Request
          </Button>
        </div>
      </div>
  );
};

export default ProposeCaseModal;
