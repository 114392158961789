import React from 'react';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'disabled'
    | 'destructive'
    | 'neutral';
  children: React.ReactNode;
  ariaLabel?: string;
  tooltip?: string;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  onClick,
  disabled,
  children,
  ariaLabel,
  type = 'button',
  className,
  tooltip,
  ...rest
}) => {
  const variantClasses = {
    primary:
      'bg-primary-500 text-white hover:bg-primary-600 focus:ring-primary-500',
    secondary:
      'bg-secondary-500 text-white hover:bg-secondary-700 focus:ring-secondary-500',
    success:
      'bg-success-500 text-white hover:bg-success-600 focus:ring-success-500',
    warning:
      'bg-warning-500 text-white hover:bg-warning-600 focus:ring-warning-500',
    danger:
      'bg-danger-500 text-white hover:bg-danger-600 focus:ring-danger-500',
    disabled: 'bg-disabled-500 text-gray-400 cursor-not-allowed',
    destructive:
      'bg-neutral-500 text-white hover:bg-danger-600 focus:ring-danger-500',
    neutral:
      'bg-neutral-100 dark:bg-neutral-900 text-gray-900 dark:text-gray-100 hover:bg-neutral-300 dark:hover:bg-neutral-800 focus:ring-gray-500 border border-1 border-gray-300',
  };

  const classes = clsx(
    'flex items-center justify-center py-2 px-4 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 transition text-sm',
    variantClasses[variant],
    {
      'opacity-50 cursor-not-allowed': disabled,
    },
    className
  );

  // Determine the number of children
  const childCount = React.Children.count(children);

  const contentClasses = clsx(
    'flex items-center w-full',
    {
      'justify-center': childCount === 1,
      'justify-between': childCount > 1,
    }
  );

  return (
    <button
      type={type}
      className={classes}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      {...rest}
    >
      {tooltip ? (
        <Tooltip title={tooltip}>
          <span className={contentClasses}>{children}</span>
        </Tooltip>
      ) : (
        <span className={contentClasses}>{children}</span>
      )}
    </button>
  );
};

export default Button;
