import { ThunkDispatch } from "redux-thunk";
import { RootState } from "..";
import { AnyAction } from "redux";
import apis from "../../utils/apis";
import { getUserID, getUserType } from "./ChatAction";
import { DELETE_CUSTOM_BLOB_DOCUMENT_FAILURE, DELETE_CUSTOM_BLOB_DOCUMENT_SUCCESS, FETCH_CUSTOM_BLOB_DOCUMENTS_FAILURE, FETCH_CUSTOM_BLOB_DOCUMENTS_REQUEST, FETCH_CUSTOM_BLOB_DOCUMENTS_SUCCESS, FETCH_CUSTOM_INDEX_INFO_FAILURE, FETCH_CUSTOM_INDEX_INFO_REQUEST, FETCH_CUSTOM_INDEX_INFO_SUCCESS, POLL_CUSTOM_INDEX_STATUS_START, POLL_CUSTOM_INDEX_STATUS_STOP, POST_ADDITIONAL_CUSTOM_FILES_FAILURE, POST_ADDITIONAL_CUSTOM_FILES_REQUEST, POST_ADDITIONAL_CUSTOM_FILES_SUCCESS, POST_CUSTOM_FILES_FAILURE, POST_CUSTOM_FILES_REQUEST, POST_CUSTOM_FILES_SUCCESS, RERUN_CUSTOM_INDEX_FAILURE, RERUN_CUSTOM_INDEX_REQUEST, RERUN_CUSTOM_INDEX_SUCCESS, UPDATE_CUSTOM_INDEX_STATUS_MESSAGE } from "../types";

// Action Types


// Action Creators

// Upload Custom AI Files
export const uploadCustomFiles = (file: File) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    const formData = new FormData();
    formData.append('File', file);
    dispatch({ type: POST_CUSTOM_FILES_REQUEST });
    try {
        const response = await apis({
            method: 'POST',
            url: 'ai/custom_agent/upload_files',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        dispatch({
            type: POST_CUSTOM_FILES_SUCCESS,
            payload: response.data
        });
        dispatch(startPollingCustomIndexStatus());
    } catch (error: any) {
        dispatch({
            type: POST_CUSTOM_FILES_FAILURE,
            payload: error
        });
    }
};

// Upload Additional Custom AI Files
export const uploadAdditionalCustomFiles = (file: File) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    const formData = new FormData();
    formData.append('File', file);
    dispatch({ type: POST_ADDITIONAL_CUSTOM_FILES_REQUEST });
    try {
        const response = await apis({ 
            method: 'POST',
            url: 'customai/additional-upload',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        dispatch({
            type: POST_ADDITIONAL_CUSTOM_FILES_SUCCESS,
            payload: response.data
        });
        dispatch(startPollingCustomIndexStatus());
    } catch (error: any) {
        dispatch({
            type: POST_ADDITIONAL_CUSTOM_FILES_FAILURE,
            payload: error
        });
    }
};

// Fetch Custom AI Index Info
export const getCustomIndexInfo = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: FETCH_CUSTOM_INDEX_INFO_REQUEST });
    try {
        const response = await apis({
            method: 'GET',
            url: 'ai/custom_agent/index_info',
        });

        if (response.data.message && response.data.message.includes("index")) {
            dispatch({
                type: FETCH_CUSTOM_INDEX_INFO_FAILURE,
                payload: { indexExists: false, error: response.data.message },
            });
        } else {
            dispatch({
                type: FETCH_CUSTOM_INDEX_INFO_SUCCESS,
                payload: response.data,
            });
        }
    } catch (error: any) {
        dispatch({
            type: FETCH_CUSTOM_INDEX_INFO_FAILURE,
            payload: { indexExists: false, error: error.message },
        });
    }
};

// Fetch Custom AI Blob Documents
export const getCustomBlobDocuments = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: FETCH_CUSTOM_BLOB_DOCUMENTS_REQUEST });
    try {
        const response = await apis({
            method: 'GET',
            url: 'ai/custom_agent/list_files',
        });

        if (response.data.message && response.data.message.includes("ContainerNotFound")) {
            dispatch({
                type: FETCH_CUSTOM_BLOB_DOCUMENTS_SUCCESS,
                payload: [],
            });
        } else {
            dispatch({
                type: FETCH_CUSTOM_BLOB_DOCUMENTS_SUCCESS,
                payload: response.data.documents,
            });
        }
    } catch (error: any) {
        dispatch({
            type: FETCH_CUSTOM_BLOB_DOCUMENTS_FAILURE,
            payload: error.message,
        });
    }
};

// Re-run Custom AI Index
export const reRunCustomIndex = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: RERUN_CUSTOM_INDEX_REQUEST });
    try {
        const response = await apis({
            method: 'GET',
            url: 'customai/re-run-index',
        });
        dispatch({
            type: RERUN_CUSTOM_INDEX_SUCCESS,
            payload: response.data
        });
    } catch (error: any) {
        dispatch({
            type: RERUN_CUSTOM_INDEX_FAILURE,
            payload: error
        });
    }
};

// Delete Custom AI Blob Document
export const deleteCustomBlobDocument = (fileName: string) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
        const response = await apis({
            method: 'POST',
            url: 'cai/custom_agent/delete_file',
            data: {
                FileName: fileName
            }
        });
        dispatch({
            type: DELETE_CUSTOM_BLOB_DOCUMENT_SUCCESS,
            payload: response.data
        });
    } catch (error: any) {
        dispatch({
            type: DELETE_CUSTOM_BLOB_DOCUMENT_FAILURE,
            payload: error
        });
    }
};

// Polling Custom AI Index Status
export const startPollingCustomIndexStatus = () => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch({ type: POLL_CUSTOM_INDEX_STATUS_START });
    const pollInterval = setInterval(async () => {
        try {
            const response = await apis({
                method: 'POST',
                url: 'ai/company_agent/index_status',
                data: {
                    UserType: getUserType(),
                }
            });

            const { status } = response.data.index_status.lastResult;

            if (status === "success") {
                clearInterval(pollInterval);
                dispatch({ type: POLL_CUSTOM_INDEX_STATUS_STOP });
                dispatch({
                    type: FETCH_CUSTOM_INDEX_INFO_SUCCESS,
                    payload: response.data,
                });
            } else {
                let statusMessage = "Building Custom AI...";
                switch (status) {
                    case 'running':
                        statusMessage = "Building Custom AI...";
                        break;
                    case 'pending':
                        statusMessage = "Preparing Custom AI...";
                        break;
                    default:
                        statusMessage = "Building Custom AI...";
                        break;
                }

                dispatch({
                    type: UPDATE_CUSTOM_INDEX_STATUS_MESSAGE,
                    payload: statusMessage,
                });
                dispatch({
                    type: FETCH_CUSTOM_INDEX_INFO_SUCCESS,
                    payload: response.data,
                });
            }
        } catch (error: any) {
            clearInterval(pollInterval);
            dispatch({ type: POLL_CUSTOM_INDEX_STATUS_STOP });
            dispatch({
                type: FETCH_CUSTOM_INDEX_INFO_FAILURE,
                payload: { indexExists: false, error: error.message },
            });
        }
    }, 5000); // Poll every 5 seconds
};
