export const getVoiceID = (voiceName: string, voiceList: any[]) => {
    const voice = voiceList.find(v => v.DisplayName === voiceName);
    return voice ? voice.ID : 'en-US-JennyNeural'; 
  };

  export const formatCamelCase = (text: string): string => {
    if (!text) return '';
    return text
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  