import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import apis from '../../../utils/apis';
import ClipLoader from 'react-spinners/ClipLoader';
import Badge from '../../shared/TailwindComponents/Badge';
import { LawyerProfile } from '../shared/types';
import { StarIcon } from '@heroicons/react/24/solid';
import ProposeCaseModal from '../../shared/TailwindComponents/ProposeCaseModal';
import { useBreadcrumbs } from '../../../contexts/BreadcrumbsContext';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getLawyerProfile } from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';

interface RouteParams {
  lawyerId: string;
}

const LawyerProfileComponent: React.FC = () => {
  const { lawyerId } = useParams<RouteParams>();
  const location = useLocation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const dispatch = useAppDispatch();

  const [lawyerProfile, setLawyerProfile] = useState<LawyerProfile | null>(
    (location.state as any)?.lawyer || null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false)

  const error = useSelector((state: RootState) => state.marketplaceReceiver.error);

  const fetchDetails = useCallback(async () => {
    const lawyerProfile = await dispatch(getLawyerProfile(lawyerId));
    setLawyerProfile(lawyerProfile)
    if (lawyerProfile) {
      setLawyerProfile(lawyerProfile)
    }
    setLoading(false)
},[dispatch, lawyerId])

  useEffect( () => {
    setLoading(true)
    fetchDetails();
  }, [dispatch, lawyerId]);


  useEffect(() => {
    if (lawyerProfile && lawyerProfile.User) {
      const fullName = `${lawyerProfile.User.FirstName} ${lawyerProfile.User.LastName}`;
      setBreadcrumbs([
        { name: 'Marketplace', href: '/marketplace', current: false },
        { name: fullName, href: `/marketplace/lawyer/${lawyerId}`, current: true },
      ]);
    }
    return () => {
      setBreadcrumbs([]);
    };
  }, [lawyerProfile, lawyerId, setBreadcrumbs]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading || !lawyerProfile || !lawyerProfile.User) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  const {
    Bio,
    Specializations,
    HourlyRate,
    TermsOfServiceURL,
    CompanyRole,
    RatingsAverage,
    RatingsCount,
    ExperienceYears,
    Organization,
    User,
    LanguagesSpoken,
    Education,
    Awards,
    Certifications,
    Publications,
    ProfessionalMemberships,
    Portfolio
  } = lawyerProfile;

  const fullName = `${User.FirstName} ${User.LastName}`;

  return (
    <div className="bg-white dark:bg-gray-800 mx-4 rounded shadow-md dark:shadow-dark-md">
      <div className="mx-auto px-4 py-8 sm:px-6 sm:py-8 h-full lg:max-w-7xl lg:px-8">
        {/* Profile Header */}
        <div className="lg:grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-16">
          {/* Lawyer Details */}
          <div className="lg:col-span-4">
            <div className="flex items-center mb-6">
              {User.ProfilePicture ? (
                <img
                  src={User.ProfilePicture}
                  alt={fullName}
                  className="w-24 h-24 rounded-full object-cover mr-4"
                />
              ) : (
                <div className="w-24 h-24 rounded-full bg-secondary-900 flex items-center justify-center text-3xl text-sky-300 mr-4">
                  {User.FirstName[0]}
                  {User.LastName[0]}
                </div>
              )}
              <div>
                <h1 className="text-3xl font-bold">{fullName}</h1>
                <div className="flex items-center mt-2">
                  <div className="flex items-center mr-2">
                    <StarIcon className="text-yellow-500 mr-1 h-5 w-5" />
                    <span className="text-sm">
                      {RatingsAverage !== undefined && RatingsAverage !== null
                        ? RatingsAverage.toFixed(1)
                        : 'N/A'}{' '}
                      ({RatingsCount || 0} reviews)
                    </span>
                  </div>
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    {ExperienceYears
                      ? `${ExperienceYears} years experience`
                      : 'Experience not specified'}
                  </span>
                </div>
                {/* Affiliation Badge */}
                <div className="mt-2">
                  {CompanyRole ? (
                    <Badge color="green">Law Firm Affiliated</Badge>
                  ) : (
                    <Badge color="blue">Independent Lawyer</Badge>
                  )}
                </div>
              </div>
            </div>

            {/* Bio */}
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">About</h2>
              <p className="text-base text-gray-700 dark:text-gray-300">{Bio || 'No bio available'}</p>
            </div>

            {/* Specializations */}
            {Specializations && Specializations.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Specializations</h2>
                <div className="flex flex-wrap">
                  {Specializations.map((spec, index) => (
                    <Badge key={index} color="blue" className="mr-2 mb-2">
                      {spec}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

                        {/* Languages Spoken */}
                        {LanguagesSpoken && LanguagesSpoken.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Languages Spoken</h2>
                <div className="flex flex-wrap">
                  {LanguagesSpoken.map((language, index) => (
                    <Badge key={index} color="green" className="mr-2 mb-2">
                      {language}
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            {/* Education */}
            {Education && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Education</h2>
                <p className="text-base text-gray-700 dark:text-gray-300">{Education}</p>
              </div>
            )}

            {/* Awards */}
            {Awards && Awards.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Awards</h2>
                <ul className="list-disc list-inside">
                  {Awards.map((award, index) => (
                    <li key={index} className="text-base text-gray-700 dark:text-gray-300">
                      {award}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Certifications */}
            {Certifications && Certifications.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Certifications</h2>
                <ul className="list-disc list-inside">
                  {Certifications.map((cert, index) => (
                    <li key={index} className="text-base text-gray-700 dark:text-gray-300">
                      {cert}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Publications */}
            {Publications && Publications.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Publications</h2>
                <ul className="list-disc list-inside">
                  {Publications.map((pub, index) => (
                    <li key={index} className="text-base text-gray-700 dark:text-gray-300">
                      {pub}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Professional Memberships */}
            {ProfessionalMemberships && ProfessionalMemberships.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Professional Memberships</h2>
                <ul className="list-disc list-inside">
                  {ProfessionalMemberships.map((membership, index) => (
                    <li key={index} className="text-base text-gray-700 dark:text-gray-300">
                      {membership}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Portfolio */}
            {Portfolio && Portfolio.length > 0 && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Portfolio</h2>
                <ul className="list-disc list-inside">
                  {Portfolio.map((item, index) => (
                    <li key={index} className="text-base text-gray-700 dark:text-gray-300">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Terms of Service */}
            {TermsOfServiceURL && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Terms of Service</h2>
                <a
                  href={TermsOfServiceURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600"
                >
                  View Terms of Service
                </a>
              </div>
            )}

            {/* Organization Details */}
            {Organization && (
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Organization</h2>
                <div className="flex items-center">
                  {Organization.Logo && (
                    <img
                      src={Organization.Logo}
                      alt={Organization.OrganizationName}
                      className="w-12 h-12 mr-4"
                    />
                  )}
                  <div>
                    <h3 className="text-lg font-bold">{Organization.OrganizationName}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300">{Organization.Description}</p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Action Section */}
          <div className="mt-8 lg:mt-0 lg:col-span-3">
            <div className="space-y-6">
              {/* Hourly Rate */}
              <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Hourly Rate</h2>
                <p className="text-2xl font-bold text-gray-900 dark:text-white">
                  {HourlyRate !== null && HourlyRate !== undefined ? `$${HourlyRate}` : 'N/A'}
                </p>
              </div>

                <ProposeCaseModal
                  lawyerId={lawyerId}
                  onClose={() => setIsModalOpen(false)}
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawyerProfileComponent;
