import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import {
  getTimeEntriesApi,
  pauseTimerApi,
  resumeTimerApi,
  startTimerApi,
  stopTimerApi,
} from '../../store/actions/TimeManagementActions';
import { TimeEntry } from '../../store/reducer/TimeManagementReducer';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { showModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import { fetchAllTasks } from '../../store/actions/TasksActions';
import { fetchAllExternalCases } from '../../store/actions/ClientAction';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { completeTask as completeTaskFunction } from '../../store/actions/TasksActions'

interface TimerContextType {
  time: number;
  isRunning: boolean;
  isPaused: boolean;
  selectedItems: any[]; // Changed from selectedItem to selectedItems array
  activeList: 'tasks' | 'cases';
  tasks: any[];
  cases: any[];
  timeEntries: TimeEntry[];
  startTimer: () => void;
  stopTimer: (description: string, completeTask?: boolean) => void;
  pauseTimer: () => void;
  resumeTimer: () => void;
  setActiveList: (list: 'tasks' | 'cases') => void;
  handleItemClick: (item: any) => void;
  resetState: () => void;
}

const TimerContext = createContext<TimerContextType | undefined>(undefined);

export const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
};

export const TimerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]); // Changed to array
  const [activeList, setActiveList] = useState<'tasks' | 'cases'>('tasks');
  const tasks = useSelector((state: RootState) => state.tasks.tasks).filter(
    (task: any) => task.Status === 'Active'
  );
  const cases = useSelector((state: RootState) => state.casesM.cases);
  const timeEntries = useSelector((state: RootState) => state.time.timeEntries);
  const userType = getUserType();
  const [startTime, setStartTime] = useState<number | null>(null);
  const { t } = useTranslation();
  const userId = getUserID();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);
  const [activeEntryId, setActiveEntryId] = useState<number | null>(null);

  const saveTimerState = useCallback(() => {
    localStorage.setItem(
      'timerState',
      JSON.stringify({
        startTime,
        time,
        isRunning,
        isPaused,
        selectedItems,
        activeList,
        activeEntryId,
      })
    );
  }, [startTime, time, isRunning, isPaused, selectedItems, activeList, activeEntryId]);  

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userInfo.UserUID) {
      dispatch(fetchAllTasks());
      dispatch(fetchCases());
    }
  }, [dispatch, userInfo]);


  useEffect(() => {
    loadSavedState();
  }, [dispatch]);
  

  useEffect(() => {
    if (selectedItems.length > 0 && timeEntries.length > 0) {
      const activeEntry: any = timeEntries.find((entry: any) => entry.status === 'Active' && entry.task_id === selectedItems[0].TaskID);
      
      if (activeEntry) {
        const startTime = new Date(activeEntry.start_time);
        const now = new Date();
  
        setTime((prevTime) => {
          if (prevTime === 0 || selectedItems.length > 0) {
            if (activeEntry.duration > 0) {
              if (activeEntry.is_paused) {
                setIsPaused(true);
                return parseFloat(activeEntry.duration) * 3600;
              }else{
                setIsPaused(false);
                const elapsedSeconds = Math.floor((now.getTime() - startTime.getTime()) / 1000);
                return elapsedSeconds;
              }
            } else if (!activeEntry.is_paused) {
              const elapsedSeconds = Math.floor((now.getTime() - startTime.getTime()) / 1000);
              return elapsedSeconds;
            } else {
              return 0;
            }
          }
          return prevTime; 
        });
      }
    }
  
    if (isRunning || isPaused) {
      saveTimerState();
    }
  }, [timeEntries, isRunning, isPaused, saveTimerState, selectedItems]);

  

  useEffect(() => {
    if (isRunning && !isPaused) {
      const id = setInterval(() => setTime((prevTime) => prevTime + 1), 1000);
      setIntervalId(id);
    } else if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isRunning, isPaused]);

  const startTimer = async () => {
    if (selectedItems.length === 0) return;
  
    const item = selectedItems[0]; 
    const case_id = activeList === 'cases' ? item.CaseID : null;
    const task_id = activeList === 'tasks' ? item.TaskID : null;
  
    const timer_info: any = await dispatch(startTimerApi(case_id, task_id));
  
    if (timer_info && timer_info.entry_id) {
      setActiveEntryId(timer_info.entry_id);
      setIsRunning(true);
      setIsPaused(false);
      setTime(0);
      setStartTime(Date.now());
      saveTimerState();
    }
  };

  const loadSavedState = async () => {
    const savedTimerState = localStorage.getItem('timerState');
    if (savedTimerState) {
      const {
        startTime: savedStartTime,
        isRunning,
        isPaused,
        selectedItems,
        activeList,
        activeEntryId,
      } = JSON.parse(savedTimerState);
      setStartTime(savedStartTime);
      setSelectedItems(selectedItems);
      setIsRunning(isRunning);
      setIsPaused(isPaused);
      setActiveList(activeList);
      setActiveEntryId(activeEntryId);
      setTime(isRunning ? Math.floor((Date.now() - savedStartTime) / 1000) : 0);
    }
  };

  // Stop the timer
  const stopTimer = async (description: string, completeTask?: boolean) => {
    if (!activeEntryId) return;

    localStorage.removeItem('timerState');

    dispatch(
      showModal({
        type: 'timeStop',
        message: `${t('Do you want to stop the timer?')}`,
        onConfirm: async (data: string | File, completeTask?: boolean) => {
          const description = typeof data === 'string' ? data : '';
          for (const item of selectedItems) {
            const case_id = activeList === 'cases' ? item.CaseID : null;
            const task_id = activeList === 'tasks' ? item.TaskID : null;
            const activeTimer: any = timeEntries && timeEntries.length > 0 && timeEntries.find((item: any) => {
              if (item.status === 'Active') {
                return item
              }
              return null
            })

            await dispatch(stopTimerApi(activeEntryId, description));
            resetState();
            if (completeTask && task_id) {
              await dispatch(completeTaskFunction(task_id))
            }

            await dispatch(getTimeEntriesApi(case_id, task_id));
          }
          resetState();
        },
        showModal: true,
        taskId: selectedItems.map(item => item.TaskID || item.CaseID), 
      })
    );
  };

  const pauseTimer = async () => {
    if (!activeEntryId) return;

    for (const item of selectedItems) {

      const case_id = activeList === 'cases' ? item.CaseID : null;
      const task_id = activeList === 'tasks' ? item.TaskID : null;

      const activeTimer: any = timeEntries && timeEntries.length > 0 && timeEntries.find((item: any) => {
        if (!item.is_paused && item.status === 'Active') {
          return item
        }
        return null
      })

      console.log(activeTimer, "activeTimer");

      await dispatch(pauseTimerApi(activeEntryId));
      await dispatch(getTimeEntriesApi(case_id, task_id));
    }
    setIsPaused(true);
    setIsRunning(false);
    saveTimerState();
  };

  const resumeTimer = async () => {
    if (!activeEntryId) return;

    for (const item of selectedItems) {
      const case_id = activeList === 'cases' ? item.CaseID : null;
      const task_id = activeList === 'tasks' ? item.TaskID : null;
      const activeTimer: any = timeEntries && timeEntries.length > 0 && timeEntries.find((item: any) => {
        if (item.is_paused && item.status === 'Active') {
          return item
        }
        return null
      })

      await dispatch(resumeTimerApi(activeEntryId));
      await dispatch(getTimeEntriesApi(case_id, task_id));
    }
    setIsPaused(false);
    setIsRunning(true);
    saveTimerState();
  };

  const resetState = () => {
    setIsRunning(false);
    setIsPaused(false);
    setTime(0);
    setSelectedItems([]);
    setActiveEntryId(null);
    localStorage.removeItem('timerState');
  };

  const handleItemClick = async (item: any) => {
    localStorage.removeItem(
      'timerState')
    const itemID = item.TaskID || item.CaseID;
    const isSelected = selectedItems.some((selectedItem) => {
      const selectedItemID = selectedItem.TaskID || selectedItem.CaseID;
      return selectedItemID === itemID;
    });
    setSelectedItems(isSelected ? [] : [item]);

    // Fetch time entries for the item
    const case_id = activeList === 'cases' ? item.CaseID : null;
    const task_id = activeList === 'tasks' ? item.TaskID : null;

    await dispatch(getTimeEntriesApi(case_id, task_id));
  };

  return (
    <TimerContext.Provider
      value={{
        time,
        isRunning,
        isPaused,
        selectedItems,
        activeList,
        tasks,
        cases,
        timeEntries,
        startTimer,
        stopTimer,
        pauseTimer,
        resumeTimer,
        setActiveList,
        handleItemClick,
        resetState,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};
