import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import ToggleButton from './ToggleButton';
import './MessageCenter.css';
import { ClipLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { Lawyer } from '../Dashboard/AdminFirm/interface/IFirmLawyer';
import { getAllLawyers } from '../../store/actions/organization/LawfirmActions';
import { getUserID, getUserType } from '../../store/actions/ChatAction';
import SocketServices from '../../utils/SocketServices';
import { fetchCHatList, fetchMessageList } from '../../store/actions/DataAction';
import { useTranslation } from 'react-i18next';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';

const MessageCenter: React.FC = () => {
  const [selectedChatId, setSelectedChatId] = useState<number | null | string>(null);
  const [isEmployeeView, setIsEmployeeView] = useState(true);
  const [openListView, setOpenListView] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null)
  const [newChat, setNewChat] = useState(false)
  const dispatch = useAppDispatch();
  const date: Date = useMemo(() => new Date(), []);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [isLoading, setIsLoading] = useState(true)
  const chatList = useSelector((state: RootState) => state.lists.chatList)
  const MessageList = useSelector((state: RootState) => state.lists.MessageList)
  const [messages, setMessages] = useState<any[]>(MessageList)
  const selectedChat: any = chatList.find((chat: any) => chat.ChatID === selectedChatId);
  const { t } = useTranslation()
  const userType = getUserType()
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const userID = getUserID();
  const [isChatLoading, setIsChatLoading] = useState(false)
  const [unreadCounts, setUnreadCounts] = useState({});
  const [lastMessages, setLastMessages] = useState({});

  useEffect(() => {
    const storedUnreadCounts = localStorage.getItem('unreadCounts');
    if (storedUnreadCounts) {
      setUnreadCounts(JSON.parse(storedUnreadCounts));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('unreadCounts', JSON.stringify(unreadCounts));
  }, [unreadCounts]);


  useEffect(() => {
    if (userType === 'LawFirmEmployee') {
      dispatch(fetchAllEmployees());
    } else {
      dispatch(fetchAllEmployees());
    }
    dispatch(fetchCHatList())
  }, [dispatch, userID, userType]);

  const recievePersonalMessage = useCallback(async (data: any) => {
    const {
      message,
      img_urls,
      chatId
    } = data;

    // setMessages(messages => [...messages, { id: (messages.length + 1), content: message, timestamp: date, sender: "", attachments: img_urls }]);

    if (selectedChatId === chatId) {
      await dispatch(fetchCHatList())
      SocketServices.emit("reset_unread_count", { "ChatID": chatId, "UserID": userInfo.UserUID })
      setMessages(messages => [...messages, { id: (messages.length + 1), content: message, timestamp: date, sender: "", attachments: img_urls }]);
    }

    if (selectedChatId !== chatId) {
      // await dispatch(fetchCHatList())
      console.log(selectedChatId, chatId);

      setUnreadCounts((prevCounts: any[]) => ({
        ...prevCounts,
        [chatId]: (prevCounts[chatId] || 0) + 1
      }));
    }
    setLastMessages(prevLastMessages => ({
      ...prevLastMessages,
      [chatId]: message
    }));

  }, [date, selectedChatId,userInfo.UserUID])

  const emitUserJoin = useCallback(() => {
    SocketServices.emit("user_join", { 'username': userInfo.UserUID, 'caseid': "" });
  }, [userID]);

  const newChatStarted = useCallback((data: any) => {
    const { chat_id } = data
    if (chat_id) {
      dispatch(fetchCHatList())
      setSelectedChatId(chat_id)
    }
  }, [dispatch])

  // const personal_chat_list = useCallback(() => {
  //   SocketServices.emit("personal_chat_list", {})
  // },[])


  const recieve_personal_chat_list = useCallback((data: any) => {
    const { messages } = data
    console.log(messages, "messages");

    if (messages) {
      setIsChatLoading(false)
      setMessages(messages)
    }

  }, [])

  useEffect(() => {
    emitUserJoin()
    SocketServices.on('recieve_personal_message', recievePersonalMessage)
    SocketServices.on("new_chat_started", newChatStarted)
    SocketServices.on("personal_chat_list", recieve_personal_chat_list)
    return () => {
      SocketServices.removeListener('recieve_personal_message')
      SocketServices.removeListener("new_chat_started")
      SocketServices.emit("personal_chat_list")
    };
  }, [userID, date, recievePersonalMessage, emitUserJoin, newChatStarted, recieve_personal_chat_list])

  const handleChatSelect = async (id: string) => {
    console.log(id, "chat_id", userInfo.UserUID, id);
    setUnreadCounts((prevCounts: any[]) => ({
      ...prevCounts,
      [id]: 0
    }));

    setIsChatLoading(true)
    setSelectedChatId(id);
    setMessages([])
    if (id) {
      SocketServices.emit("reset_unread_count", { "ChatID": id, "UserID": userInfo.UserUID })
      SocketServices.emit("personal_chat_list", { "ChatID": id, "UserID": userInfo.UserUID })
      await dispatch(fetchCHatList())
    }

  };

  const readFileAsArrayBuffer = (file: File): Promise<{ fileName: string; fileType: string; fileData: ArrayBuffer | string }> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        if (event.target?.result) {
          resolve({
            fileName: file.name,
            fileType: file.type,
            fileData: event.target.result,
          });
        } else {
          reject(new Error("Failed to read file"));
        }
      };

      fileReader.onerror = () => {
        reject(new Error("Error reading file"));
      };

      fileReader.readAsArrayBuffer(file);
    });
  }


  const handleSendMessage = async (message: string, attachments: any[]) => {
    let newAttachments: any[] = []
    if (attachments.length > 0) {
      newAttachments = await Promise.all(
        attachments.map(file => readFileAsArrayBuffer(file))
      );

    }
    setMessages(messages => [...messages, { id: (messages.length + 1), content: message, timestamp: date, sender: userInfo.UserUID, attachments }]);
    SocketServices.emit('send_personal_message', { "ChatID": selectedChatId, SenderID: userInfo.UserUID, RecieverID:selectedEmployee ? selectedEmployee?.User?.UserUID : selectedChat.SenderID === userInfo.UserUID ? selectedChat.RecieverID : selectedChat.SenderID, Message: message, GroupID: "", attachments: newAttachments })
    await dispatch(fetchCHatList())
  };



  const handleEmplyeeList = () => {
    setOpenListView(true)
  }

  const handleSelectEmployee = (employee: Lawyer) => {
    console.log(chatList, employee);

    let existing_chat = chatList && chatList.length > 0 && chatList.find((chat: any) => {
      if (chat.userData.Email === employee.Email) {
        return chat;
      }
      return null
    })

    if (existing_chat && Object.keys(existing_chat).length > 0) {
      handleChatSelect(existing_chat.chatID)
      setSelectedEmployee(existing_chat)
      setOpenListView(false)

    } else {
      setSelectedChatId(null)
      setSelectedEmployee(employee)
      setOpenListView(false)
      setNewChat(true)
      setMessages([])
    }

  } 
  // console.log(selectedChat)
  return (
    <div className="flex h-full">
      {/* Column 1: Optional Switch or Options */}
      {/* <div className="w-1/5 bg-gray-100 dark:bg-gray-800 p-4">
        {/* Optional Switch or Options 
        <ToggleButton isEmployeeView={isEmployeeView} setIsEmployeeView={setIsEmployeeView} />
      </div> */}

      {/* Column 2: Chat List */}
      <div className="w-1/3 border-r border-gray-300 dark:border-gray-700">
        <ChatList
          chatList={chatList}
          selectedChat={selectedChatId}
          onChatSelect={handleChatSelect}
          unreadMessages={unreadCounts}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleSelectEmployee={handleSelectEmployee}
        />
      </div>

      {/* Column 3: Chat Window */}
      <div className="flex-grow">
        {selectedChat && selectedChatId ? (
          <ChatWindow
            chatId={selectedChatId}
            messages={messages}
            selectedChat={selectedChat}
            onSendMessage={handleSendMessage}
            isLoading={isChatLoading}
          />
        ) : newChat ? (
          <ChatWindow
            chatId={''}
            messages={messages}
            selectedChat={selectedChat? selectedChat : selectedEmployee}
            onSendMessage={handleSendMessage}
            isLoading={isChatLoading}
          />
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500 dark:text-gray-400">{t('Select a chat to start messaging')}</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default MessageCenter;
