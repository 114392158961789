import React, { useRef, useState, useEffect } from 'react';
import { FileProps, FolderProps } from './types';
import { useTranslation } from 'react-i18next';
import Badge from '../shared/TailwindComponents/Badge';
import Button from '../shared/TailwindComponents/Button';
import { useAppDispatch } from '../../store';
import { showAddFileSidePanel } from '../../store/actions/sidePanelActions';
import { usePermission } from '../../hooks/userPermissions';

interface RecommendedFilesProps {
  documents: FileProps[];
  onSelectFile: (file: FileProps) => void;
  folders?: FolderProps[];
}

function getFileCategoryIcon(fileType: string): string {
  switch (fileType) {
    case 'Contract':
      return 'fas fa-file-contract';
    case 'Legal Document':
      return 'fas fa-gavel';
    case 'General':
      return 'fas fa-file-alt';
    case 'Template':
      return 'fas fa-file-invoice';
    default:
      return 'fas fa-file';
  }
}

const RecommendedFiles: React.FC<RecommendedFilesProps> = ({ documents, onSelectFile, folders }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const { hasAccess, isReadOnly, loading } = usePermission('documents');

  const updateScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    updateScrollButtons();
    window.addEventListener('resize', updateScrollButtons);
    return () => {
      window.removeEventListener('resize', updateScrollButtons);
    };
  }, [documents]);

  const scrollLeftFunc = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -scrollContainerRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollRightFunc = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: scrollContainerRef.current.clientWidth,
        behavior: 'smooth',
      });
    }
  };

  // Update scroll buttons when scrolling
  const handleScroll = () => {
    updateScrollButtons();
  };

  const handleAddFile = () => {
    if (folders) {
    dispatch(showAddFileSidePanel(folders));
    }
  };

  return (
    <div className="relative px-4 py-2">
      <h2 className="text-lg font-bold mb-4">{t('Recommended Files')}</h2>
      {documents.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-8 bg-gray-50 dark:bg-gray-800 rounded-lg">
          <p className="text-gray-600 dark:text-gray-400 mb-4">{t('No recommended files.')}</p>
          <Button
            disabled={isReadOnly}
           variant="primary" onClick={handleAddFile}>
            {t('Add New File')}
          </Button>
        </div>
      ) : (
        <div className="relative">
          {canScrollLeft && (
            <button
              className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white dark:bg-gray-800 p-2 rounded-full shadow-md z-10"
              onClick={scrollLeftFunc}
              aria-label="Scroll Left"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
          )}
          {canScrollRight && (
            <button
              className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white dark:bg-gray-800 p-2 rounded-full shadow-md z-10"
              onClick={scrollRightFunc}
              aria-label="Scroll Right"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          )}
          <div
            className="flex space-x-4 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
            ref={scrollContainerRef}
            onScroll={handleScroll}
            style={{
              scrollSnapType: 'x mandatory',
            }}
          >
            {documents.map((doc) => (
              <div
                key={doc.FileID}
                className="flex-none w-64 rounded-lg bg-white dark:bg-gray-800 p-4 shadow hover:shadow-lg transition cursor-pointer scroll-snap-align-start"
              >
                <div className="flex items-center">
                  <div className="rounded-md bg-sky-300 p-3">
                    <i className={`${getFileCategoryIcon(doc.FileCategory)} text-white text-xl`}></i>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-md font-bold">{doc.FileName}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {t(doc.FileCategory)}
                    </p>
                  </div>
                </div>
                <div className="mt-2 flex flex-row justify-between items-end">
                  <div>
                    <Badge color="green">{doc.FileCategory}</Badge>
                  </div>
                  <div>
                    <Button
                      variant="neutral"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent triggering the parent click
                        onSelectFile(doc);
                      }}
                    >
                      {t('Open')}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecommendedFiles;
