// src/hooks/usePermission.ts

import { usePermissions } from "../contexts/PermissionsContext";

export const usePermission = (module: string) => {
  const { accessRights, readOnlyRights, loading }: any = usePermissions();

  const hasAccess = accessRights[`${module}_access`];
  const isReadOnly = readOnlyRights[`${module}_read_only`];

  return { hasAccess, isReadOnly, loading };
};
