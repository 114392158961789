import React, { useEffect, useState, DragEvent, useRef } from 'react';
import { RootState, useAppDispatch } from '../../store';
import { fetchFileCategorys, fetchStructure } from '../../store/actions/FolderAction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { hideModal } from '../../store/actions/modalActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createNewDocument } from '../../store/actions/DocumentsAction';
import EmailTagsInput from './EmailTagInput';
import dayjs, { Dayjs } from 'dayjs';
import { hideSidePanel } from '../../store/actions/sidePanelActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { getUserType } from '../../store/actions/ChatAction';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import Input from '../shared/TailwindComponents/Input';
import Button from '../shared/TailwindComponents/Button';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Textarea from '../shared/TailwindComponents/Textarea';
import { FolderProps } from './types';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';

export interface NewFileData {
  folderId: string;
  fileName: string;
  fileType: 'Contract' | 'Legal Document' | 'General' | 'Template';
  uploadedFile: File | undefined;
  additionalFields: {
    PartiesInvolved?: string[];
    StartDate?: string;
    EndDate?: string;
    Approvers?: string[];
    Signatories?: string[];
    SharedWith?: string[];
    LegalType?: string;
    CaseID?: string;
    Expiry?: string;
  };
}

interface NewFileProps {
  folders: FolderProps[] | undefined;
  onSave: (data: NewFileData) => void;
  currentUserId: string;
  preSelectedFolderId?: string;
}

const NewFile: React.FC<NewFileProps> = ({ folders, onSave, currentUserId, preSelectedFolderId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const fileTypes = useSelector((state: RootState) => state.folders.fileTypes);
  const structure = useSelector((state: RootState) => state.folders.structure);
  const [selectedFileCategory, setSelectedFileCategory] = useState<'Contract' | 'Legal Document' | 'General' | 'Template'>('General');
  const caseList = useSelector((root: RootState) => root.casesM.cases);
  const [file, setFile] = useState<File | null>(null);
  const userType = getUserType();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(fetchFileCategorys());
    dispatch(fetchCases())
  }, [dispatch]);
  
  useEffect(() => {
    if (preSelectedFolderId && folders) {
      formik.setFieldValue('FolderID', preSelectedFolderId);
      const selectedFolder = folders.find((folder) => folder.FolderID.toString() === preSelectedFolderId);
      if (selectedFolder) {
        formik.setFieldValue('FolderName', selectedFolder.FolderName);
      }
      setIsFolderSelectionDisabled(true);
    }
  }, [preSelectedFolderId, folders]);
  

  const formattedCaseOptions = caseList && caseList
    .filter((caseItem: any) => caseItem.Status === "Ongoing")
    .map((caseItem: any) => ({
      label: `${caseItem.CaseType} - ${caseItem.MaxBudget}`,
      value: caseItem.CaseID
    }));

  const getValidationSchema = () => {
    let schemaFields = {
      FolderID: Yup.string().required(t('Please select a folder')),
      Title: Yup.string().required(t('File title/name required')),
    };

    return Yup.object().shape(schemaFields);
  };
  const validationSchema = getValidationSchema();

  const formik = useFormik({
    initialValues: {
      FolderID: '',
      FolderName: '',
      Title: '',
      Content: '',
      FileCategory: 'General' as 'Contract' | 'Legal Document' | 'General' | 'Template',
      PartiesInvolved: [],
      StartDate: '',
      EndDate: '',
      Approvers: [],
      Signatories: [],
      SharedWith: [],
      LegalType: '',
      CaseID: '',
      Expiry: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log('Form submitted:', values);
      const additionalFields = {
        PartiesInvolved: sanitizeEmails(values.PartiesInvolved),
        StartDate: values.StartDate,
        EndDate: values.EndDate,
        Approvers: sanitizeEmails(values.Approvers || []),
        Signatories: sanitizeEmails(values.Signatories || []),
        SharedWith: sanitizeEmails(values.SharedWith || []),
        LegalType: values.LegalType,
        CaseID: values.CaseID,
        Expiry: values.Expiry || '',
      };
      console.log('Submitting file x2');
  
      const newFileData: NewFileData = {
        folderId: preSelectedFolderId ? preSelectedFolderId : values.FolderID,
        fileName: values.Title,
        fileType: values.FileCategory,
        uploadedFile: file || undefined,
        additionalFields,
      };
      console.log('Submitting file x3: ', newFileData);
      await handleConfirmCreateFile(newFileData);
      formik.resetForm(); 
      setFile(null);
      dispatch(hideModal());
      dispatch(hideSidePanel());
    }
  });

  const buildFolderOptions = (folders: FolderProps[]): DropdownOption[] => {
    return folders.map(folder => ({
      value: folder.FolderID.toString(), // Changed from 'id' to 'value'
      label: folder.FolderName,
      options: folder.SubFolders && folder.SubFolders.length > 0 ? buildFolderOptions(folder.SubFolders) : undefined
    }));
  };

  const folderOptions: any[] = buildFolderOptions(structure);

  const handleFolderChange = (selectedOption: DropdownOption) => {
    formik.setFieldValue('FolderID', selectedOption.value);
    formik.setFieldValue('FolderName', selectedOption.label);
  };
  

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      setFile(event.currentTarget.files[0]);
      event.currentTarget.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setFile(event.dataTransfer.files[0]);
    }
  };
  
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };  

  const handleFileCategorySelect = (fileType: 'Contract' | 'Legal Document' | 'General' | 'Template') => {
    setSelectedFileCategory(fileType);
    formik.setFieldValue('FileCategory', fileType);
  };

  const sanitizeEmails = (emails: string[]) => {
    return emails.map(email => email.replace(/^['"]|['"]+$/g, '').trim());
  };

  const [isFolderSelectionDisabled, setIsFolderSelectionDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (preSelectedFolderId && folders) {
      formik.setFieldValue('FolderID', folders[0].FolderID.toString());
      formik.setFieldValue('FolderName', folders[0].FolderName);
      setIsFolderSelectionDisabled(true);
    }
  }, [preSelectedFolderId]);  

  const handleConfirmCreateFile = async (newFileData: NewFileData) => {
    const { folderId, fileName, fileType, uploadedFile, additionalFields } = newFileData;
  
    if (!userType) {
      dispatch(showFeedbackModal({
        modalType: 'error',
        message: t('Something went wrong, please try again.'),
        showModal: true,
        duration: 3000
      }))
      return;
    }
  
    let additionalFieldsData: { [key: string]: string | string[] } = {};
    switch (fileType) {
      case 'Contract':
        additionalFieldsData = {
          FileName: fileName,
          PartiesInvolved: additionalFields.PartiesInvolved || [],
          ContractStart: additionalFields.StartDate || '',
          Approvers: additionalFields.Approvers || [],
          Signatories: additionalFields.Signatories || [],
          Expiry: additionalFields.Expiry || '',
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      case 'Legal Document':
        additionalFieldsData = {
          FileName: fileName,
          LegalType: additionalFields.LegalType || '',
          CaseID: additionalFields.CaseID || '',
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Signatories: additionalFields.Signatories || [],
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      case 'General':
      case 'Template':
        additionalFieldsData = {
          FileName: fileName,
          Expiry: additionalFields.Expiry || '',
          Approvers: additionalFields.Approvers || [],
          Signatories: additionalFields.Signatories || [],
          SharedWith: additionalFields.SharedWith || []
        };
        break;
      default:
        console.error('Unknown file type:', fileType);
        return;
    }
  
    try {
      const resp: any = await dispatch(createNewDocument(fileType, folderId.toString(), uploadedFile, additionalFieldsData));
      if (resp) {
        dispatch(fetchStructure());
        dispatch(showFeedbackModal({
          showModal: true,
          message: t('File created successfully'),
          modalType: 'success',
          duration: 3000
        }));
        formik.setFieldValue('', '')   
      }
    } catch (error) {
      console.error("Error creating document:", error);
      dispatch(showFeedbackModal({
        showModal: true,
        message: t('Failed to create file'),
        modalType: 'error',
        duration: 3000
      }));
    }
  };

  const fileTypeIcons = {
    Contract: 'fas fa-file-contract',
    'Legal Document': 'fas fa-gavel',
    General: 'fas fa-file-alt',
    Legal_Document: 'fas fa-gavel',
  };

  const handleClearFile = () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };  

  return (
    <div className="max-w-8xl mx-5 p-6">
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
          {t('Create New File')}
        </h2>
        {/* File Type Buttons */}
        <div className="flex space-x-2">
          {fileTypes.map((type: any) => (
            <button
              type="button"
              key={type.FileType}
              className={`flex-1 px-4 py-2 rounded-md text-sm font-medium ${
                selectedFileCategory === type.FileType
                  ? 'bg-primary-600 text-white'
                  : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200'
              }`}
              onClick={() => handleFileCategorySelect(type.FileType as 'Contract' | 'Legal Document' | 'General' | 'Template')}
            >
              <i className={`${fileTypeIcons[type.FileType as keyof typeof fileTypeIcons]} mr-2`}></i>
              <span>{t(`${type.FileTypeName}`)}</span>
            </button>
          ))}
        </div>

        {/* File Name */}
        <Input
          label={t('File Name')}
          type="text"
          id="Title"
          name="Title"
          value={formik.values.Title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={t('Enter file name')}
          error={formik.touched.Title && formik.errors.Title ? formik.errors.Title : undefined}
        />


        {/* Conditional Fields Based on File Type */}
        {formik.values.FileCategory === 'Contract' && (
          <>
            {/* Parties Involved */}
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              <div className="flex items-center">
                <span>{t('Parties Involved')}</span>
                <i
                  title={t('Please enter emails and separate by comma.')}
                  className="fas fa-exclamation-circle ml-2 text-gray-400"
                ></i>
              </div>
              <EmailTagsInput
                value={formik.values.PartiesInvolved}
                placeholder={t('Enter parties involved')}
                onChange={(tags) => formik.setFieldValue('PartiesInvolved', tags)}
              />
            </label>

            {/* Start Date and End Date */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {/* Start Date */}
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t('Start Date')}
                <CustomDateTimePicker
                  value={formik.values.StartDate ? dayjs(formik.values.StartDate) : null}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) {
                      formik.setFieldValue('StartDate', newValue.format("YYYY-MM-DD HH:mm:ss"));
                    }
                  }}
                  minDateTime={dayjs()}
                />
              </label>

              {/* End Date */}
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t('End Date')}
                <CustomDateTimePicker
                  value={formik.values.EndDate ? dayjs(formik.values.EndDate) : null}
                  onChange={(newValue: Dayjs | null) => {
                    if (newValue) {
                      formik.setFieldValue('EndDate', newValue.format("YYYY-MM-DD HH:mm:ss"));
                    }
                  }}
                  minDateTime={dayjs()}
                />
              </label>
            </div>
          </>
        )}

        {formik.values.FileCategory === 'Legal Document' && (
          <>
            {/* Legal Type */}
            <Input
              label={t('Legal Type')}
              type="text"
              value={formik.values.LegalType}
              onChange={formik.handleChange('LegalType')}
              placeholder={t('Enter legal type')} id={''}            />

            {/* Case */}
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              {t('Case')}
              <Dropdown
                  options={formattedCaseOptions}
                  onSelect={(selectedOption: any) => formik.setFieldValue("CaseID", selectedOption.value)}
                  value={formik.values.CaseID}
                  placeholder={t('Select a case')}
                />
            </label>
          </>
        )}

        {/* Deadline */}
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('Deadline')}
          <CustomDateTimePicker
            value={formik.values.Expiry ? dayjs(formik.values.Expiry) : null}
            onChange={(newValue: Dayjs | null) => {
              if (newValue) {
                formik.setFieldValue('Expiry', newValue.format("YYYY-MM-DD HH:mm:ss"));
              }
            }}
            minDateTime={dayjs()}
          />
        </label>

        {/* Assignees (for all file types) */}
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          <div className="flex items-center">
            <span>{t('Signatories')}</span>
            <i
              title={t('Please enter emails and separate by comma.')}
              className="fas fa-exclamation-circle ml-2 text-gray-400"
            ></i>
          </div>
          <EmailTagsInput
            value={formik.values.Signatories}
            placeholder={t('Enter assignees')}
            onChange={(tags) => formik.setFieldValue('Signatories', tags)}
          />
        </label>

        {/* Shared With (for all file types) */}
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          <div className="flex items-center">
            <span>{t('Shared With')}</span>
            <i
              title={t('Please enter emails and separate by comma.')}
              className="fas fa-exclamation-circle ml-2 text-gray-400"
            ></i>
          </div>
          <EmailTagsInput
            value={formik.values.SharedWith}
            placeholder={t('Enter emails to share with')}
            onChange={(tags) => formik.setFieldValue('SharedWith', tags)}
          />
        </label>

        {/* Folder Selection */}
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('Folder')}
          {preSelectedFolderId ? (
            <div className="bg-gray-100 dark:bg-gray-700 p-2 rounded-md">
              <p className="text-gray-700 dark:text-gray-200">{formik.values.FolderName}</p>
            </div>
          ) : (
            <Dropdown
            options={folderOptions}
            onSelect={handleFolderChange}
            value={formik.values.FolderID}
            placeholder={t('Select a folder')}
          />
          )}
          {formik.errors.FolderID && formik.touched.FolderID ? (
            <span className="text-red-500 text-sm">{formik.errors.FolderID}</span>
          ) : null}
        </label>

        {/* File Upload */}
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('Import File')}
          <input
            ref={fileInputRef}
            id="fileUploadInput"
            type="file"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <div
            className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md cursor-pointer dark:border-gray-600"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              fileInputRef.current?.click();
            }}
          >
            <div className="space-y-1 text-center">
            {file ? (
                <div className="flex items-center justify-between w-full">
                  <span className="text-sm text-gray-600 dark:text-gray-200 truncate">
                    {file.name}
                  </span>
                  <button
                    type="button"
                    onClick={handleClearFile}
                    className="text-red-500 ml-4"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              ) : (
                <p className="text-sm text-gray-600 dark:text-gray-200">
                  {t('Drag and drop a file here or click to upload')}
                </p>
              )}
            </div>
          </div>
        </label>

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button 
          type="submit"
          variant="primary">
            {t('Create')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewFile;
