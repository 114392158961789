import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { showModal, hideModal } from '../../../store/actions/modalActions';
import { getUserID, getUserType } from '../../../store/actions/ChatAction';

import { showFeedbackModal } from '../../../store/actions/UserFeedbackActions';
import Button from '../../shared/TailwindComponents/Button';
import Modal from '../../shared/TailwindComponents/Modal';
import VerifyButton from './StripeVerificationButton';
import { disable2FA, generateQr, verify2faQr } from '../../../store/actions/SignInAction';
import { loadStripe } from '@stripe/stripe-js';
import { generateCode } from '../../../store/actions/SecurityActions';
const stripePromise = loadStripe('pk_test_51NUAIpCKZInHFLiaoqKLdIpmsng4qX2W0ylQG7C7SvB6jAmLmUw3brzQUbMdBjHWpPTGyGJFfmbEYIYsZjGvARMu0000tqTUgw');

const SecuritySettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  // State Variables
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading2FA, setLoading2FA] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [smsLoading, setSMSLoading] = useState(false);

  // User Information
  const userId = getUserID();
  const userType = getUserType();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  // Handlers
  const handleCancel = () => {
    dispatch(hideModal());
  };

  const handleDisable2FA = () => {
    dispatch(disable2FA(userId, userType));
    dispatch(
      showFeedbackModal({
        modalType: 'success',
        message: t('Two-Factor Authentication disabled successfully.'),
        showModal: true,
        duration: 3000,
      })
    );
  };

  const handleEnable2FA = async () => {
    setLoading2FA(true);
    try {
      const response: any = await dispatch(generateQr(userId, userType, userInfo.Email));
      if (response) {
        setLoading2FA(false);
        dispatch(
          showModal({
            type: '2fa-qrcode',
            message: t('Enable Two-Factor Authentication (2FA)'),
            onConfirm: async (verificationCode: string) => {
              if (verificationCode) {
                const resp: any = await dispatch(verify2faQr(verificationCode, userId, userType));
                if (resp) {
                  setMessage(t('Two-Factor Authentication enabled successfully.'));
                  setConfirmModalOpen(true);
                  dispatch(hideModal());
                }
              } else {
                setMessage(
                  t('Please enter verification code to enable Two-Factor Authentication (2FA).')
                );
                setConfirmModalOpen(true);
              }
            },
            onCancel: handleCancel,
            showModal: true,
            inputData: response.QR,
          })
        );
      }
    } finally {
      setLoading2FA(false);
    }
  };

  const handleEmailVerification = async () => {
    setEmailLoading(true);
    try {
      const resp: any = await dispatch(generateCode('email', ''));
      
    } catch (error) {
      
    }
    setEmailLoading(false);
  };

  const handleSMSVerification = async () => {
    setSMSLoading(true);
    try {
      const resp: any = await dispatch(generateCode('sms', ''));
      if (resp) {
        dispatch(
          showFeedbackModal({
            modalType: 'success',
            message: t('Verification code sent to your mobile number.'),
            showModal: true,
            duration: 3000,
          })
        );
      }
    } catch (error) {
      dispatch(
        showFeedbackModal({
          modalType: 'error',
          message: t('Failed to send verification code.'),
          showModal: true,
          duration: 3000,
        })
      );
    }
    setSMSLoading(false);
  };

  return (
    <>
      {/* Confirmation Dialog */}
      {confirmModalOpen && (
        <Modal
          isOpen={confirmModalOpen}
          title={t('Confirmation')}
          subtitle={message}
          onConfirm={() => setConfirmModalOpen(false)}
          confirmLabel={t('OK')}
        />
      )}

      {/* Main Container */}
      <div className="divide-y divide-gray-200 dark:divide-gray-700 px-2">
        {/* Two-Factor Authentication Section */}
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
          <div className="px-4 sm:px-6 md:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
              {t('Two-Factor Authentication (2FA)')}
            </h2>
            <p className="text-xs text-gray-500 dark:text-gray-400 italic">
                {t('Strengthen your account’s security with Two-Factor Authentication.')}
                </p>
              <p className="text-sm text-gray-700 dark:text-gray-300">
                {t("Adding 2FA provides an additional layer of protection by requiring a second form of verification beyond your password.")}
                </p>
            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
              {t('This ensures that only you can access your account, even if your password is compromised.')}
            </p>
          </div>
          <div className="md:col-span-2">
            <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
              <div>
                <p className="font-medium text-gray-900 dark:text-white">
                  {t('Two-Factor Authentication')}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 italic">
                  {t('Activate 2FA to safeguard your account with secure, multi-step verification.')}
                </p>
              </div>
              {userInfo.Enable2FA === 'Yes' ? (
                <Button variant="danger" onClick={handleDisable2FA} disabled={loading2FA}>
                  {t('Disable')}
                  {loading2FA && <ClipLoader color="#fff" size={10} className="ml-2" />}
                </Button>
              ) : (
                <Button variant="primary" onClick={handleEnable2FA} disabled={loading2FA}>
                  {t('Enable')}
                  {loading2FA && <ClipLoader color="#fff" size={10} className="ml-2" />}
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Verification Options Section */}
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
          <div className="px-4 sm:px-6 md:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
              {t('Verification Options')}
            </h2>
            <p className="text-xs text-gray-500 dark:text-gray-400 italic">
                {t('Secure and validate your account through identity verification and email confirmation.')}
                </p>
              <p className="text-sm text-gray-700 dark:text-gray-300">
                {t("Verification enhances account security, ensures platform compliance, and unlocks full platform features.")}
                </p>
            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
              {t('Partnering with Stripe, we guarantee a seamless and secure identity verification process.')}
            </p>
          </div>
          <div className="md:col-span-2 space-y-4">
            {/* Identity Verification */}
            <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
              <div>
                <p className="font-medium text-gray-900 dark:text-white">
                  {t('Identity Verification')}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 italic">
                {t('Verify your identity to meet compliance standards and ensure secure platform access.')}
                </p>
              <p className="text-xs text-gray-700 dark:text-gray-300">
                {t('Partnering with Stripe, we verify your information securely and efficiently to protect your account and enable access to the marketplace.')}
                </p>
              </div>
              {
                userInfo.IsVerified ? (
                  <Button variant="primary">
                    {t('Verified')}
                  </Button>
                ) : (<VerifyButton stripePromise={stripePromise} />)
              }

            </div>

            {/* Email Verification */}
            <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
              <div>
              <p className="font-medium text-gray-900 dark:text-white">
                  {t('Email Verification')}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 italic">
                {t('Confirm your email address for secure communication and account recovery.')}
                </p>
              <p className="text-xs text-gray-700 dark:text-gray-300">
                {t('Validating your email ensures you can reset your password and receive important platform updates.')}
                </p>
              </div>
              {
                userInfo.isEmailVerified ? (
                  <Button variant="primary">
                    {t('Verified')}
                  </Button>
                ) : (
                  <Button
                  variant="primary"
                  onClick={handleEmailVerification}
                  disabled={emailLoading}
                >
                  {t('Verify')}
                  {emailLoading && <ClipLoader color="#fff" size={10} className="ml-2" />}
                </Button>)
              }
              
            </div>

            {/* SMS Verification */}
            {/* <div className="flex items-center justify-between bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
              <div>
                <p className="font-medium text-gray-900 dark:text-white">{t('SMS Verification')}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t('Link mobile number for additional security and alerts.')}
                </p>
              </div>
              <Button variant="primary" onClick={handleSMSVerification} disabled={smsLoading}>
                {t('Verify')}
                {smsLoading && <ClipLoader color="#fff" size={10} className="ml-2" />}
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecuritySettings;
