import React, { useEffect } from "react";
import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FirstPage from "./pages/FirstPage";
import DashboardPage from "./pages/DashboardPage";
import AIAssistantPage from "./pages/AIAssistantPage";
import ReportsPage from "./pages/ReportsPage";
import HelpPage from "./pages/HelpPage";
import FeedbackPage from "./pages/FeedbackPage";
import { UserContext, UserContextProps } from "../src/contexts/UserContext";
import "../node_modules/flag-icon-css/css/flag-icons.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./PrivateRoutes";
import { MsalProvider } from "@azure/msal-react";
import pca from "./msalInstance";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PrivateLayout from "./pages/PrivateLayout";
import GlobalModal from "./components/shared/GlobalModal";
import './App.css'
import './index.css'; // Tailwind CSS
import { PeerProvider } from "./contexts/Peer";
import PaymentResults from "./components/Payment/PaymentResults";
import UserFeedbackModal from "./components/shared/UserFeedbackModal";
import FinancePage from "./pages/FinancePage";
import ProjectsPage from "./pages/ProjectsPage";
import { getUserType } from "./store/actions/ChatAction";
import MessageCenterPage from "./pages/MessageCenterPage";
import TimeKeepingPage from "./pages/TimeKeepingPage";
import ClientPortalPage from "./pages/ClientPortalPage";
import PricingPage from "./components/PlanCard/PricingPage";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import SocketServices from "./utils/SocketServices";
import { UserProvider } from "./contexts/UserProvider";
import { TimerProvider } from "./components/TimeTracker/TimeProvider";
import { DropdownProvider } from "./components/shared/contexts/DropdownContext";
import GlobalSidePanel from "./components/shared/GlobalSidePanel";
import TimeTrackingPage from "./pages/TimeTrackingPage";
import CaseManagementPage from "./pages/CaseManagementPage";
import DesignSystem from "./utils/DesignSystem";
import { ThemeProvider } from "./contexts/ThemeContext";
import NotFoundPage from "./components/shared/TailwindComponents/NotFoundPage";
import ChatInterface from "./components/Chat/ChatInterface";
import SupportTicketSystem from "./components/Feedback/SupportTicketSystem";
import PreferencesSection from "./components/Settings/AccountSettings/Preferences";
import AccountSettings from "./components/Settings/AccountSettings/AccountSettings";
import SecuritySettings from "./components/Settings/Security/SecuritySettings";
import AISettingsComponent from "./components/Settings/AccountSettings/AISettings";
import CompanySettings from "./components/Settings/CompanySettings";
import ClientDetails from "./components/Clients/ClientDetails";
import ManagementPage from "./pages/ManagementPage";
import SecurityPage from "./pages/SecurityPage";
import MarketplaceSettingsPage from "./pages/MarketplaceSettingsPage";
import GlobalShortcuts from "./utils/GlobalShortcuts";
import MarketplacePage from "./pages/MarketplacePage";
import CaseDetails from "./components/Cases/CaseDetails";
import Cases from "./components/Cases/Cases";
import MarketplaceCaseDetails from "./components/Marketplace/Lawyer/MarketplaceCaseDetails";
import LawyerProfile from "./components/Marketplace/Client/LawyerProfile";
import TeamDetail from "./components/Management/Teams/TeamDetail";
import EmployeeDetailPage from "./pages/EmployeeDetailPage";
import EmployeeDetail from "./components/Management/Employees/EmployeeDetail";
import TimeKeepingLogs from "./components/TimeTracker/TimeKeepingLogs";
import ResetPasswordNew from "./components/Auth/ResetPasswordNew";
import TicketDetailPage from "./pages/TicketDetailPage";
import ProjectDetailsPage from "./components/Projects/ProjectDetailsPage";
import TaskDetail from "./components/Tasks/TaskDetail";
import FilesPage from "./pages/FilesPage";
import { PermissionsProvider } from "./contexts/PermissionsContext";
import TaskDeadlineTracker from "./components/Tasks/TaskDeadlineTracker";
import VaultPage from "./pages/VaultPage";
import ToolsPage from "./pages/ToolsPage";
import OperationPage from "./pages/OperationsPage";
import LawyerProfileComponent from "./components/Marketplace/Client/LawyerProfile";
import EngagementDetails from "./components/Marketplace/Lawyer/EngagementDetails";
import EngagementDetailsWrapper from "./components/Marketplace/shared/EngagementDetailsWrapper";

const App: React.FC = () => {
  const userInfo = useSelector((state: RootState) => state.user.userInfo)
  const [activeView, setActiveView] = useState('defaultView');
  const [subscriptionId, setSubscriptionID] = useState("")

  useEffect(() => {
    // SocketServices.initializeSocket()
    setSubscriptionID(userInfo.stripe_subscription_id)
  }, [userInfo.stripe_subscription_id])

  return (
    <ThemeProvider>
      <PeerProvider>
        <MsalProvider instance={pca}>
          <TimerProvider>
            <DropdownProvider>
              <UserProvider>
                <PermissionsProvider>
                  <Router>
                    <UserFeedbackModal />
                    <ToastContainer position="top-right" theme="dark" autoClose={1500} />
                    <Switch>
                      {/* Public Routes */}
                      <Route path="/" exact component={FirstPage} />
                      {/* <Route path="/auth/otp" exact component={OtpInput} /> */}
                      <PrivateRoute
                        path="/reset-password/:token"
                        exact
                        component={ResetPasswordNew}
                        subscriptionId={subscriptionId}
                        restricted={false}
                      />

                      {/* Private Routes */}
                      <PrivateRoute
                        path="/"
                        render={() => (
                          <PrivateLayout activeView={activeView} setActiveView={setActiveView}>
                            <Switch>
                              {/* Dashboard Route */}
                              <PrivateRoute
                                path="/dashboard"
                                exact
                                component={DashboardPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                      {/* Vault Routes */}
                            <PrivateRoute 
                              exact path="/vault" 
                              component={VaultPage} 
                              subscriptionId={subscriptionId} 
                              restricted={false}
                            />

                            <PrivateRoute
                                path="/cases/:caseId"
                                exact
                                component={CaseDetails}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Cases */}
                              <PrivateRoute
                                exact
                                path="/cases"
                                component={CaseManagementPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Client Details */}
                              <PrivateRoute
                                exact
                                path="/clients/:clientId"
                                component={ClientDetails}
                                allowedUserTypes={['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Clients */}
                              <PrivateRoute
                                exact
                                path="/clients"
                                component={ClientPortalPage}
                                allowedUserTypes={['LawFirmAdmin', 'LawFirmEmployee', 'IndependentLawyer']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                               {/* Files */}
                               <PrivateRoute
                                path="/files"
                                component={FilesPage}
                                exact
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                                {/* Projects */}
                                <PrivateRoute
                                path="/projects"
                                exact
                                component={ProjectsPage}
                                allowedUserTypes={['BusinessAdmin', 'BusinessEmployee']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              <PrivateRoute
                                path="/projects/:projectId"
                                component={ProjectDetailsPage}
                                allowedUserTypes={['BusinessAdmin', 'BusinessEmployee']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                            {/* Tools Routes */}
                            <PrivateRoute 
                              exact path="/tools" 
                              component={ToolsPage} 
                              subscriptionId={subscriptionId} 
                              restricted={false}
                            />


                              {/* Legal Services */}
                              <PrivateRoute
                                path="/legal-services"
                                exact
                                component={AIAssistantPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Marketplace */}
                               <PrivateRoute
                                exact
                                path="/marketplace"
                                component={MarketplacePage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              <PrivateRoute
                                path="/marketplace/case/:caseId"
                                exact
                                component={MarketplaceCaseDetails}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              <PrivateRoute
                                path="/marketplace/engagement/:engagementId"
                                exact
                                component={EngagementDetailsWrapper} 
                                subscriptionId={subscriptionId}
                                restricted={false}
                                />
                              <PrivateRoute
                                path="/marketplace/lawyer/:lawyerId"
                                exact
                                component={LawyerProfileComponent}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Operations Routes */}
                             <PrivateRoute 
                              exact path="/operations" 
                              component={OperationPage} 
                              subscriptionId={subscriptionId} 
                              restricted={false}
                            />

                              <PrivateRoute
                                path="/tasks/:taskId"
                                component={TaskDetail}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              <PrivateRoute
                                exact
                                path="/tasks"
                                component={TaskDeadlineTracker}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Admin Page */}
                              <PrivateRoute
                                path="/management/employees/:employeeId"
                                component={EmployeeDetail}
                                allowedUserTypes={['LawFirmAdmin', 'BusinessAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Team Detail Page */}
                              <PrivateRoute
                                path="/management/teams/:teamId"
                                component={TeamDetail}
                                allowedUserTypes={['LawFirmAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              {/* Management Page */}
                              <PrivateRoute
                                exact
                                path="/management"
                                component={ManagementPage}
                                allowedUserTypes={['LawFirmAdmin', 'BusinessAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                exact
                                path="/management/employees"
                                component={ManagementPage}
                                allowedUserTypes={['LawFirmAdmin', 'BusinessAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                exact
                                path="/management/teams"
                                component={ManagementPage}
                                allowedUserTypes={['LawFirmAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              {/* Finance Page */}
                              <PrivateRoute
                                path="/finance"
                                exact
                                component={FinancePage}
                                allowedUserTypes={['LawFirmAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              
                              {/* Analytics */}
                              <PrivateRoute
                                path="/analytics"
                                exact
                                component={ReportsPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Header Routes */}

                              {/* Time Tracking */}
                              <PrivateRoute
                                path="/time-tracking"
                                exact
                                component={TimeTrackingPage}
                                allowedUserTypes={['LawFirmAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Time Keeping */}
                              <PrivateRoute
                                exact
                                path="/time-keeping"
                                component={TimeKeepingPage}
                                allowedUserTypes={['LawFirmEmployee']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              <PrivateRoute
                                path="/time-keeping/logs"
                                exact
                                component={TimeKeepingLogs}
                                allowedUserTypes={['LawFirmEmployee']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              <PrivateRoute
                                path="/marketplace-settings"
                                component={MarketplaceSettingsPage}
                                exact
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Message Center */}
                              <PrivateRoute
                                path="/message-center"
                                exact
                                component={MessageCenterPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Non-restricted routes accessible to everyone */}
                              <PrivateRoute
                                path="/help"
                                exact
                                component={HelpPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/feedback"
                                exact
                                component={FeedbackPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/support"
                                exact
                                component={SupportTicketSystem}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/support/:ticketId"
                                exact
                                component={TicketDetailPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/account"
                                exact
                                component={AccountSettings}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/security"
                                exact
                                component={SecurityPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/platform-preferences"
                                exact
                                component={PreferencesSection}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/ai-settings"
                                exact
                                component={AISettingsComponent}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Organization Settings */}
                              <PrivateRoute
                                path="/organization"
                                exact
                                component={CompanySettings}
                                allowedUserTypes={['LawFirmAdmin', 'BusinessAdmin']}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* Other Routes */}

                              <PrivateRoute
                                path="/payment-result"
                                exact
                                component={PaymentResults}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/design-system"
                                component={DesignSystem}
                                exact
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />
                              <PrivateRoute
                                path="/plans"
                                exact
                                component={PricingPage}
                                subscriptionId={subscriptionId}
                                restricted={false}
                              />

                              {/* 404 Not Found Route */}
                              <Route path="*" component={NotFoundPage} />
                            </Switch>
                          </PrivateLayout>
                        )}
                        subscriptionId={userInfo.stripe_subscription_id}
                        restricted={false}
                      />

                      {/* Global 404 Not Found Route */}
                      <Route path="*" component={NotFoundPage} />
                    </Switch>
                    <GlobalModal />
                    <GlobalShortcuts />
                    <GlobalSidePanel />
                  </Router>
                </PermissionsProvider>
              </UserProvider>
            </DropdownProvider>
          </TimerProvider>
        </MsalProvider>
      </PeerProvider>
    </ThemeProvider>
  );
};

export default App;