export const GET_MY_ACCESS_REQUESTS_REQUEST = 'GET_MY_ACCESS_REQUESTS_REQUEST';
export const GET_MY_ACCESS_REQUESTS_SUCCESS = 'GET_MY_ACCESS_REQUESTS_SUCCESS';
export const GET_MY_ACCESS_REQUESTS_FAILURE = 'GET_MY_ACCESS_REQUESTS_FAILURE';

export const GET_MY_ENGAGEMENTS_REQUEST = 'GET_MY_ENGAGEMENTS_REQUEST';
export const GET_MY_ENGAGEMENTS_SUCCESS = 'GET_MY_ENGAGEMENTS_SUCCESS';
export const GET_MY_ENGAGEMENTS_FAILURE = 'GET_MY_ENGAGEMENTS_FAILURE';

export const GET_ALL_LAWYERS_REQUEST = 'GET_ALL_LAWYERS_REQUEST';
export const GET_ALL_LAWYERS_SUCCESS = 'GET_ALL_LAWYERS_SUCCESS';
export const GET_ALL_LAWYERS_FAILURE = 'GET_ALL_LAWYERS_FAILURE';

export const PUBLISH_CASE_REQUEST = 'PUBLISH_CASE_REQUEST';
export const PUBLISH_CASE_SUCCESS = 'PUBLISH_CASE_SUCCESS';
export const PUBLISH_CASE_FAILURE = 'PUBLISH_CASE_FAILURE';

export const RESPOND_TO_ACCESS_REQUEST_REQUEST = 'RESPOND_TO_ACCESS_REQUEST_REQUEST';
export const RESPOND_TO_ACCESS_REQUEST_SUCCESS = 'RESPOND_TO_ACCESS_REQUEST_SUCCESS';
export const RESPOND_TO_ACCESS_REQUEST_FAILURE = 'RESPOND_TO_ACCESS_REQUEST_FAILURE';

export const INVITE_LAWYER_REQUEST = 'INVITE_LAWYER_REQUEST';
export const INVITE_LAWYER_SUCCESS = 'INVITE_LAWYER_SUCCESS';
export const INVITE_LAWYER_FAILURE = 'INVITE_LAWYER_FAILURE';

export const GET_CASE_DETAILS_REQUEST = 'GET_CASE_DETAILS_REQUEST';
export const GET_CASE_DETAILS_SUCCESS = 'GET_CASE_DETAILS_SUCCESS';
export const GET_CASE_DETAILS_FAILURE = 'GET_CASE_DETAILS_FAILURE';

export const PROPOSE_ENGAGEMENT_REQUEST = 'PROPOSE_ENGAGEMENT_REQUEST';
export const PROPOSE_ENGAGEMENT_SUCCESS = 'PROPOSE_ENGAGEMENT_SUCCESS';
export const PROPOSE_ENGAGEMENT_FAILURE = 'PROPOSE_ENGAGEMENT_FAILURE';

export const ACCEPT_ENGAGEMENT_REQUEST = 'ACCEPT_ENGAGEMENT_REQUEST';
export const ACCEPT_ENGAGEMENT_SUCCESS = 'ACCEPT_ENGAGEMENT_SUCCESS';
export const ACCEPT_ENGAGEMENT_FAILURE = 'ACCEPT_ENGAGEMENT_FAILURE';

export const DECLINE_ENGAGEMENT_REQUEST = 'DECLINE_ENGAGEMENT_REQUEST';
export const DECLINE_ENGAGEMENT_SUCCESS = 'DECLINE_ENGAGEMENT_SUCCESS';
export const DECLINE_ENGAGEMENT_FAILURE = 'DECLINE_ENGAGEMENT_FAILURE';


export const GET_FINAL_DOCUMENTS_REQUEST = 'GET_FINAL_DOCUMENTS_REQUEST';
export const GET_FINAL_DOCUMENTS_SUCCESS = 'GET_FINAL_DOCUMENTS_SUCCESS';
export const GET_FINAL_DOCUMENTS_FAILURE = 'GET_FINAL_DOCUMENTS_FAILURE';

export const SUBMIT_LAWYER_RATING_REQUEST = 'SUBMIT_LAWYER_RATING_REQUEST';
export const SUBMIT_LAWYER_RATING_SUCCESS = 'SUBMIT_LAWYER_RATING_SUCCESS';
export const SUBMIT_LAWYER_RATING_FAILURE = 'SUBMIT_LAWYER_RATING_FAILURE';

export const UPDATE_CASE_STATUS_REQUEST = 'UPDATE_CASE_STATUS_REQUEST';
export const UPDATE_CASE_STATUS_SUCCESS = 'UPDATE_CASE_STATUS_SUCCESS';
export const UPDATE_CASE_STATUS_FAILURE = 'UPDATE_CASE_STATUS_FAILURE';

export const GET_RECOMMENDED_LAWYERS_REQUEST = 'GET_RECOMMENDED_LAWYERS_REQUEST';
export const GET_RECOMMENDED_LAWYERS_SUCCESS = 'GET_RECOMMENDED_LAWYERS_SUCCESS';
export const GET_RECOMMENDED_LAWYERS_FAILURE = 'GET_RECOMMENDED_LAWYERS_FAILURE';
