import React from 'react';
import { useTranslation } from 'react-i18next';

interface TextareaProps {
  id: string;
  name?: string; // Add 'name' prop
  label: string;
  placeholder?: string;
  value?: string;
  rows?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean; // Add 'disabled' prop
  error?: string; // Add 'error' prop
  description?: string;
  italicTxt?: string;
}

const Textarea: React.FC<TextareaProps> = ({
  id,
  name,
  label,
  placeholder,
  value,
  rows = 4,
  onChange,
  disabled,
  error,
  description,
  italicTxt
}) => {
  const { t } = useTranslation();
  return (
    <div className='w-full'>
      <label htmlFor={id} className="block mb-1 font-medium">
        {t(label)}
      </label>
      <div className='flex flex-col space-y-1 mb-2'>
      {italicTxt && (
        <p className='text-xs text-gray-500 dark:text-gray-300 text-left italic'>{t(italicTxt)}</p>
      )}
      {description && (
        <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t(description)}</p>
      )}
            </div>
      <textarea
        id={id}
        name={name} // Include 'name' attribute
        className="mt-1 block w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 transition duration-300 input"
        placeholder={placeholder}
        value={value}
        rows={rows}
        onChange={onChange}
        disabled={disabled} // Include 'disabled' attribute
      ></textarea>
            {error && (
          <p className="mt-1 text-sm text-red-600 dark:text-red-400">{error}</p>
        )}
    </div>
  );
};

export default Textarea;
