import React, { useState } from 'react';
import { FileProps, FolderProps } from './types';
import { useTranslation } from 'react-i18next';
import Button from '../shared/TailwindComponents/Button';
import Table from '../shared/TailwindComponents/Table';
import Badge from '../shared/TailwindComponents/Badge';
import { useAppDispatch } from '../../store';
import { showAddFileSidePanel } from '../../store/actions/sidePanelActions';
import { usePermission } from '../../hooks/userPermissions';

interface DocumentListProps {
  documents: FileProps[];
  onSelectFile: (file: FileProps) => void;
  folders?: FolderProps[];
  selectedFolderId?: string;
}

function getFileCategoryIcon(fileType: string): string {
  switch (fileType) {
    case 'Contract':
      return 'fas fa-file-contract';
    case 'Legal Document':
      return 'fas fa-gavel';
    case 'General':
      return 'fas fa-file-alt';
    case 'Template':
      return 'fas fa-file-invoice';
    default:
      return 'fas fa-file';
  }
}

function formatDate(dateString: string): string {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
}

const DocumentList: React.FC<DocumentListProps> = ({ documents, onSelectFile, folders, selectedFolderId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const { hasAccess, isReadOnly, loading } = usePermission('documents');
  const handleSelectFile = (fileId: string) => {
    setSelectedFiles((prevSelected) =>
      prevSelected.includes(fileId)
        ? prevSelected.filter((id) => id !== fileId)
        : [...prevSelected, fileId]
    );
  };

  const handleDelete = () => {
    // Implement delete functionality
    setSelectedFiles([]);
  };

  const handleDownload = () => {
    // Implement download functionality
  };

  const handleAddFile = () => {
    if (folders) {
      dispatch(showAddFileSidePanel(folders, selectedFolderId));
    }
  };

  const columns = ['Name', 'Type', 'Last Modified'];

  // Include 'doc' in the row data
  const data = documents.map((doc) => ({
    id: doc.FileID,
    doc: doc, // Include the entire doc object
    Name: (
      <div className="flex items-center">
        <i className={`${getFileCategoryIcon(doc.FileCategory)} mr-2`}></i>
        {doc.FileName}
      </div>
    ),
    Type: <Badge color="blue">{t(doc.FileCategory)}</Badge>,
    'Last Modified': formatDate(doc.ModifiedAt),
  }));

  return (
    <div className="px-2 flex flex-col h-full">
      {selectedFiles.length > 0 && (
        <div className="mb-2 flex space-x-2">
          <Button variant="danger" onClick={handleDelete}>
            {t('Delete')}
          </Button>
          <Button variant="primary" onClick={handleDownload}>
            {t('Download')}
          </Button>
        </div>
      )}
          <div className="flex-1 overflow-y-auto">
        <Table
          columns={columns}
          data={data}
          selectFunction={(row) => onSelectFile(row.doc)}
          selectLabel={t('Open')}
          fixedHeader={true}
        />
            </div>
      {documents.length === 0 && (
        <div className="flex flex-col items-center justify-center py-8">
          <p className="text-gray-600 dark:text-gray-400 mb-4">{t('No files found.')}</p>
          <Button
          disabled={isReadOnly}
          variant="primary" onClick={handleAddFile}>
            {t('Add New File')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DocumentList;
