import { AnyAction } from 'redux';
import {
  GET_CLIENT_REQUESTS_REQUEST,
  GET_CLIENT_REQUESTS_SUCCESS,
  GET_CLIENT_REQUESTS_FAILURE,
  GET_INCOMING_CLIENT_REQUESTS_REQUEST,
  GET_INCOMING_CLIENT_REQUESTS_SUCCESS,
  GET_INCOMING_CLIENT_REQUESTS_FAILURE,
  ACCEPT_CLIENT_REQUEST_SUCCESS,
  ACCEPT_CLIENT_REQUEST_FAILURE,
  REJECT_CLIENT_REQUEST_SUCCESS,
  REJECT_CLIENT_REQUEST_FAILURE,
} from '../../types';

interface RequestsState {
  lawyerRequests: any[];
  incomingClientRequests: any[];
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  totalPages: number;
}

const initialState: RequestsState = {
  lawyerRequests: [],
  incomingClientRequests: [],
  isLoading: false,
  error: null,
  currentPage: 1,
  totalPages: 1,
};

export const marketplaceRequestsReducer = (
  state = initialState,
  action: AnyAction
): RequestsState => {
  switch (action.type) {
    case GET_CLIENT_REQUESTS_REQUEST:
    case GET_INCOMING_CLIENT_REQUESTS_REQUEST:
      return { ...state, isLoading: true, error: null };
    case GET_CLIENT_REQUESTS_SUCCESS:
      return {
        ...state,
        lawyerRequests: action.payload.requests,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        isLoading: false,
        error: null,
      };
    case GET_INCOMING_CLIENT_REQUESTS_SUCCESS:
      return {
        ...state,
        incomingClientRequests: action.payload.requests,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        isLoading: false,
        error: null,
      };
    case GET_CLIENT_REQUESTS_FAILURE:
    case GET_INCOMING_CLIENT_REQUESTS_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case ACCEPT_CLIENT_REQUEST_SUCCESS:
    case REJECT_CLIENT_REQUEST_SUCCESS:
      return { ...state, error: null };
    case ACCEPT_CLIENT_REQUEST_FAILURE:
    case REJECT_CLIENT_REQUEST_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
