import React, { useEffect, useState } from 'react';
import { useAppDispatch, RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { getLawyerProfile, updateLawyerProfile } from '../../../store/actions/marketplace2/MarketplaceProvider/MarketplaceProviderActions';
import Input from '../../shared/TailwindComponents/Input';
import Textarea from '../../shared/TailwindComponents/Textarea';
import Button from '../../shared/TailwindComponents/Button';
import TagInput from '../../shared/TailwindComponents/TagInput';
import Toggle from '../../shared/TailwindComponents/Toggle';
import SingleFileInput from '../../shared/TailwindComponents/SingleFileInput';
import { getUserID } from '../../../store/actions/ChatAction';

const LawyerProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const lawyerProfile = useSelector((state: RootState) => state.marketplaceProvider.lawyerProfile);
  const isLoading = useSelector((state: RootState) => state.marketplaceProvider.isLoading);
  const userId = useSelector((state: RootState) => state.user.userInfo.UserID)
  const [formData, setFormData] = useState<any>({
    HourlyRate: '',
    Bio: '',
    ExperienceYears: '',
    Specializations: [],
    LanguagesSpoken: [],
    Education: '',
    Certifications: [],
    Awards: [],
    Publications: [],
    ProfessionalMemberships: [],
    Portfolio: [],
    AvailabilityStatus: '',
    is_marketplace_active: false,
    PreferredCaseTypes: [],
    PreferredBudgetMin: '',
    PreferredBudgetMax: '',
    PreferredLocations: [],
    PreferredKeywords: [],
    ProfilePicture: null,
    TermsOfServiceFile: null,
  });

  useEffect(() => {
    dispatch(getLawyerProfile(userId));
  }, [dispatch]);

  useEffect(() => {
    if (lawyerProfile) {
      setFormData({
        ...formData,
        HourlyRate: lawyerProfile.HourlyRate || '',
        Bio: lawyerProfile.Bio || '',
        ExperienceYears: lawyerProfile.ExperienceYears || '',
        Specializations: lawyerProfile.Specializations || [],
        LanguagesSpoken: lawyerProfile.LanguagesSpoken || [],
        Education: lawyerProfile.Education || '',
        Certifications: lawyerProfile.Certifications || [],
        Awards: lawyerProfile.Awards || [],
        Publications: lawyerProfile.Publications || [],
        ProfessionalMemberships: lawyerProfile.ProfessionalMemberships || [],
        Portfolio: lawyerProfile.Portfolio || [],
        AvailabilityStatus: lawyerProfile.AvailabilityStatus || '',
        is_marketplace_active: lawyerProfile.is_marketplace_active || false,
        PreferredCaseTypes: lawyerProfile.PreferredCaseTypes || [],
        PreferredBudgetMin: lawyerProfile.PreferredBudgetMin || '',
        PreferredBudgetMax: lawyerProfile.PreferredBudgetMax || '',
        PreferredLocations: lawyerProfile.PreferredLocations || [],
        PreferredKeywords: lawyerProfile.PreferredKeywords || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawyerProfile]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTagChange = (name: string, values: string[]) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: values,
    }));
  };

  const handleFileChange = (name: string, file: File | null) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: file,
    }));
  };

  const handleToggleChange = (name: string, value: boolean) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const updateData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item: string) => {
          updateData.append(key, item);
        });
      } else if (formData[key] instanceof File) {
        updateData.append(key, formData[key]);
      } else {
        updateData.append(key, formData[key]);
      }
    });

    dispatch(updateLawyerProfile(updateData));
  };

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-6">My Marketplace Profile</h2>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <Input
            label="Hourly Rate"
            name="HourlyRate"
            value={formData.HourlyRate}
            onChange={handleInputChange}
            type="number"
          />
          <Textarea
                          label="Bio"
                          name="Bio"
                          value={formData.Bio}
                          onChange={handleInputChange} id={''}          />
          <Input
            label="Experience Years"
            name="ExperienceYears"
            value={formData.ExperienceYears}
            onChange={handleInputChange}
            type="number"
          />
          <TagInput
                          label="Specializations"
                          tags={formData.Specializations}
                          onChange={(values) => handleTagChange('Specializations', values)} id={''}          />
          <TagInput
                          label="Languages Spoken"
                          tags={formData.LanguagesSpoken}
                          onChange={(values) => handleTagChange('LanguagesSpoken', values)} id={''}          />
          <Input
                          label="Education"
                          name="Education"
                          value={formData.Education}
                          onChange={handleInputChange} type={''}          />
          <TagInput
                          label="Certifications"
                          tags={formData.Certifications}
                          onChange={(values) => handleTagChange('Certifications', values)} id={''}          />
          <TagInput
                          label="Awards"
                          tags={formData.Awards}
                          onChange={(values) => handleTagChange('Awards', values)} id={''}          />
          <TagInput
                          label="Publications"
                          tags={formData.Publications}
                          onChange={(values) => handleTagChange('Publications', values)} id={''}          />
          <TagInput
                          label="Professional Memberships"
                          tags={formData.ProfessionalMemberships}
                          onChange={(values) => handleTagChange('ProfessionalMemberships', values)} id={''}          />
          <TagInput
                          label="Preferred Case Types"
                          tags={formData.PreferredCaseTypes}
                          onChange={(values) => handleTagChange('PreferredCaseTypes', values)} id={''}          />
          <div className="grid grid-cols-2 gap-4">
            <Input
              label="Preferred Budget Min"
              name="PreferredBudgetMin"
              value={formData.PreferredBudgetMin}
              onChange={handleInputChange}
              type="number"
            />
            <Input
              label="Preferred Budget Max"
              name="PreferredBudgetMax"
              value={formData.PreferredBudgetMax}
              onChange={handleInputChange}
              type="number"
            />
          </div>
          <TagInput
                          label="Preferred Locations"
                          tags={formData.PreferredLocations}
                          onChange={(values) => handleTagChange('PreferredLocations', values)} id={''}          />
          <TagInput
                          label="Preferred Keywords"
                          tags={formData.PreferredKeywords}
                          onChange={(values) => handleTagChange('PreferredKeywords', values)} id={''}          />
          <SingleFileInput
            label="Profile Picture"
            // name="ProfilePicture"
            setFile={(file) => handleFileChange('ProfilePicture', file)}
            file={null} 
          />
          <SingleFileInput
            label="Terms of Service"
            setFile={(file) => handleFileChange('TermsOfServiceFile', file)} 
            file={null}          />
          <div className="flex items-center mt-4">
            <Toggle
                enabled={formData.is_marketplace_active}
                onToggle={() => handleToggleChange('is_marketplace_active', true)} 
                value={false}            />
            <label className="ml-3 text-sm font-medium text-gray-700">
              Active on Marketplace
            </label>
          </div>
          <Button type="submit" variant="primary">
            Save Profile
          </Button>
        </form>
      )}
    </div>
  );
};

export default LawyerProfile;
