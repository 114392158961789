import React from 'react';
import Badge from './Badge';
import { useHistory } from 'react-router-dom';
import { Case } from '../../Marketplace/shared/types';

interface CaseCardProps {
  caseItem: Case;
}

const CaseCard: React.FC<CaseCardProps> = ({ caseItem }) => {
  const history = useHistory();

  const handleViewCase = () => {
    history.push({
      pathname: `/marketplace/case/${caseItem.CaseID}`,
      state: { caseItem },
    });
  };

  const {
    CaseType,
    BudgetRange,
    Deadline,
    Categories,
    Tags,
  } = caseItem;

  return (
    <div
      onClick={handleViewCase}
      className="bg-white dark:bg-gray-800 rounded-lg shadow hover:shadow-lg transition-shadow duration-300 p-6 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
    >
      {/* Header with Case Type and Badges */}
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100">{CaseType || 'Case'}</h3>
        {/* Badges */}
        <div className="flex flex-wrap">
          {BudgetRange && (
            <Badge color="green" className="mr-2 mb-2">
              ${BudgetRange.Min} - ${BudgetRange.Max}
            </Badge>
          )}
          {Deadline && (
            <Badge color="yellow" className="mb-2">
              {new Date(Deadline).toLocaleDateString()}
            </Badge>
          )}
        </div>
      </div>

      {/* Categories */}
      {Categories && Categories.length > 0 && (
        <div className="mb-4">
          <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">Categories</h4>
          <div className="flex flex-wrap">
            {Categories.map((category, index) => (
              <Badge key={index} color="blue" className="mr-2 mb-2">
                {category}
              </Badge>
            ))}
          </div>
        </div>
      )}
      {/* Tags */}
      {Tags && Tags.length > 0 && (
        <div>
          <h4 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">Tags</h4>
          <div className="flex flex-wrap">
            {Tags.map((tag, index) => (
              <Badge key={index} color="indigo" className="mr-2 mb-2">
                {tag}
              </Badge>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseCard;
