import React, { useState } from 'react';
import Button from './Button';
import Dropdown from './Dropdown';
import Alert from './Alert';
import clsx from 'clsx';
import { useAppDispatch } from '../../../store';
import Textarea from './Textarea';
import { submitBid } from '../../../store/actions/marketplace2/marketplaceBidsActions';

interface BidComponentProps {
  caseId: string;
  onBidSuccess?: () => void;
  onBidFailure?: () => void;
}

const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
  // Add more currencies as needed
];

const currencySymbols: { [key: string]: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  // Add more symbols as needed
};

const BidComponent: React.FC<BidComponentProps> = ({
  caseId,
  onBidSuccess,
  onBidFailure,
}) => {
  const [currency, setCurrency] = useState<string>('USD');
  const [amount, setAmount] = useState<number>(0.0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [bidDescription, setBidDescription] = useState<string>('');
  const dispatch = useAppDispatch();

  const handleCurrencyChange = (selectedValues: string[] | string) => {
    if (Array.isArray(selectedValues)) {
      if (selectedValues.length > 0) {
        setCurrency(selectedValues[0]);
      }
    } else {
      setCurrency(selectedValues);
    }
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      setAmount(numericValue);
    } else {
      setAmount(0.0);
    }
  };

  const incrementAmount = () => {
    setAmount((prev) => parseFloat((prev + 1).toFixed(2)));
  };

  const decrementAmount = () => {
    setAmount((prev) => (prev > 0 ? parseFloat((prev - 1).toFixed(2)) : 0.0));
  };

  const handleBidSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate input
    if (amount <= 0) {
      alert('Please enter a valid bid amount.');
      return;
    }

    setIsSubmitting(true);

    try {
      // Prepare bid data
      const bidData = {
        CaseID: caseId,
        BidAmount: amount,
        BidDescription: bidDescription,
      };

      // Dispatch the submitBid action
      await dispatch(submitBid(bidData));

      // Clear form after submission
      setAmount(0.0);
      setBidDescription('');
      if (onBidSuccess) onBidSuccess();
    } catch (error) {
      if (onBidFailure) onBidFailure();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={clsx(
        'p-6 rounded-lg shadow-md transition-colors duration-300',
        'bg-white dark:bg-gray-800'
      )}
    >
      <form onSubmit={handleBidSubmit} className="space-y-6">
        {/* Bid Amount */}
        <div>
          <label htmlFor="amount" className="block text-sm font-medium mb-1">
            Bid Amount
          </label>
          <div className='flex flex-row flex-1 space-x-2'>
          <div className="flex items-center">
            {/* Currency Symbol */}
            <span className="text-4xl font-semibold mr-2">
              {currencySymbols[currency]}
            </span>
            {/* Amount Input */}
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={handleAmountChange}
              min={0}
              step="0.01"
              required
              className="w-full text-4xl font-semibold bg-transparent border-none focus:outline-none"
            />
            {/* Increment/Decrement Buttons */}
            <div className="flex flex-col ml-2">
              <button
                type="button"
                onClick={incrementAmount}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                ▲
              </button>
              <button
                type="button"
                onClick={decrementAmount}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                ▼
              </button>
            </div>
          </div>
        {/* Currency Dropdown */}
        <div className="">
          <label htmlFor="currency" className="block text-sm font-medium mb-1">
            Currency
          </label>
          <Dropdown
            id="currency"
            options={currencyOptions}
            placeholder="Select"
            onChange={handleCurrencyChange}
            value={currency}
            className="w-full"
          />
        </div>
        </div>
        </div>


        {/* Bid Description */}
        <div>
          <Textarea
            id="bid-description"
            value={bidDescription}
            onChange={(e) => setBidDescription(e.target.value)}
            placeholder="Describe your approach or any relevant information"
            label={'Bid Description'}
          />
        </div>

        {/* Place Bid Button */}
        <div className="flex w-full">
          <Button type="submit" className='w-full' variant="primary" disabled={isSubmitting}>
            {isSubmitting ? 'Placing Bid...' : 'Place Bid'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BidComponent;
