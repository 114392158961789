import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../..'; // Adjust the path as needed
import {
  PUBLISH_CASE_SUCCESS,
  PUBLISH_CASE_FAILURE,
  UPLOAD_FINAL_DOCUMENT_SUCCESS,
  UPLOAD_FINAL_DOCUMENT_FAILURE,
  GET_FINAL_DOCUMENTS_SUCCESS,
  GET_FINAL_DOCUMENTS_FAILURE,
  SUBMIT_LAWYER_RATING_SUCCESS,
  SUBMIT_LAWYER_RATING_FAILURE,
  GET_LAWYERS_FAILURE,
  GET_LAWYERS_REQUEST,
  GET_LAWYERS_SUCCESS,
  SET_MARKETPLACE_ACTIVE_SUCCESS,
  SET_MARKETPLACE_ACTIVE_FAILURE,
} from '../../types';
import apis from '../../../utils/apis';
import { showFeedbackModal } from '../UserFeedbackActions';
import { Dispatch } from 'react';
import { GET_PUBLISHED_CASES_SUCCESS, GET_PUBLISHED_CASES_FAILURE } from '../../reducer/marketplace2/MarketplaceProvider/MarketplaceProviderTypes';

// **1. Publish Case to Marketplace**

export const publishCaseSuccess = (): AnyAction => ({
  type: PUBLISH_CASE_SUCCESS,
});

export const publishCaseFailure = (error: string): AnyAction => ({
  type: PUBLISH_CASE_FAILURE,
  payload: error,
});

export const setMarketplaceActiveSuccess = (): AnyAction => ({
  type: SET_MARKETPLACE_ACTIVE_SUCCESS,
});
export const setMarketplaceActiveFailure = (error: string): AnyAction => ({
  type: SET_MARKETPLACE_ACTIVE_FAILURE,
  payload: error,
});

export const publishCase = (caseData: {
  CaseID: string;
  AnonymizedDescription: string;
  BudgetRange: { Min: number; Max: number };
  Deadline: string;
  DesiredOutcome: string;
  AdditionalNotes?: string;
  Categories?: string[];
  Tags?: string[];
  AttachedFiles?: string[];
}) => {
  return async (dispatch:  ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis.post('/marketplace/publish_case', caseData);
      dispatch(publishCaseSuccess());
    } catch (error: any) {
      dispatch(publishCaseFailure(error.toString()));
    }
  };
};


// **2. Get Published Cases**

export const getPublishedCasesSuccess = (cases: any[]): AnyAction => ({
  type: GET_PUBLISHED_CASES_SUCCESS,
  payload: cases,
});

export const getPublishedCasesFailure = (error: string): AnyAction => ({
  type: GET_PUBLISHED_CASES_FAILURE,
  payload: error,
});

export const getPublishedCases = (params = {}) => {
  return async (dispatch:  ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis.get('/marketplace/published_cases', { params });
      const cases = response.data.cases;
      dispatch(getPublishedCasesSuccess(cases));
    } catch (error: any) {
      dispatch(getPublishedCasesFailure(error.toString()));
    }
  };
};


// **3. Get Lawyers**

export const getLawyersRequest = (): AnyAction => ({
  type: GET_LAWYERS_REQUEST,
});

export const getLawyersSuccess = (data: {
  lawyers: any[];
  currentPage: number;
}): AnyAction => ({
  type: GET_LAWYERS_SUCCESS,
  payload: data,
});

export const getLawyersFailure = (error: string): AnyAction => ({
  type: GET_LAWYERS_FAILURE,
  payload: error,
});

interface GetLawyersParams {
  page?: number;
  per_page?: number;
  Specialization?: string;
  MinExperience?: number;
  MaxHourlyRate?: number;
}

export const getLawyers = (params: GetLawyersParams) => {
  return async (dispatch:  ThunkDispatch<RootState, undefined, AnyAction>) => {
    dispatch(getLawyersRequest());
    try {
      const response = await apis.get('/marketplace/get_all_lawyers', { params });
      const { lawyers } = response.data;
      dispatch(
        getLawyersSuccess({
          lawyers,
          currentPage: params.page || 1,
        })
      );
    } catch (error: any) {
      dispatch(getLawyersFailure(error.toString()));
    }
  };
};



// **7. Upload Final Document**

export const uploadFinalDocumentSuccess = (): AnyAction => ({
  type: UPLOAD_FINAL_DOCUMENT_SUCCESS,
});

export const uploadFinalDocumentFailure = (error: string): AnyAction => ({
  type: UPLOAD_FINAL_DOCUMENT_FAILURE,
  payload: error,
});

export const uploadFinalDocument = (caseId: string, file: File) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const formData = new FormData();
      formData.append('CaseID', caseId);
      formData.append('file', file);

      await apis({
        method: 'POST',
        url: '/marketplace/upload_final_document',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      dispatch(uploadFinalDocumentSuccess());
    } catch (error: any) {
      dispatch(uploadFinalDocumentFailure(error.toString()));
    }
  };
};

// **8. Get Final Documents**

export const getFinalDocumentsSuccess = (caseId: string, documents: any[]): AnyAction => ({
  type: GET_FINAL_DOCUMENTS_SUCCESS,
  payload: { caseId, documents },
});

export const getFinalDocumentsFailure = (error: string): AnyAction => ({
  type: GET_FINAL_DOCUMENTS_FAILURE,
  payload: error,
});

export const getFinalDocuments = (caseId: string) => {
  return async (dispatch:  ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis.post('/marketplace/get_final_documents', { CaseID: caseId });
      const documents = response.data.documents;
      dispatch(getFinalDocumentsSuccess(caseId, documents));
    } catch (error: any) {
      dispatch(getFinalDocumentsFailure(error.toString()));
    }
  };
};

// **9. Submit Lawyer Rating**

export const submitLawyerRatingSuccess = (): AnyAction => ({
  type: SUBMIT_LAWYER_RATING_SUCCESS,
});

export const submitLawyerRatingFailure = (error: string): AnyAction => ({
  type: SUBMIT_LAWYER_RATING_FAILURE,
  payload: error,
});

export const submitLawyerRating = (ratingData: {
  CaseID: string;
  Rating: number;
  Review?: string;
  FirmRating?: number;
  FirmReview?: string;
}) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      await apis.post('/marketplace/submit_lawyer_rating', ratingData);
      dispatch(submitLawyerRatingSuccess());
    } catch (error: any) {
      dispatch(submitLawyerRatingFailure(error.toString()));
    }
  };
};





export const getLawyerProfile = (lawyerId: string) => {
  return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
    try {
      const response = await apis({
        method: 'POST',
        url: '/marketplace/get_lawyer_profile',
        data: { LawyerID: lawyerId },
      });
      const profile = response.data.profile;
    } catch (error: any) {
    }
  };
};

// **11. Create Case**
  
  export const createCase = (caseData: FormData) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      try {
        const response = await apis({
          method: 'POST',
          url: '/cases/create',
          data: caseData,
        });
        if(response && response.status === 200) {
          dispatch(showFeedbackModal({
            modalType: 'success',
            showModal: true,
            message: (`Case created`),
            duration: 3000
          }))
          return response.data;
        } else {
          dispatch(showFeedbackModal({
            modalType: 'error',
            showModal: true,
            message: (`Failed to create case.`),
            duration: 3000
          }))
        }
      } catch (error: any) {
        dispatch(showFeedbackModal({
            modalType: 'error',
            showModal: true,
            message: (`Failed to create case`),
            duration: 3000
        }))
      }
    }
  };



  export const setMarketplaceActive = (isActive: boolean) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
      try {
        await apis({
          method: 'POST',
          url: '/marketplace/set_marketplace_active',
          data: { is_active: isActive },
        });
        dispatch(setMarketplaceActiveSuccess());
      } catch (error: any) {
        dispatch(setMarketplaceActiveFailure(error.toString()));
      }
    };
  };