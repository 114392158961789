import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../store';
import {
  getMarketplaceSettings,
  updateLawyerTOS,
  updateMarketplaceSettings,
  uploadFirmLogo,
  uploadProfilePicture,
} from '../../../../store/actions/marketplace2/MarketplaceSettingsActions';
import Textarea from '../../../shared/TailwindComponents/Textarea';
import Input from '../../../shared/TailwindComponents/Input';
import Dropdown from '../../../shared/TailwindComponents/Dropdown';
import SingleFileInput from '../../../shared/TailwindComponents/SingleFileInput';
import Button from '../../../shared/TailwindComponents/Button';
import { getUserID } from '../../../../store/actions/ChatAction';
import ToggleSwitch from '../../../shared/TailwindComponents/ToggleSwitch';
import { getUserType } from '../../../../utils/authUtils';
import Divider from '../../../shared/TailwindComponents/Divider';

const MarketplaceSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userId = getUserID();
  const userType = getUserType();

  const { settings } = useSelector((state: RootState) => state.marketplaceSettings);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    dispatch(getMarketplaceSettings());
  }, [dispatch]);

  // Extract Individual and Organization Settings
  const individualSettings = settings?.marketplace_settings?.IndividualMarketplaceSettings || {};
  const organizationSettings = settings?.marketplace_settings?.OrganizationMarketplaceSettings || {};

  // Prepare initial values
  const initialValues = {
    // Individual settings
    HourlyRate: individualSettings.HourlyRate || '',
    Bio: individualSettings.Bio || '',
    Specializations: individualSettings.Specializations || [],
    ExperienceYears: individualSettings.ExperienceYears || '',
    is_marketplace_active: individualSettings.is_marketplace_active || false,
    // Organization settings
    OrganizationName: organizationSettings.OrganizationName || '',
    Description: organizationSettings.Description || '',
    PracticeArea: organizationSettings.Specializations || [],
    is_marketplace_active_org: organizationSettings.is_marketplace_active || false,
  };

  // Formik setup
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const payload: any = {};
      // Update Individual Settings
      payload.IndividualMarketplaceSettings = {
        HourlyRate: values.HourlyRate,
        Bio: values.Bio,
        Specializations: values.Specializations,
        ExperienceYears: values.ExperienceYears,
        is_marketplace_active: values.is_marketplace_active,
      };
      // Update Organization Settings if applicable
      if (userType === 'LawFirmAdmin') {
        payload.OrganizationMarketplaceSettings = {
          OrganizationName: values.OrganizationName,
          Description: values.Description,
          Specializations: values.PracticeArea,
          is_marketplace_active: values.is_marketplace_active_org,
        };
      }
      await dispatch(updateMarketplaceSettings(payload));
      setHasUnsavedChanges(false);
      dispatch(getMarketplaceSettings())
    },
  });

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.handleChange(e);
    setHasUnsavedChanges(true);
  };

  const handleSpecializationsChange = (values: string[] | string, fieldName: string) => {
    formik.setFieldValue(fieldName, values);
    setHasUnsavedChanges(true);
  };

  const handleToggleMarketplaceActive = (checked: boolean, type: 'individual' | 'organization') => {
    if (type === 'individual') {
      formik.setFieldValue('is_marketplace_active', checked);
    } else {
      formik.setFieldValue('is_marketplace_active_org', checked);
    }
    setHasUnsavedChanges(true);
  };

  // File Uploads
  const [firmLogoFile, setFirmLogoFile] = useState<File | null>(null);
  const [profilePictureFile, setProfilePictureFile] = useState<File | null>(null);
  const [lawyerTOSFile, setLawyerTOSFile] = useState<File | null>(null);

  const handleFirmLogoUpload = (file: File | null) => {
    setFirmLogoFile(file);
  };

  const handleProfilePictureUpload = (file: File | null) => {
    setProfilePictureFile(file);
  };

  const handleLawyerTOSUpload = (file: File | null) => {
    setLawyerTOSFile(file);
  };

  const handleSaveFiles = () => {
    if (firmLogoFile) {
      dispatch(uploadFirmLogo(firmLogoFile));
      setFirmLogoFile(null);
    }
    if (profilePictureFile) {
      dispatch(uploadProfilePicture(profilePictureFile));
      setProfilePictureFile(null);
    }
    if (lawyerTOSFile) {
      dispatch(updateLawyerTOS(lawyerTOSFile));
      setLawyerTOSFile(null);
    }
    dispatch(getMarketplaceSettings())
  };

  return (
    <div className="divide-y divide-gray-200 dark:divide-gray-700 px-2">
      {/* Individual Marketplace Settings Section */}
      <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-8 md:grid-cols-3">
        {/* Left Sidebar */}
        <div className="px-4 sm:px-6 md:px-0">
          <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
            {t('My Marketplace Settings')}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
            {t('Manage your personal marketplace profile to showcase your expertise and attract clients.')}
          </p>
        </div>
        {/* Right Content */}
        <div className="md:col-span-2 space-y-6">
          {/* Profile Picture Upload */}
          <SingleFileInput
            label={t('Profile Picture')}
            accept="image/*"
            file={profilePictureFile}
            setFile={handleProfilePictureUpload}
            existingFileUrl={individualSettings.ProfilePicture}
            subMsg='Ensure your profile picture reflects a professional and approachable image to instill confidence in potential clients.'
              desc='Upload or update your professional profile image.'
          />

          {/* Lawyer TOS Upload */}
          <SingleFileInput
            label={t('Terms of Service')}
            accept=".pdf,.doc,.docx"
            file={lawyerTOSFile}
            setFile={handleLawyerTOSUpload}
            existingFileUrl={individualSettings.TermsOfServiceURL}
            subMsg={`${t('Clearly define the scope, conditions, and expectations for your services to maintain transparency with clients.')}`}
            desc='Edit or review the terms of service for your offerings.'
          />

          {/* Save Files Button */}
          {(profilePictureFile || lawyerTOSFile) && (
            <div className="mt-4">
              <Button variant="primary" onClick={handleSaveFiles}>
                {t('Save Files')}
              </Button>
            </div>
          )}

          {/* Marketplace Active Toggle */}
          <div className="flex flex-col mt-4">
            <div>
            <label
              htmlFor="is_marketplace_active"
              className="mr-2 text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              {t('Marketplace Active')}
            </label>
            <div className='flex flex-col space-y-1 mb-2'>
            <p className="text-xs text-gray-500 dark:text-gray-300 text-left italic">{t('Control the visibility of your marketplace profile.')}</p>
            <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t('Activate or deactivate your profile as needed to manage your availability and engagement with clients.')}</p>
            </div>
            </div>
            <ToggleSwitch
              id="is_marketplace_active"
              name="is_marketplace_active"
              checked={formik.values.is_marketplace_active}
              onChange={(checked) => handleToggleMarketplaceActive(checked, 'individual')}
            />
          </div>

          {/* Form for Individual Settings */}
          <form onSubmit={formik.handleSubmit} className="space-y-6 mt-6">
            {/* Hourly Rate */}
            <Input
              label={t('Hourly Rate')}
              italicTxt='Set the rate you charge per hour for your legal services.'
              id="HourlyRate"
              name="HourlyRate"
              type="number"
              description='Displaying your rate allows potential clients to gauge the cost of your expertise upfront.'
              value={formik.values.HourlyRate}
              onChange={handleInputChange}
            />

            {/* Bio */}
            <Textarea
              label={t('Bio')}
              italicTxt='Craft a compelling summary of your professional background and approach.'
              description='Highlight your qualifications, achievements, and what sets you apart in the legal field.'
              id="Bio"
              name="Bio"
              value={formik.values.Bio}
              onChange={handleInputChange}
            />

            {/* Specializations */}
            <Dropdown
              label={t('Specializations')}
              id="Specializations"
              options={[
                { value: 'Contract Law', label: t('Contract Law') },
                { value: 'Business Law', label: t('Business Law') },
                // Add more options as needed
              ]}
              value={formik.values.Specializations}
              onChange={(values) => handleSpecializationsChange(values, 'Specializations')}
              multiple
              italicTxt='Select your areas of legal expertise to target relevant clients.'
              description='Specify the fields of law where you excel, ensuring your profile reaches the right audience.'
            />

            {/* Experience Years */}
            <Input
              label={t('Years of Experience')}
              id="ExperienceYears"
              name="ExperienceYears"
              type="number"
              value={formik.values.ExperienceYears}
              onChange={handleInputChange}
              italicTxt='Indicate the number of years you have practiced law.'
              description='Your experience level adds credibility and helps clients assess your suitability for their needs.'
            />

            {/* Save Button */}
            <div className="mt-8 flex gap-x-4">
              <Button variant="primary" type="submit" disabled={!hasUnsavedChanges}>
                {t('Save')}
              </Button>
              {hasUnsavedChanges && (
                <Button
                  variant="destructive"
                  onClick={() => {
                    formik.resetForm();
                    setHasUnsavedChanges(false);
                  }}
                >
                  {t('Cancel')}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* Organization Marketplace Settings Section */}
      {userType === 'LawFirmAdmin' && (
        <>
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 py-16 md:grid-cols-3">
            <div className="px-4 sm:px-6 md:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                {t('Organization Marketplace Settings')}
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                {t("Optimize your organization’s profile to attract clients and showcase your firm's strengths.")}
              </p>
            </div>
            <div className="md:col-span-2 space-y-6">
              {/* Firm Logo Upload */}
              <SingleFileInput
                label={t('Firm Logo')}
                accept="image/*"
                file={firmLogoFile}
                setFile={handleFirmLogoUpload}
                existingFileUrl={organizationSettings.Logo}
                desc="Upload or update your firm's official logo."
                subMsg='A recognizable logo helps establish your brand identity and build trust with potential clients.'
              />

              {/* Save Firm Logo Button */}
              {firmLogoFile && (
                <div className="mt-4">
                  <Button variant="primary" onClick={handleSaveFiles}>
                    {t('Save Firm Logo')}
                  </Button>
                </div>
              )}

              {/* Marketplace Active Toggle */}
              <div className="flex flex-col mt-4">
                <div>
                <label
                  htmlFor="is_marketplace_active_org"
                  className="mr-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  {t('Organization Marketplace Active')}
                </label>
                <div className='flex flex-col space-y-1 mb-2'>
                <p className="text-xs text-gray-500 dark:text-gray-300 text-left italic">{t("Manage your firm's visibility on the marketplace.")}</p>
            <p className="text-sm text-gray-700 dark:text-gray-200 text-left">{t('Toggle the status to display or hide your organization’s profile based on availability and operational needs.')}</p>
            </div>
            </div>
                <ToggleSwitch
                  id="is_marketplace_active_org"
                  name="is_marketplace_active_org"
                  checked={formik.values.is_marketplace_active_org}
                  onChange={(checked) => handleToggleMarketplaceActive(checked, 'organization')}
                />
              </div>

              {/* Form for Organization Settings */}
              <form onSubmit={formik.handleSubmit} className="space-y-6 mt-6">
                {/* Organization Name */}
                <Input
                  label={t('Organization Name')}
                  id="OrganizationName"
                  name="OrganizationName"
                  type="text"
                  value={formik.values.OrganizationName}
                  onChange={handleInputChange}
                  italicTxt='Set or update the official name of your organization.'
                  description="Ensure the name reflects your firm's identity and is consistent across all client interactions."
                />

                {/* Description */}
                <Textarea
                  label={t('Description')}
                  id="Description"
                  italicTxt='Provide a detailed overview of your firm’s mission, values, and services.'
                  name="Description"
                  value={formik.values.Description}
                  onChange={handleInputChange}
                  description='Use this section to convey what makes your firm unique and why clients should choose you.'
                />

                {/* Practice Area */}
                <Dropdown
                  label={t('Practice Area')}
                  id="PracticeArea"
                  options={[
                    { value: 'Corporate Law', label: t('Corporate Law') },
                    { value: 'Tax Law', label: t('Tax Law') },
                    // Add more options as needed
                  ]}
                  value={formik.values.PracticeArea}
                  onChange={(values) => handleSpecializationsChange(values, 'PracticeArea')}
                  multiple
                  italicTxt='Specify the legal domains your organization specializes in.'
                  description='This helps potential clients quickly identify your areas of expertise.'
                />

                {/* Save Button */}
                <div className="mt-8 flex gap-x-4">
                  <Button variant="primary" type="submit" disabled={!hasUnsavedChanges}>
                    {t('Save')}
                  </Button>
                  {hasUnsavedChanges && (
                    <Button
                      variant="destructive"
                      onClick={() => {
                        formik.resetForm();
                        setHasUnsavedChanges(false);
                      }}
                    >
                      {t('Cancel')}
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MarketplaceSettings;
