import React, { useEffect, useState } from 'react';
import Input from '../shared/TailwindComponents/Input';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { RootState, useAppDispatch } from '../../store';
import { createCase } from '../../store/actions/marketplace2/marketplaceActions';
import { fetchCases } from '../../store/actions/marketplace2/caseActions';
import { fetchAllClients } from '../../store/actions/ClientAction';
import { useSelector } from 'react-redux';
import { fetchCaseTypes } from '../../store/actions/DropdownActions';
import { showAddClientSidePanel } from '../../store/actions/sidePanelActions';
import { getUserType } from '../../utils/authUtils';
import { usePermission } from '../../hooks/userPermissions';
import { useTranslation } from 'react-i18next';
import TextOrFileInput from '../shared/TailwindComponents/TextOrFileInput';
import Textarea from '../shared/TailwindComponents/Textarea';
import PlusIcon from '../shared/icons/PlusIcon';
import CurrencyInput from '../shared/TailwindComponents/CurrencyInput';
import { TrashIcon } from '@heroicons/react/24/solid';

interface AddCaseFormProps {
  clientId?: string;
  clientUserType?: string;
  onClose: () => void;
  setIsAddingCase: (isAddingCase: boolean) => void;
}

const AddCaseForm: React.FC<AddCaseFormProps> = ({
  clientId,
  clientUserType,
  onClose,
  setIsAddingCase,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({
    CaseType: '',
    issue_description: { text: '', file: null },
    events: { text: '', file: null },
    timeline: { text: '', file: null },
    witness_statements: { text: '', file: null },
    communications: { text: '', file: null },
    evidence: { text: '', file: null },
    // Business-specific fields
    company_size: '',
    industry: '',
    internal_policies: { text: '', file: null },
    stakeholder_info: { text: '', file: null },
    financial_implications: { text: '', file: null },
    third_party_relationships: { text: '', file: null },
    // Custom fields
    custom_fields: [],
    // Financial Data fields directly in formData
    AgreedFee: '',
    BillingMethod: '',
    HourlyRate: '',
    PaymentTerms: '',
    RetainerAmount: '',
    Currency: '',
    TaxRate: '',
    Discounts: '',
    AdditionalFees: '',
    FinancialNotes: '',
    // Custom financial fields
    custom_financial_fields: [],
  });
  const clients = useSelector((state: RootState) => state.client.allClients);
  const [selectedClientID, setSelectedClientID] = useState(clientId || '');
  const [selectedClientUserType, setSelectedClientUserType] = useState(
    clientUserType || ''
  );
  const caseTypeOptions = useSelector((state: RootState) => state.dropdown.caseTypes);
  const userType = getUserType();
  const [clientTypeSelection, setClientTypeSelection] = useState<
    'IndividualClient' | 'BusinessAdmin' | 'BusinessEmployee'
  >();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const { hasAccess, isReadOnly } = usePermission('case_management');
  const [showFinancialFields, setShowFinancialFields] = useState(false);

  useEffect(() => {
    if (clients.length === 0 && ['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType)) {
      dispatch(fetchAllClients());
    }
    dispatch(fetchCaseTypes());
  }, [dispatch, clients.length]);

  useEffect(() => {
    if (clientId) {
      setSelectedClientID(clientId);
    }
    if (clientUserType) {
      setSelectedClientUserType(clientUserType);
    }
  }, [clientId, clientUserType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (
      [
        'issue_description',
        'events',
        'timeline',
        'witness_statements',
        'communications',
        'evidence',
        'internal_policies',
        'stakeholder_info',
        'financial_implications',
        'third_party_relationships',
      ].includes(name)
    ) {
      setFormData((prev: any) => ({
        ...prev,
        [name]: {
          ...prev[name],
          text: value,
        },
      }));
    } else {
      setFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (file: File | null, fieldName: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        file: file,
      },
    }));
  };

  const handleSelect = (option: DropdownOption) => {
    setFormData((prev: any) => ({
      ...prev,
      CaseType: option.value,
    }));
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.CaseType) newErrors.CaseType = 'Case type is required';
    if (
      !formData.issue_description.text &&
      !formData.issue_description.file
    ) {
      newErrors.issue_description = 'Issue description is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddClientClick = () => {
    dispatch(showAddClientSidePanel());
  };

  const handleAddCustomField = () => {
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: [...prev.custom_fields, { label: '', text: '', file: null }],
    }));
  };

  const handleCustomFieldChange = (index: number, field: string, value: any) => {
    const updatedCustomFields = [...formData.custom_fields];
    updatedCustomFields[index] = {
      ...updatedCustomFields[index],
      [field]: value,
    };
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: updatedCustomFields,
    }));
  };

  const handleRemoveCustomField = (index: number) => {
    const updatedCustomFields = [...formData.custom_fields];
    updatedCustomFields.splice(index, 1);
    setFormData((prev: any) => ({
      ...prev,
      custom_fields: updatedCustomFields,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Prepare FormData for file uploads
    const formPayload = new FormData();

    // Fields to process
    const fieldsToProcess = [
      'issue_description',
      'events',
      'timeline',
      'witness_statements',
      'communications',
      'evidence',
      'internal_policies',
      'stakeholder_info',
      'financial_implications',
      'third_party_relationships',
    ];

    // Append fields to FormData
    fieldsToProcess.forEach((field) => {
      if (formData[field]) {
        if (formData[field].text) {
          formPayload.append(field, formData[field].text);
        }
        if (formData[field].file) {
          formPayload.append(field, formData[field].file);
        }
      }
    });

    // Append custom fields directly with their labels
    formData.custom_fields.forEach((field: any, index: number) => {
      const label = field.label;
      if (label) {
        if (field.text) {
          formPayload.append(label, field.text);
        }
        if (field.file) {
          formPayload.append(label, field.file);
        }
      }
    });

    // Append financial fields directly
    const financialFields = [
      'AgreedFee',
      'BillingMethod',
      'HourlyRate',
      'PaymentTerms',
      'RetainerAmount',
      'Currency',
      'TaxRate',
      'Discounts',
      'AdditionalFees',
      'FinancialNotes',
    ];
    financialFields.forEach((field) => {
      if (formData[field]) {
        formPayload.append(field, formData[field]);
      }
    });

    // Append custom financial fields with 'financial_' prefix
    formData.custom_financial_fields.forEach((field: any) => {
      const label = field.label;
      if (label && field.value) {
        formPayload.append(`financial_${label}`, field.value);
      }
    });

    // Append other fields
    formPayload.append('CaseType', formData.CaseType);
    formPayload.append('ClientID', selectedClientID || clientId || '');
    formPayload.append(
      'ClientUserType',
      selectedClientUserType || clientUserType || clientTypeSelection || userType
    );
    formPayload.append('IsPublishedToMarketplace', 'false');

    // Append any other fields (like company_size, industry)
    ['company_size', 'industry'].forEach((field) => {
      if (formData[field]) {
        formPayload.append(field, formData[field]);
      }
    });

    try {
      await dispatch(createCase(formPayload));
      dispatch(fetchCases());
      setIsAddingCase(false);
    } catch (error) {
      console.error('Error creating case:', error);
    }
  };

  const handleAddCustomFinancialField = () => {
    setFormData((prev: any) => ({
      ...prev,
      custom_financial_fields: [...prev.custom_financial_fields, { label: '', value: '' }],
    }));
  };

  const handleCustomFinancialFieldChange = (index: number, field: string, value: any) => {
    const updatedCustomFields = [...formData.custom_financial_fields];
    updatedCustomFields[index] = {
      ...updatedCustomFields[index],
      [field]: value,
    };
    setFormData((prev: any) => ({
      ...prev,
      custom_financial_fields: updatedCustomFields,
    }));
  };

  const handleRemoveCustomFinancialField = (index: number) => {
    const updatedCustomFields = [...formData.custom_financial_fields];
    updatedCustomFields.splice(index, 1);
    setFormData((prev: any) => ({
      ...prev,
      custom_financial_fields: updatedCustomFields,
    }));
  };



  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">{t('Add New Case')}</h2>
      <form onSubmit={handleSubmit}>
        {/* Client Selection for Lawyers */}
        {['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && (
          <div className="mb-4 flex items-end space-x-4">
            <div className="w-full">
              <Dropdown
                label={t('Select Client')}
                options={clients.map((client) => ({
                  value: client.ClientID || 'unknown',
                  label:
                    client.BusinessName ||
                    `${client.FirstName} ${client.LastName}`.trim() ||
                    client.Email ||
                    'Unknown Client',
                }))}
                placeholder={t('Select a client')}
                onSelect={(option) => {
                  const selectedClient = clients.find((c) => c.ClientID === option.value);
                  setSelectedClientID(option.value);
                  if (selectedClient) {
                    setSelectedClientUserType(selectedClient.UserTypeName);
                  }
                }}
                value={selectedClientID}
                error={errors.selectedClientID}
              />
            </div>
            <Button variant="neutral" onClick={handleAddClientClick}>
              <PlusIcon />
            </Button>
          </div>
        )}
        {(!selectedClientID && ['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType)) && (
          <div className="mb-4">
            <div className="flex items-center space-x-4">
              <span className="text-sm font-medium text-gray-700">{t('Select Client Type')}:</span>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="clientType"
                  value="IndividualClient"
                  checked={clientTypeSelection === 'IndividualClient'}
                  onChange={(e) => setClientTypeSelection(e.target.value as any)}
                />
                <span className="ml-2">{t('Individual Client')}</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio"
                  name="clientType"
                  value="BusinessAdmin"
                  checked={clientTypeSelection === 'BusinessAdmin'}
                  onChange={(e) => setClientTypeSelection(e.target.value as any)}
                />
                <span className="ml-2">{t('Business')}</span>
              </label>
            </div>
            <hr className="my-4" />
          </div>
        )}

        {/* Case Type */}
        <div className="mb-4">
          <Dropdown
            label={t('Case Type')}
            options={caseTypeOptions}
            placeholder={t('Select case type')}
            onSelect={handleSelect}
            value={formData.CaseType}
            error={errors.CaseType}
          />
        </div>

        {/* Issue Description */}
        <TextOrFileInput
          id="issue_description"
          label={t('Issue Description')}
          description={t('Provide a detailed description of the issue.')}
          value={formData.issue_description.text}
          file={formData.issue_description.file}
          onTextChange={(e) =>
            handleChange({
              target: { name: 'issue_description', value: e.target.value },
            } as any)
          }
          onFileChange={(file) => handleFileChange(file, 'issue_description')}
          error={errors.issue_description}
        />

        {/* Conditional Form Fields Based on User Type */}
        {(() => {
          const currentType = selectedClientUserType || clientTypeSelection || userType;
          if (['BusinessAdmin', 'BusinessEmployee'].includes(currentType)) {
            // Business Form Fields
            return (
              <>
                {/* Company Size */}
                <div className="mb-4">
                  <Input
                    id="company_size"
                    name="company_size"
                    label={t('Company Size')}
                    placeholder={t('Enter company size...')}
                    value={formData.company_size}
                    onChange={handleChange}
                    type="text"
                    description={t('Specify the size of the company.')}
                  />
                </div>
                {/* Industry */}
                <div className="mb-4">
                  <Input
                    id="industry"
                    name="industry"
                    label={t('Industry')}
                    placeholder={t('Enter industry...')}
                    value={formData.industry}
                    onChange={handleChange}
                    type="text"
                    description={t('Specify the industry sector.')}
                  />
                </div>
                {/* Internal Policies */}
                <TextOrFileInput
                  id="internal_policies"
                  label={t('Internal Policies')}
                  description={t('Provide details about internal policies relevant to the case.')}
                  value={formData.internal_policies.text}
                  file={formData.internal_policies.file}
                  onTextChange={(e) =>
                    handleChange({
                      target: { name: 'internal_policies', value: e.target.value },
                    } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'internal_policies')}
                />
                {/* Stakeholder Info */}
                <TextOrFileInput
                  id="stakeholder_info"
                  label={t('Stakeholder Information')}
                  description={t('Provide information about stakeholders involved.')}
                  value={formData.stakeholder_info.text}
                  file={formData.stakeholder_info.file}
                  onTextChange={(e) =>
                    handleChange({
                      target: { name: 'stakeholder_info', value: e.target.value },
                    } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'stakeholder_info')}
                />
                {/* Financial Implications */}
                <TextOrFileInput
                  id="financial_implications"
                  label={t('Financial Implications')}
                  description={t('Explain any financial impacts related to the case.')}
                  value={formData.financial_implications.text}
                  file={formData.financial_implications.file}
                  onTextChange={(e) =>
                    handleChange({
                      target: { name: 'financial_implications', value: e.target.value },
                    } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'financial_implications')}
                />
                {/* Third Party Relationships */}
                <TextOrFileInput
                  id="third_party_relationships"
                  label={t('Third-Party Relationships')}
                  description={t('Provide information about third parties involved.')}
                  value={formData.third_party_relationships.text}
                  file={formData.third_party_relationships.file}
                  onTextChange={(e) =>
                    handleChange({
                      target: { name: 'third_party_relationships', value: e.target.value },
                    } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'third_party_relationships')}
                />
              </>
            );
          } else {
            // Individual Client Form Fields
            return (
              <>
                {/* Events */}
                <TextOrFileInput
                  id="events"
                  label={t('Events')}
                  description={t('Describe the events related to the case.')}
                  value={formData.events.text}
                  file={formData.events.file}
                  onTextChange={(e) =>
                    handleChange({ target: { name: 'events', value: e.target.value } } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'events')}
                />
                {/* Timeline */}
                <TextOrFileInput
                  id="timeline"
                  label={t('Timeline')}
                  description={t('Provide a timeline of the events.')}
                  value={formData.timeline.text}
                  file={formData.timeline.file}
                  onTextChange={(e) =>
                    handleChange({ target: { name: 'timeline', value: e.target.value } } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'timeline')}
                />
                {/* Witness Statements */}
                <TextOrFileInput
                  id="witness_statements"
                  label={t('Witness Statements')}
                  description={t('Include any witness statements.')}
                  value={formData.witness_statements.text}
                  file={formData.witness_statements.file}
                  onTextChange={(e) =>
                    handleChange({
                      target: { name: 'witness_statements', value: e.target.value },
                    } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'witness_statements')}
                />
                {/* Communications */}
                <TextOrFileInput
                  id="communications"
                  label={t('Communications')}
                  description={t('Provide relevant communications.')}
                  value={formData.communications.text}
                  file={formData.communications.file}
                  onTextChange={(e) =>
                    handleChange({ target: { name: 'communications', value: e.target.value } } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'communications')}
                />
                {/* Evidence */}
                <TextOrFileInput
                  id="evidence"
                  label={t('Evidence')}
                  description={t('Provide any evidence related to the case.')}
                  value={formData.evidence.text}
                  file={formData.evidence.file}
                  onTextChange={(e) =>
                    handleChange({ target: { name: 'evidence', value: e.target.value } } as any)
                  }
                  onFileChange={(file) => handleFileChange(file, 'evidence')}
                />
              </>
            );
          }
        })()}

        {/* Custom Fields */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">{t('Custom Fields')}</label>
          <p className="text-sm text-gray-500 mb-2">
            {t('Add any additional information with custom labels.')}
          </p>
          {formData.custom_fields.map((field: any, index: number) => (
            <div key={index} className="mb-4 relative">
              {/* Remove Custom Field Button */}
              <button
                type="button"
                className="absolute top-0 right-0 text-red-500 hover:text-red-700"
                onClick={() => handleRemoveCustomField(index)}
              >
                <TrashIcon className="h-5 w-5" />
              </button>

              <Input
                name={`custom_field_label_${index}`}
                placeholder={t('Label')}
                value={field.label}
                onChange={(e) => handleCustomFieldChange(index, 'label', e.target.value)}
                type="text"
              />
              <TextOrFileInput
                id={`custom_field_value_${index}`}
                label={field.label || t('Value')}
                value={field.text}
                file={field.file}
                onTextChange={(e) => handleCustomFieldChange(index, 'text', e.target.value)}
                onFileChange={(file) => handleCustomFieldChange(index, 'file', file)}
              />
            </div>
          ))}

          <Button variant="secondary" onClick={handleAddCustomField}>
            {t('Add Custom Field')}
          </Button>
        </div>

        {/* Show Financial Data Button for Lawyers */}
        {['IndependentLawyer', 'LawFirmAdmin', 'LawFirmEmployee'].includes(userType) && (
          <div className="mb-4">
            <Button
              variant="success"
              onClick={() => setShowFinancialFields(!showFinancialFields)}
            >
              {showFinancialFields ? t('Hide Financial Data') : t('Add Financial Data')}
            </Button>
          </div>
        )}

        {/* Financial Data Fields */}
        {showFinancialFields && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">{t('Financial Data')}</h3>
            {/* Agreed Fee */}
            <div className="mb-4">
            <CurrencyInput
              id="AgreedFee"
              name="AgreedFee"
              label={t('Agreed Fee')}
              placeholder={t('Enter agreed fee...')}
              value={formData.AgreedFee}
              onChange={handleChange}
              currencySymbol={formData.Currency || '$'}
            />
          </div>
            {/* Billing Method */}
            <div className="mb-4">
              <Dropdown
                label={t('Billing Method')}
                options={[
                  { value: 'Hourly', label: t('Hourly') },
                  { value: 'Fixed', label: t('Fixed') },
                  // Add more billing methods if needed
                ]}
                placeholder={t('Select billing method')}
                onSelect={(option) =>
                  setFormData((prev: any) => ({
                    ...prev,
                    FinancialData: {
                      ...prev.FinancialData,
                      BillingMethod: option.value,
                    },
                  }))
                }
                value={formData.BillingMethod}
              />
            </div>
            {/* Hourly Rate */}
            {formData.BillingMethod === 'Hourly' && (
              <div className="mb-4">
                <CurrencyInput
                  id="HourlyRate"
                  name="HourlyRate"
                  label={t('Hourly Rate')}
                  placeholder={t('Enter hourly rate...')}
                  value={formData.HourlyRate}
                  onChange={handleChange}
                  currencySymbol={formData.Currency || '$'}
                />
              </div>
            )}
            {/* Payment Terms */}
            <div className="mb-4">
              <Textarea
                id="PaymentTerms"
                name="PaymentTerms"
                label={t('Payment Terms')}
                placeholder={t('Enter payment terms...')}
                value={formData.PaymentTerms}
                onChange={handleChange}
              />
            </div>
            {/* Retainer Amount */}
            <div className="mb-4">
              <CurrencyInput
                id="RetainerAmount"
                name="RetainerAmount"
                label={t('Retainer Amount')}
                placeholder={t('Enter retainer amount...')}
                value={formData.RetainerAmount}
                onChange={handleChange}
                currencySymbol={formData.Currency || '$'}
              />
            </div>
            {/* Currency */}
            <div className="mb-4">
              <Input
                id="Currency"
                name="Currency"
                label={t('Currency')}
                placeholder={t('Enter currency symbol (e.g., $)...')}
                value={formData.Currency}
                onChange={handleChange}
                type="text"
              />
            </div>
            {/* Tax Rate */}
            <div className="mb-4">
              <Input
                id="TaxRate"
                name="TaxRate"
                label={t('Tax Rate (%)')}
                placeholder={t('Enter tax rate...')}
                value={formData.TaxRate}
                onChange={handleChange}
                type="number"
              />
            </div>
            {/* Discounts */}
            <div className="mb-4">
              <CurrencyInput
                id="Discounts"
                name="Discounts"
                label={t('Discounts')}
                placeholder={t('Enter discounts...')}
                value={formData.Discounts}
                onChange={handleChange}
                currencySymbol={formData.Currency || '$'}
              />
            </div>
            {/* Additional Fees */}
            <div className="mb-4">
              <CurrencyInput
                id="AdditionalFees"
                name="AdditionalFees"
                label={t('Additional Fees')}
                placeholder={t('Enter additional fees...')}
                value={formData.AdditionalFees}
                onChange={handleChange}
                currencySymbol={formData.Currency || '$'}
              />
            </div>
            {/* Financial Notes */}
            <div className="mb-4">
              <Textarea
                id="FinancialNotes"
                name="FinancialNotes"
                label={t('Financial Notes')}
                placeholder={t('Enter financial notes...')}
                value={formData.FinancialNotes}
                onChange={handleChange}
              />
            </div>
          {/* Custom Financial Fields */}
            <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('Custom Financial Fields')}
            </label>
            <p className="text-sm text-gray-500 mb-2">
              {t('Add any additional financial information with custom labels.')}
            </p>
            {formData.custom_financial_fields.map((field: any, index: number) => (
              <div key={index} className="mb-4 relative">
                {/* Remove Custom Field Button */}
                <button
                  type="button"
                  className="absolute top-0 right-0 text-red-500 hover:text-red-700"
                  onClick={() => handleRemoveCustomFinancialField(index)}
                >
                  <TrashIcon className="h-5 w-5" />
                </button>

                <Input
                  name={`custom_financial_field_label_${index}`}
                  placeholder={t('Label')}
                  value={field.label}
                  onChange={(e) => handleCustomFinancialFieldChange(index, 'label', e.target.value)}
                  type="text"
                />
                <Input
                  name={`custom_financial_field_value_${index}`}
                  placeholder={t('Value')}
                  value={field.value}
                  onChange={(e) => handleCustomFinancialFieldChange(index, 'value', e.target.value)}
                  type="text"
                />
              </div>
            ))}

            <Button variant="secondary" onClick={handleAddCustomFinancialField}>
              {t('Add Custom Financial Field')}
            </Button>
          </div>
        </div>
      )}

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button disabled={isReadOnly} type="submit" variant="primary">
            {t('Create Case')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddCaseForm;
