import axios, { AxiosError } from "axios";
import apis from "../../utils/apis";
import { showFeedbackModal } from "./UserFeedbackActions";
import { ErrorResponse } from "./SignInAction";
import { START_TIMER, STOP_TIMER, PAUSE_TIMER, RESUME_TIMER, LOG_TIME_ENTRY, SET_TIME_ENTRIES, EDIT_TIME_ENTRY, DELETE_TIME_ENTRY, SET_TIMER_RUNNING_STATE, SET_PAUSED_STATE, SET_TIMER } from "../types";

const prepareData = (case_id: string | null, task_id: string | null) => ({
  case_id: case_id || "",
  task_id: task_id || ""
});

export const startTimerApi = (case_id: string | null, task_id: string | null) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/start_timer",
        data: {
          CaseID: case_id || "",
          TaskID: task_id || "",
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: START_TIMER });
        dispatch(setTimerRunningState(true));
        dispatch(setPausedState(false));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer started successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return resp.data.TimeEntry;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
    }
  };
};

export const stopTimerApi = (entry_id: number,description: string = "") => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/stop_timer",
        data: {
          time_entry_id: entry_id,
          description: description,
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: STOP_TIMER });
        dispatch(setTimerRunningState(false));
        dispatch(setPausedState(false));
        dispatch(setTimer(0));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer stopped successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        // Optionally handle the 'duration' field from the response
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
    }
  };
};


export const pauseTimerApi = (entry_id: number) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/pause_timer",
        data: {
          time_entry_id: entry_id
        }
      });

      if (resp && resp.status === 200) {
        dispatch({ type: PAUSE_TIMER });
        dispatch(setTimerRunningState(false));
        dispatch(setPausedState(true));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer paused successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
    }
  };
};

export const resumeTimerApi = (entry_id: number) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/resume_timer",
        data: {
          time_entry_id: entry_id
        }
      });

      if (resp && resp.status === 200) {
        dispatch({ type: RESUME_TIMER });
        dispatch(setTimerRunningState(true));
        dispatch(setPausedState(false));
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Timer resumed successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
    }
  };
};

export const setTimerRunningState = (isRunning: boolean) => {
  return {
    type: SET_TIMER_RUNNING_STATE,
    payload: isRunning,
  };
};

export const setTimer = (time: number) => {
  return {
    type: SET_TIMER,
    payload: time,
  };
};

export const setPausedState = (isPaused: boolean) => {
  return {
    type: SET_PAUSED_STATE,
    payload: isPaused,
  };
};

export const logTimeEntryApi = (timeEntryData: {
  CaseID: string;
  TaskID: string;
  StartTime: string;
  EndTime: string;
  Description: string;
}) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/log_time_entry",
        data: timeEntryData,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: LOG_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry logged successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
    }
  };
};


export const getTimeEntriesApi = (case_id?: string, task_id?: string) => {
  return async (dispatch: any) => {
    try {
      const params: any = {};
      if (case_id) params.CaseID = case_id;
      if (task_id) params.TaskID = task_id;

      const resp: any = await apis({
        method: "GET",
        url: "/timekeeping/get_time_entries",
        params: params,
      });

      if (resp && resp.status === 200) {
        dispatch({ type: SET_TIME_ENTRIES, payload: resp.data.time_entries });
        return resp.data.time_entries;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
      return [];
    }
  };
};

export const editTimeEntryApi = (entry_id: string, updates: any) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "PUT",
        url: "/timekeeping/edit_time_entry",
        data: {
          EntryID: entry_id,
          Updates: updates,
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: EDIT_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry edited successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
    }
  };
};

export const deleteTimeEntryApi = (entry_id: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "DELETE",
        url: "/timekeeping/delete_time_entry",
        data: {
          EntryID: entry_id,
        },
      });

      if (resp && resp.status === 200) {
        dispatch({ type: DELETE_TIME_ENTRY });
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: resp.data.message || "Time entry deleted successfully!",
            modalType: "success",
            duration: 3000,
          })
        );
        return true;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
    }
  };
};

const handleError = (dispatch: any, error: any) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.data) {
      const errorData = axiosError.response.data as ErrorResponse;
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: errorData.message || errorData.error || "An unknown error occurred",
          modalType: "error",
          duration: 3000,
        })
      );
    } else {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: "Network error, please try again",
          modalType: "error",
          duration: 3000,
        })
      );
    }
  } else {
    dispatch(
      showFeedbackModal({
        showModal: true,
        message: "An unexpected error occurred",
        modalType: "error",
        duration: 3000,
      })
    );
  }
};


export const fetchTimeData = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/fetchTimeData",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: resp.data.message || "List fetched successfully",
        //     modalType: "success",
        //     duration: 3000,
        //   })
        // );
        return resp.data;
      } else {
        handleError(dispatch, resp);
      }
    } catch (err) {
      console.error(err);
    }
  };
};


export const fetchDayWeekData = (
  mode: string,
  date: string,
  from_date: string,
  to_date: string,
  user_id?: string
) => {
  return async (dispatch: any) => {
    try {
      const data: any = {
        mode,
        date,
        from_date,
        to_date,
      };
      if (user_id) {
        data.user_id = user_id;
      }
      const resp: any = await apis({
        method: 'POST',
        url: '/timekeeping/fetch_day_week_data',
        data,
      });

      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        handleError(dispatch, resp);
        return null;
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
      return null;
    }
  };
};


export const fetchChartsData = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/fetchChartData",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // dispatch(
        //   showFeedbackModal({
        //     showModal: true,
        //     message: resp.data.message || "List fetched successfully",
        //     modalType: "success",
        //     duration: 3000,
        //   })
        // );
        return resp.data;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const getTimeDataByModeApi = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/get_time_data_by_mode",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // Process the data as needed
        return resp.data.data;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
      return [];
    }
  };
};

export const getEmployeeTimeDataByMode = (mode: string) => {
  return async (dispatch: any) => {
    try {
      const resp: any = await apis({
        method: "POST",
        url: "/timekeeping/get_employee_time_data_by_mode",
        data: { mode },
      });

      if (resp && resp.status === 200) {
        // Process the results as needed
        return resp.data.results;
      } else {
        handleError(dispatch, resp);
        return [];
      }
    } catch (err) {
      console.error(err);
      handleError(dispatch, err);
      return [];
    }
  };
};

