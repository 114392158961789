import axios, { AxiosError } from "axios"
import apis from "../../utils/apis"
import { ErrorResponse } from "./SignInAction"
import { showFeedbackModal } from "./UserFeedbackActions"
import { hideModal, showModal } from "./modalActions"
import { SET_SESSIONS } from "../types"
import { userInformation } from "./DataAction"


export const setSessions = (sessions: any[] | []) => {
    return {
      type: SET_SESSIONS,
      sessions: sessions
    };
  };
  
export const generateCode = (method: string,t: any) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/generateCode",
                data: {
                    method
                }
            })
            if (response && response.status === 200) {
                
                dispatch(showModal({
                    showModal: true,
                    type: 'otp-verification',
                    message: '',
                    onConfirm: async (verificationCode: File | string) => {
                        await dispatch(VerifyCode(response.data.verification_token,verificationCode as string))
                        dispatch(userInformation());
                    },
                    onCancel: () => hideModal(),
                    inputData: method
                }));

                dispatch(showFeedbackModal({
                    showModal: true,
                    message: (response.data.message),
                    modalType: 'success',
                    duration: 3000
                }));
                return true

            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}


export const VerifyCode = (Token: string,Code: string) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/email/verifyCode",
                data: {
                    Token,
                    Code
                }
            })
            if (response && response.status === 200) {
                dispatch(hideModal())
                dispatch(showFeedbackModal({
                    showModal: true,
                    message: response.data.message,
                    modalType: 'success',
                    duration: 3000
                }));
            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}

export const getActiveSessions = () => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'GET',
                url: "/security/fetch_all_sessions",
            })
            if (response && response.status === 200) {
                dispatch(setSessions(response.data.session_list))
            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}

export const clearActiveSession = (sessionId: string) => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/logout_session",
                data: { session_id: sessionId }
            })
            if (response && response.status === 200) {
                dispatch(getActiveSessions())
                return response.data;
            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}

export const clearAllActiveSession = () => {
    return async (dispatch: any) => {
        try {

            const response = await apis({
                method: 'POST',
                url: "security/logout_all_sessions"
            })
            if (response && response.status === 200) {
                dispatch(getActiveSessions())
                return response.data;
            } else {
                if (axios.isAxiosError(response)) {
                    const axiosError = response as AxiosError;
                    if (axiosError.response && axiosError.response.data) {
                        let errorMessage = axiosError.response.data as ErrorResponse
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: (errorMessage.message || 'An unknown error occurred'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    } else {
                        dispatch(showFeedbackModal({
                            showModal: true,
                            message: ('Network error, please try again'),
                            modalType: 'error',
                            duration: 3000
                        }));
                    }
                }
            }

        } catch (err) {
            console.error(err)
        }
    }
}