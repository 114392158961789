import React from 'react';
import clsx from 'clsx';
import Button from './Button';

interface TableProps {
  columns: string[];
  data: Record<string, any>[];
  hiddenColumns?: Set<string>;
  selectFunction?: (row: any) => void;
  selectLabel?: string; 
  fixedHeader?: boolean;
  disabled?: boolean;
}

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const Table: React.FC<TableProps> = ({
  columns,
  data,
  hiddenColumns = new Set(),
  selectFunction,
  selectLabel = 'Select',
  fixedHeader = false,
  disabled = false,
}) => {
  return (
    <div
      className={clsx(
        'flex-1 overflow-y-auto -mx-4 mt-4 ring-1 ring-gray-300 dark:ring-gray-700 sm:mx-0 sm:rounded-lg',
        fixedHeader ? '' : ''
      )}
    >
      <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
        <thead
          className={clsx(
            'bg-gray-50 dark:bg-gray-800',
            fixedHeader ? 'sticky top-0' : ''
          )}
        >
          <tr>
            {columns.map((col, colIdx) => (
              <th
                key={col}
                scope="col"
                className={classNames(
                  'py-3.5 px-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200',
                  hiddenColumns.has(col) ? 'hidden lg:table-cell' : '',
                  colIdx === 0 ? 'pl-4 sm:pl-6' : '',
                  colIdx === columns.length - 1 ? 'pr-4 sm:pr-6' : ''
                )}
              >
                {col}
              </th>
            ))}
            {/* Action Column */}
            {selectFunction && (
              <th
                scope="col"
                className="relative py-3.5 px-3 sm:pr-6 text-left"
              >
                <span className="sr-only">{selectLabel}</span>
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-gray-900">
          {data.map((row, rowIdx) => (
            <tr
              key={row.id || rowIdx}
              className={clsx(
                rowIdx % 2 === 0 ? undefined : 'bg-gray-50 dark:bg-gray-800',
                row.onClick ? 'cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700' : ''
              )}
              onClick={row.onClick}
            >
              {columns.map((col, colIdx) => (
                <td
                  key={col}
                  className={classNames(
                    'py-4 px-3 text-sm text-gray-900 dark:text-gray-100',
                    hiddenColumns.has(col) ? 'hidden lg:table-cell' : '',
                    colIdx === 0 ? 'pl-4 sm:pl-6' : '',
                    colIdx === columns.length - 1 ? 'pr-4 sm:pr-6' : ''
                  )}
                >
                  {row[col]}
                </td>
              ))}
              {/* Action Cell */}
              {selectFunction && (
                <td
                  className={classNames(
                    'py-4 px-3 text-right text-sm font-medium sm:pr-6',
                    rowIdx !== 0 ? 'border-t border-transparent' : ''
                  )}
                >
                  <Button
                    variant="neutral"
                    disabled={disabled}
                    onClick={() => selectFunction(row)}
                  >
                    {selectLabel}
                    <span className="sr-only">, {row[columns[0]]}</span>
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
