export const GET_PUBLISHED_CASES_REQUEST = 'GET_PUBLISHED_CASES_REQUEST';
export const GET_PUBLISHED_CASES_SUCCESS = 'GET_PUBLISHED_CASES_SUCCESS';
export const GET_PUBLISHED_CASES_FAILURE = 'GET_PUBLISHED_CASES_FAILURE';

export const GET_MY_ACCESS_REQUESTS_REQUEST = 'GET_MY_ACCESS_REQUESTS_REQUEST';
export const GET_MY_ACCESS_REQUESTS_SUCCESS = 'GET_MY_ACCESS_REQUESTS_SUCCESS';
export const GET_MY_ACCESS_REQUESTS_FAILURE = 'GET_MY_ACCESS_REQUESTS_FAILURE';

export const GET_MY_ENGAGEMENTS_REQUEST = 'GET_MY_ENGAGEMENTS_REQUEST';
export const GET_MY_ENGAGEMENTS_SUCCESS = 'GET_MY_ENGAGEMENTS_SUCCESS';
export const GET_MY_ENGAGEMENTS_FAILURE = 'GET_MY_ENGAGEMENTS_FAILURE';

export const REQUEST_CASE_ACCESS_REQUEST = 'REQUEST_CASE_ACCESS_REQUEST';
export const REQUEST_CASE_ACCESS_SUCCESS = 'REQUEST_CASE_ACCESS_SUCCESS';
export const REQUEST_CASE_ACCESS_FAILURE = 'REQUEST_CASE_ACCESS_FAILURE';

export const RESPOND_TO_INVITATION_REQUEST = 'RESPOND_TO_INVITATION_REQUEST';
export const RESPOND_TO_INVITATION_SUCCESS = 'RESPOND_TO_INVITATION_SUCCESS';
export const RESPOND_TO_INVITATION_FAILURE = 'RESPOND_TO_INVITATION_FAILURE';

export const GET_CASE_DETAILS_REQUEST = 'GET_CASE_DETAILS_REQUEST';
export const GET_CASE_DETAILS_SUCCESS = 'GET_CASE_DETAILS_SUCCESS';
export const GET_CASE_DETAILS_FAILURE = 'GET_CASE_DETAILS_FAILURE';

export const PROPOSE_ENGAGEMENT_REQUEST = 'PROPOSE_ENGAGEMENT_REQUEST';
export const PROPOSE_ENGAGEMENT_SUCCESS = 'PROPOSE_ENGAGEMENT_SUCCESS';
export const PROPOSE_ENGAGEMENT_FAILURE = 'PROPOSE_ENGAGEMENT_FAILURE';

export const SET_MARKETPLACE_ACTIVE_REQUEST = 'SET_MARKETPLACE_ACTIVE_REQUEST';
export const SET_MARKETPLACE_ACTIVE_SUCCESS = 'SET_MARKETPLACE_ACTIVE_SUCCESS';
export const SET_MARKETPLACE_ACTIVE_FAILURE = 'SET_MARKETPLACE_ACTIVE_FAILURE';

export const UPLOAD_TOS_REQUEST = 'UPLOAD_TOS_REQUEST';
export const UPLOAD_TOS_SUCCESS = 'UPLOAD_TOS_SUCCESS';
export const UPLOAD_TOS_FAILURE = 'UPLOAD_TOS_FAILURE';

export const UPLOAD_FINAL_DOCUMENT_REQUEST = 'UPLOAD_FINAL_DOCUMENT_REQUEST';
export const UPLOAD_FINAL_DOCUMENT_SUCCESS = 'UPLOAD_FINAL_DOCUMENT_SUCCESS';
export const UPLOAD_FINAL_DOCUMENT_FAILURE = 'UPLOAD_FINAL_DOCUMENT_FAILURE';


export const GET_RELEVANT_CASES_REQUEST = 'GET_RELEVANT_CASES_REQUEST';
export const GET_RELEVANT_CASES_SUCCESS = 'GET_RELEVANT_CASES_SUCCESS';
export const GET_RELEVANT_CASES_FAILURE = 'GET_RELEVANT_CASES_FAILURE';

// Lawyer profile actions
export const GET_LAWYER_PROFILE_REQUEST = 'GET_LAWYER_PROFILE_REQUEST';
export const GET_LAWYER_PROFILE_SUCCESS = 'GET_LAWYER_PROFILE_SUCCESS';
export const GET_LAWYER_PROFILE_FAILURE = 'GET_LAWYER_PROFILE_FAILURE';

export const UPDATE_LAWYER_PROFILE_REQUEST = 'UPDATE_LAWYER_PROFILE_REQUEST';
export const UPDATE_LAWYER_PROFILE_SUCCESS = 'UPDATE_LAWYER_PROFILE_SUCCESS';
export const UPDATE_LAWYER_PROFILE_FAILURE = 'UPDATE_LAWYER_PROFILE_FAILURE';

// Law firm profile actions (for LawFirmAdmin)
export const GET_LAWFIRM_PROFILE_REQUEST = 'GET_LAWFIRM_PROFILE_REQUEST';
export const GET_LAWFIRM_PROFILE_SUCCESS = 'GET_LAWFIRM_PROFILE_SUCCESS';
export const GET_LAWFIRM_PROFILE_FAILURE = 'GET_LAWFIRM_PROFILE_FAILURE';

export const UPDATE_LAWFIRM_PROFILE_REQUEST = 'UPDATE_LAWFIRM_PROFILE_REQUEST';
export const UPDATE_LAWFIRM_PROFILE_SUCCESS = 'UPDATE_LAWFIRM_PROFILE_SUCCESS';
export const UPDATE_LAWFIRM_PROFILE_FAILURE = 'UPDATE_LAWFIRM_PROFILE_FAILURE';
