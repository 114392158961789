import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import {
  fetchTimeData,
  getTimeEntriesApi,
  getTimeDataByModeApi,
  fetchDayWeekData,
} from '../../store/actions/TimeManagementActions';
import Header from '../shared/TailwindComponents/Header';
import FilterBar from '../shared/TailwindComponents/FilterBar';
import SearchBar from '../shared/TailwindComponents/SearchBar';
import Table from '../shared/TailwindComponents/Table';
import Pagination from '../shared/TailwindComponents/Pagination';
import dayjs from 'dayjs';
import { showTimekeepingSidePanel } from '../../store/actions/sidePanelActions';
import { getIsAdmin } from '../../utils/authUtils';

const TimeKeepingLogs = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // State variables
  const [mode, setMode] = useState<'day' | 'week' | 'month'>('day');
  const [timeEntries, setTimeEntries] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const entriesPerPage = 10; // Adjust as needed
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [sortOption, setSortOption] = useState<string>('');
  const isAdmin = getIsAdmin();

  // Fetch time entries based on the selected mode
  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getTimeDataByModeApi(mode));
      setTimeEntries(data);
    };
    fetchData();
  }, [dispatch, mode]);

  // Filter and search logic
  const filteredEntries = timeEntries
    .filter((entry) => {
      // Apply search term
      if (searchTerm) {
        return (
          entry.Description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          entry.TaskTitle?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          entry.CaseType?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      return true;
    })
    .filter((entry) => {
      // Apply selected filters
      let matches = true;
      Object.keys(selectedFilters).forEach((filterKey) => {
        if (selectedFilters[filterKey].length > 0) {
          if (!selectedFilters[filterKey].includes(entry[filterKey])) {
            matches = false;
          }
        }
      });
      return matches;
    })
    .sort((a, b) => {
      // Apply sorting
      if (sortOption === 'DurationAsc') {
        return a.Duration - b.Duration;
      } else if (sortOption === 'DurationDesc') {
        return b.Duration - a.Duration;
      }
      return 0;
    });

    const handleRowClick = async (entry: any) => {
      let date = '';
      let from_date = '';
      let to_date = '';
  
      if (mode === 'day') {
        date = entry.date;
      } else if (mode === 'week') {
        from_date = entry.week_start;
        to_date = dayjs(entry.week_start).add(6, 'day').format('YYYY-MM-DD');
      } else if (mode === 'month') {
        from_date = `${entry.year}-${String(entry.month).padStart(2, '0')}-01`;
        to_date = dayjs(from_date).endOf('month').format('YYYY-MM-DD');
      }
  
      const resp: any = await dispatch(fetchDayWeekData(mode, date, from_date, to_date));
      if (resp) {
        // Prepare stats if needed
        const stats = {
          // Calculate stats based on resp.data
        };
  
        dispatch(showTimekeepingSidePanel(mode, resp.data, false, stats));
      }
    };
  

  // Pagination logic
  const totalPages = Math.ceil(filteredEntries.length / entriesPerPage);
  const displayedEntries = filteredEntries.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  // Columns for the table
  const columns = mode === 'day'
    ? ['Day', 'Date', 'Duration', 'Description']
    : mode === 'week'
      ? ['Week Number', 'Start of Week','End of Week', 'Duration', 'Description', 'User Name']
      : ['Month', 'Year', 'Duration', 'Description', 'User Name'];

  return (
    <div className="container mx-auto p-6">
      {/* Header */}
      <Header
        title={t('Time Keeping Logs')}
        subtitle={t('View and manage your time entries')}
      />

      {/* Mode Selector */}
      <div className="flex space-x-4 my-4">
        {['day', 'week', 'month'].map((period) => (
          <button
            key={period}
            onClick={() => setMode(period as 'day' | 'week' | 'month')}
            className={`px-4 py-2 rounded-full transition ${mode === period
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'
              }`}
          >
            {t(period.charAt(0).toUpperCase() + period.slice(1))}
          </button>
        ))}
      </div>

      {/* Search and Filter */}
      <div className="flex flex-wrap items-center justify-between mb-4">
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder={t('Search time entries...')}
        />
        {/* Implement filters as needed */}
        <FilterBar
          filters={[
            // Define your filter options
            {
              label: 'Status',
              options: [
                { value: 'Completed', label: 'Completed' },
                { value: 'Pending', label: 'Pending' },
              ],
            },
          ]}
          sortOptions={[
            { value: 'DurationAsc', label: t('Duration (Asc)') },
            { value: 'DurationDesc', label: t('Duration (Desc)') },
          ]}
          selectedFilters={selectedFilters}
          onFilterChange={(filterName, selectedValues) => {
            setSelectedFilters({
              ...selectedFilters,
              [filterName]: selectedValues,
            });
          }}
          onSortChange={(sortValue) => {
            setSortOption(sortValue);
          }}
          onRemoveFilter={(filterName, value) => {
            setSelectedFilters((prevFilters) => {
              const updatedFilters = { ...prevFilters };
              updatedFilters[filterName] = updatedFilters[filterName].filter(
                (v) => v !== value
              );
              return updatedFilters;
            });
          }}
        />
      </div>

      {/* Table */}
      <Table
        columns={columns}
        data={displayedEntries.map((entry) => {
          const rowData: any = {};
          if (isAdmin) {
            rowData['Employee Name'] = entry.user_name;
          }
          if (mode === 'day') {
            rowData['Day'] = new Date(entry.date).toLocaleDateString('en-US', { weekday: 'long' });
            rowData['Date'] = new Date(entry.date).toLocaleDateString();
          } else if (mode === 'week') {
            rowData['Week Number'] = entry.week;
            rowData['Start of Week'] = new Date(entry.week_start).toLocaleDateString();
            rowData['End of Week'] = dayjs(entry.week_start).add(6, 'day').format('YYYY-MM-DD');
          } else if (mode === 'month') {
            rowData['Year'] = entry.year;
            rowData['Month'] = new Date(entry.year, entry.month - 1).toLocaleString('default', {
              month: 'long',
            });
          }
          rowData['Duration'] = `${entry.duration} ${t('hours')}`;
          rowData['Description'] = entry.description || t('No description provided');
          rowData['onClick'] = () => handleRowClick(entry);
          return rowData;
        })}
      />

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default TimeKeepingLogs;
