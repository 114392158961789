import {
  REQUEST_CASE_ACCESS_REQUEST,
  REQUEST_CASE_ACCESS_SUCCESS,
  REQUEST_CASE_ACCESS_FAILURE,
  RESPOND_TO_INVITATION_REQUEST,
  RESPOND_TO_INVITATION_SUCCESS,
  RESPOND_TO_INVITATION_FAILURE,
  GET_CASE_DETAILS_REQUEST,
  GET_CASE_DETAILS_SUCCESS,
  GET_CASE_DETAILS_FAILURE,
  PROPOSE_ENGAGEMENT_REQUEST,
  PROPOSE_ENGAGEMENT_SUCCESS,
  PROPOSE_ENGAGEMENT_FAILURE,
  SET_MARKETPLACE_ACTIVE_REQUEST,
  SET_MARKETPLACE_ACTIVE_SUCCESS,
  SET_MARKETPLACE_ACTIVE_FAILURE,
  UPLOAD_TOS_REQUEST,
  UPLOAD_TOS_SUCCESS,
  UPLOAD_TOS_FAILURE,
  UPLOAD_FINAL_DOCUMENT_REQUEST,
  UPLOAD_FINAL_DOCUMENT_SUCCESS,
  UPLOAD_FINAL_DOCUMENT_FAILURE,
  GET_PUBLISHED_CASES_REQUEST,
  GET_PUBLISHED_CASES_SUCCESS,
  GET_PUBLISHED_CASES_FAILURE,
  GET_MY_ACCESS_REQUESTS_REQUEST,
  GET_MY_ACCESS_REQUESTS_SUCCESS,
  GET_MY_ACCESS_REQUESTS_FAILURE,
  GET_MY_ENGAGEMENTS_REQUEST,
  GET_MY_ENGAGEMENTS_SUCCESS,
  GET_MY_ENGAGEMENTS_FAILURE,
  GET_RELEVANT_CASES_SUCCESS,
} from './MarketplaceProviderTypes';

interface MarketplaceProviderState {
  lawFirmProfile: any;
  lawyerProfile: any;
  publishedCases: any[];
  accessRequests: any[];
  engagements: any[];
  relevantCases: any[];
  caseDetails: any | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: MarketplaceProviderState = {
  publishedCases: [],
  lawyerProfile: null,
  accessRequests: [],
  engagements: [],
  relevantCases: [],
  caseDetails: null,
  isLoading: false,
  error: null,
  lawFirmProfile: undefined
};

const marketplaceProviderReducer = (state = initialState, action: any): MarketplaceProviderState => {
  switch (action.type) {
    // Handle request actions
    case REQUEST_CASE_ACCESS_REQUEST:
    case RESPOND_TO_INVITATION_REQUEST:
    case GET_CASE_DETAILS_REQUEST:
    case PROPOSE_ENGAGEMENT_REQUEST:
    case SET_MARKETPLACE_ACTIVE_REQUEST:
    case UPLOAD_TOS_REQUEST:
    case UPLOAD_FINAL_DOCUMENT_REQUEST:
    case GET_PUBLISHED_CASES_REQUEST:
    case GET_MY_ACCESS_REQUESTS_REQUEST:
    case GET_MY_ENGAGEMENTS_REQUEST:
      return { ...state, isLoading: true, error: null };

    // Handle success actions
    case REQUEST_CASE_ACCESS_SUCCESS:
    case RESPOND_TO_INVITATION_SUCCESS:
    case PROPOSE_ENGAGEMENT_SUCCESS:
    case SET_MARKETPLACE_ACTIVE_SUCCESS:
    case UPLOAD_TOS_SUCCESS:
    case UPLOAD_FINAL_DOCUMENT_SUCCESS:
      return { ...state, isLoading: false };

    case GET_CASE_DETAILS_SUCCESS:
      return { ...state, isLoading: false, caseDetails: action.payload };

    case GET_PUBLISHED_CASES_SUCCESS:
      return { ...state, isLoading: false, publishedCases: action.payload };

    case GET_MY_ACCESS_REQUESTS_SUCCESS:
      return { ...state, isLoading: false, accessRequests: action.payload };

    case GET_MY_ENGAGEMENTS_SUCCESS:
      return { ...state, isLoading: false, engagements: action.payload };
    case GET_RELEVANT_CASES_SUCCESS:
      return { ...state, isLoading: false, relevantCases: action.payload };

    // Handle failure actions
    case REQUEST_CASE_ACCESS_FAILURE:
    case RESPOND_TO_INVITATION_FAILURE:
    case GET_CASE_DETAILS_FAILURE:
    case PROPOSE_ENGAGEMENT_FAILURE:
    case SET_MARKETPLACE_ACTIVE_FAILURE:
    case UPLOAD_TOS_FAILURE:
    case UPLOAD_FINAL_DOCUMENT_FAILURE:
    case GET_PUBLISHED_CASES_FAILURE:
    case GET_MY_ACCESS_REQUESTS_FAILURE:
    case GET_MY_ENGAGEMENTS_FAILURE:
      return { ...state, isLoading: false, error: 'An error occurred' };

    default:
      return state;
  }
};

export default marketplaceProviderReducer;
