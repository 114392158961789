import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetchProjectDetails } from '../../store/actions/ProjectActions';
import ProjectView from './ProjectView';
import { ClipLoader } from 'react-spinners';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';

const ProjectDetailsPage: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [projectDetails,setProjectDetails] = useState<any>()
  const [loading,setLoading] = useState(false)

  const fetchDetails = useCallback(async () => {
      const details = await dispatch(fetchProjectDetails({ ProjectID: projectId }));
      console.log(details, "detailssss");
      if (details) {
        setProjectDetails(details)
        setLoading(false)
      }
      
  },[dispatch,projectId])
 
  useEffect(() => {
    setLoading(true)
    fetchDetails()
  }, [dispatch, fetchDetails]);

  const handleClose = () => {
    history.push('/projects');
  };

  useEffect(() => {
    if (projectDetails) {
      setBreadcrumbs([
        { name: 'Projects', href: '/projects', current: false },
        { name: projectDetails.ProjectName || `Project ${projectId}`, href: `/projects/${projectId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [projectDetails, projectId, setBreadcrumbs]);

  if (loading || !projectDetails) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={loading} size={30} />
      </div>
    );
  }

  return (
    <div className="p-6">
      <ProjectView project={projectDetails} onClose={handleClose} />
    </div>
  );
};

export default ProjectDetailsPage;
