import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { fetchTaskDetails, updateTask } from '../../store/actions/TasksActions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import Button from '../shared/TailwindComponents/Button';
import Badge from '../shared/TailwindComponents/Badge';
import { getUserType, getUserID } from '../../store/actions/ChatAction';
import dayjs from 'dayjs';
import { BadgeColor } from '../shared/TailwindComponents/types/badgeTypes';
import { useBreadcrumbs } from '../../contexts/BreadcrumbsContext';
import { ClipLoader } from 'react-spinners';

interface TaskDetailParams {
  taskId: string;
}

const TaskDetail: React.FC = () => {
  const { taskId } = useParams<TaskDetailParams>();
  const dispatch = useAppDispatch();
  const userType = getUserType();
  const userId = getUserID();
  const { setBreadcrumbs } = useBreadcrumbs();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);


  // const { taskDetails, loading, error } = useSelector((state: RootState) => state.tasks);

  const [taskDetails, setTaskDetails] = useState<any>()
  const [loading, setLoading] = useState(false)

  const fetchDetails = useCallback(async () => {
    const details = await dispatch(fetchTaskDetails(taskId));
    if (details) {
      setTaskDetails(details)
      setLoading(false)
    }

  }, [dispatch, taskId])

  useEffect(() => {
    setLoading(true)
    fetchDetails()
  }, [dispatch, taskId]);

  useEffect(() => {
    if (taskDetails) {
      setBreadcrumbs([
        { name: 'Tasks', href: '/tasks', current: false },
        { name: taskDetails.TaskTitle || 'Task Details', href: `/tasks/${taskId}`, current: true },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [taskDetails, taskId, setBreadcrumbs]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      TaskTitle: taskDetails?.TaskTitle || '',
      TaskDescription: taskDetails?.TaskDescription || '',
      TaskDeadline: taskDetails?.TaskDeadline
        ? dayjs(taskDetails.TaskDeadline).toISOString()
        : '',
    },
    validationSchema: Yup.object({
      TaskTitle: Yup.string().required('Title is required'),
      TaskDescription: Yup.string().required('Description is required'),
      TaskDeadline: Yup.date().required('Deadline is required'),
    }),
    onSubmit: async (values) => {
      try {
        await dispatch(
          updateTask({
            TaskID: taskId,
            TaskTitle: values.TaskTitle,
            TaskDescription: values.TaskDescription,
            TaskDeadline: values.TaskDeadline,
          })
        );
        // Optionally, fetch the updated task details
        dispatch(fetchTaskDetails(taskId));
      } catch (error) {
        console.error('Error updating task:', error);
      }
    },
  });

  // if (loading || !taskDetails) {
  //   return <div>Loading task details...</div>;
  // }

  if (loading || !taskDetails) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={loading} size={30} />
      </div>
    );
  }
  // if (error) {
  //   return <div>Error loading task details: {error}</div>;
  // }

  const isEditable =
    userType === 'LawFirmAdmin' ||
    userType === 'BusinessAdmin' ||
    (taskDetails.UserID === userInfo.UserID &&
      taskDetails.AssignedBy === userInfo.UserID);

  // Function to map status to badge color
  const getStatusBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'green';
      case 'active':
        return 'blue';
      case 'pending':
        return 'yellow';
      case 'deleted':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <div className="p-6 mx-2 bg-white dark:bg-gray-800 rounded-lg shadow transition-colors duration-300">
      <h2 className="text-2xl font-bold mb-6">Task Details</h2>
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        {/* Task Title */}
        <Input
          label="Title"
          name="TaskTitle"
          value={formik.values.TaskTitle}
          onChange={formik.handleChange}
          // error={formik.touched.TaskTitle && formik.errors.TaskTitle}
          disabled={!isEditable}
          placeholder="Enter task title..."
          type="text"
        />

        {/* Task Description */}
        <Textarea
          label="Description"
          name="TaskDescription"
          value={formik.values.TaskDescription}
          onChange={formik.handleChange}
          // error={formik.touched.TaskDescription && formik.errors.TaskDescription}
          disabled={!isEditable}
          placeholder="Enter task description..."
          id="TaskDescription"
        />

        {/* Task Deadline */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Deadline
          </label>
          <CustomDateTimePicker
            value={formik.values.TaskDeadline ? dayjs(formik.values.TaskDeadline) : null}
            onChange={(newValue: dayjs.Dayjs | null) => {
              formik.setFieldValue('TaskDeadline', newValue ? newValue.toISOString() : '');
            }}
            minDateTime={dayjs()}
          />
          {formik.touched.TaskDeadline && formik.errors.TaskDeadline && (
            <p className="mt-2 text-sm text-danger-600">{formik.errors.TaskDeadline}</p>
          )}
        </div>

        {/* Other Task Details */}
        <div className="grid grid-cols-2 ">
          {/* Status */}
          <div className="flex flex-col space-y-2 mt-2">
            <span className="font-medium text-gray-700 dark:text-gray-300">Status</span>
            <Badge className='w-fit' color={getStatusBadgeColor(taskDetails.Status as string)}>
              {taskDetails.Status}
            </Badge>
          </div>

          {/* Creation Date */}
          <div className="flex flex-col space-y-2 mt-2">
            <span className="font-medium text-gray-700 dark:text-gray-300">
              Creation Date
            </span>
            <Badge className='w-fit' color='purple'>
              {dayjs(taskDetails.CreationDate).format('MMMM D, YYYY h:mm A')}
            </Badge>
          </div>

          {/* Assigned By */}
          <div className="flex flex-col space-y-2 mt-2">
            <span className="font-medium text-gray-700 dark:text-gray-300">
              Assigned By
            </span>
            <Badge className='w-fit' color="indigo">{taskDetails.AssignedByUserType}</Badge>
          </div>


          {/* Modification Date */}
          <div className="flex flex-col space-y-2 mt-2">
            <span className="font-medium text-gray-700 dark:text-gray-300">
              Modification Date
            </span>
            <Badge className='w-fit' color='pink'>
              {dayjs(taskDetails.ModificationDate).format('MMMM D, YYYY h:mm A')}
            </Badge>
          </div>
        </div>

        {/* Action Buttons */}
        {isEditable && (
          <div className="flex justify-end space-x-4 pt-4">
            <Button type="submit" variant="primary">
              Update Task
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default TaskDetail;
