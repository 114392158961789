import React from 'react';
import { Link } from 'react-router-dom';

const ToolsPage: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Tools</h1>
      <p>Select a section to proceed:</p>
      <ul className="mt-4 space-y-2">
        <li>
          <Link to="/tools/marketplace" className="text-blue-500 hover:underline">
            Marketplace
          </Link>
        </li>
        <li>
          <Link to="/tools/legal-services" className="text-blue-500 hover:underline">
            Legal Services
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default ToolsPage;
