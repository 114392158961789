import React from 'react';
import Textarea from './Textarea';
import FileInputWithPreview from './FileInputWithPreview';

interface TextOrFileInputProps {
  id: string;
  label: string;
  description?: string;
  value: string;
  file: File | null;
  onTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFileChange: (file: File | null) => void;
  error?: string;
}

const TextOrFileInput: React.FC<TextOrFileInputProps> = ({
  id,
  label,
  description,
  value,
  file,
  onTextChange,
  onFileChange,
  error,
}) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {description && <p className="text-sm text-gray-500 mb-2">{description}</p>}
      <div className="flex flex-col space-y-2">
        <Textarea
                  id={id}
                  name={id}
                  placeholder={`Enter ${label.toLowerCase()}...`}
                  value={value}
                  onChange={onTextChange}
                  error={error} label={''}        />
        <FileInputWithPreview
          accept=".pdf,.doc,.docx,.txt"
          file={file}
          setFile={onFileChange}
        />
      </div>
    </div>
  );
};

export default TextOrFileInput;
