import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IEditProjectPayload, IProject } from './ProjectsInterfaces';
import { useAppDispatch, RootState } from '../../store';
import { useSelector } from 'react-redux';
import {
  fetchProjectCategory,
  fetchPriority,
  fetchProjectStatus,
} from '../../store/actions/DropdownActions';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';

import {
  editProject,
  deleteProject,
  approveProject,
  fetchProjectDetails,
} from '../../store/actions/ProjectActions';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { getAiModels, getUserID, getUserType } from '../../store/actions/ChatAction';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';
import { Avatar, Tooltip } from '@mui/material';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid';
import Badge from '../shared/TailwindComponents/Badge';

interface ProjectViewProps {
  project: IProject;
  onClose: () => void;
}

const ProjectView: React.FC<ProjectViewProps> = ({ project, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const userType = getUserType();
  const userId = getUserID();

  // Fetch project details from Redux store
  const projectDetails = useSelector((state: RootState) => state.projects.projectDetails);
  const [localProjectDetails, setLocalProjectDetails] = useState<IProject | null>(null);

  // Fetch necessary data from Redux store
  const categoriesData = useSelector((state: RootState) => state.dropdown.projectCategory) || [];
  const prioritiesData = useSelector((state: RootState) => state.dropdown.priority) || [];
  const projectStatus = useSelector((state: RootState) => state.dropdown.projectStatus) || [];
  const employees = useSelector((state: RootState) => Object.values(state.employees.employees)) || [];
  const servicesData = useSelector((state: RootState) => state.model.services) || [];

  // Determine if the user is the project lead or an admin
  const isProjectLead = localProjectDetails?.ProjectLead?.ProjectLeadID === userId;
  const isAdmin = userType === 'BusinessAdmin';

  // Determine if the user can approve the project
  const canApprove = isAdmin || isProjectLead;

  // Determine if the user can edit the project
  const canEdit = isAdmin || isProjectLead;

  // Determine if the user can delete the project
  const canDelete = isAdmin;

  // Fetch project details on component mount
  // useEffect(() => {
  //   dispatch(fetchProjectDetails({ ProjectID: project.ProjectID }));
  // }, [dispatch,project.ProjectID]);

  // Update local state when project details are fetched
  useEffect(() => {
    if (project) {
      setLocalProjectDetails(project);
    }
  }, [dispatch,project]);

  // Fetch dropdown options and employees if not already available
  useEffect(() => {
    if (categoriesData.length === 0) {
      dispatch(fetchProjectCategory());
    }
    if (prioritiesData.length === 0) {
      dispatch(fetchPriority());
    }
    if (projectStatus.length === 0) {
      dispatch(fetchProjectStatus());
    }
    if (employees.length === 0) {
      dispatch(fetchAllEmployees());
    }
    if (servicesData.length === 0) {
      dispatch(getAiModels());
    }
  }, [
    dispatch,
    categoriesData.length,
    prioritiesData.length,
    projectStatus.length,
    employees.length,
    servicesData.length,
  ]);

  // Function to save changes (used when editing multiple fields)
  const handleSaveChanges = async () => {
    if (!canEdit) return;
    if (!localProjectDetails) return;

    // const data: IEditProjectPayload = {
    //   ProjectID: localProjectDetails.ProjectID,
    //   UserType: userType,
    //   ServiceAllocation: localProjectDetails.AllocatedServices.map((service) => ({
    //     ServiceAmount: service.ServiceAmount,
    //     ServiceType: service.ServiceType,
    //   })),
    //   ProjectDescription: localProjectDetails.ProjectDescription,
    //   CategoryType: localProjectDetails.CategoryType,
    //   EndDate: localProjectDetails.EndDate,
    //   PriorityLevel: localProjectDetails.PriorityLevel,
    //   StatusID: localProjectDetails.StatusID,
    //   ProjectApprover: localProjectDetails.ProjectApprover?.ApproverID || '',
    //   ProjectLead: localProjectDetails.ProjectLead?.LeadID || '',
    //   ProjectMembers: localProjectDetails.ProjectMembers.map((member) => member.MemberID),
    //   ProjectName: localProjectDetails.ProjectName,
    //   StartDate: localProjectDetails.StartDate,
    // };
    const data: IEditProjectPayload = {
      ProjectID: localProjectDetails.ProjectID,
      UserID: localProjectDetails.UserID || 0, // Assuming UserID is included in localProjectDetails
      UserType: userType,
      ServiceAllocations: localProjectDetails.AllocatedServices.map((service) => ({
        AllocatedQueries: service.AllocatedQueries,
        AllocationID: service.AllocationID,
        EndDate: service.EndDate,
        IsActive: service.IsActive,
        RemainingQueries: service.RemainingQueries,
        ServiceID: service.ServiceID,
        ServiceName: service.ServiceName || null,
        StartDate: service.StartDate,
        AddQueries: service.AddQueries || 0
      })),
      ProjectDescription: localProjectDetails.ProjectDescription || '',
      CategoryType: localProjectDetails.CategoryType || '',
      CategoryName: localProjectDetails.CategoryName || '',
      EndDate: localProjectDetails.EndDate || '',
      PriorityLevel: localProjectDetails.PriorityLevel || '',
      PriorityName: localProjectDetails.PriorityName || '',
      Status: localProjectDetails.Status || '',
      StatusID: localProjectDetails.StatusID || '',
      ProjectApproverID: localProjectDetails.ProjectApprover?.ProjectApproverID || '',
      ProjectLeadID: localProjectDetails.ProjectLead?.ProjectLeadID || '',
      ProjectMembers: localProjectDetails.ProjectMembers.map((member) => ({
        MemberID: member.MemberID,
        MemberName: member.MemberName,
        Role: member.Role,
      })),
      ProjectName: localProjectDetails.ProjectName || '',
      StartDate: localProjectDetails.StartDate || '',
      OrganizationID: localProjectDetails.OrganizationID || 0, // Assuming OrganizationID is included in localProjectDetails
    };
    try {
      const result = await dispatch(editProject(data));
      if (result) {
        setIsEditing(false);
        const response = await dispatch(fetchProjectDetails({ ProjectID: localProjectDetails.ProjectID }));
        setLocalProjectDetails(response)
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Project updated successfully!'),
            modalType: 'success',
            duration: 3000,
          })
        );
      }
    } catch (error) {
      console.error('Error updating project:', error);
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Failed to update project.'),
          modalType: 'error',
          duration: 3000,
        })
      );
    }
  };

  // Function to handle field changes
  const handleFieldChange = (field: string, value: any) => {
    if (!canEdit) return;
    if (localProjectDetails) {
      setLocalProjectDetails({
        ...localProjectDetails,
        [field]: value,
      });
    }
  };

  // Function to handle deleting the project
  const handleDeleteProject = async () => {
    if (!canDelete) return;
    const confirmDelete = window.confirm(t('Are you sure you want to delete this project?'));
    if (confirmDelete) {
      const success = await dispatch(deleteProject({ ProjectID: project.ProjectID }));
      if (success) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('Project deleted successfully!'),
            modalType: 'success',
            duration: 3000,
          })
        );
        onClose();
      }
    }
  };

  // Function to handle approving the project
  const handleApproveProject = async () => {
    if (!canApprove) return;
    const success = await dispatch(approveProject({ ProjectID: project.ProjectID }));
    if (success) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t('Project approved successfully!'),
          modalType: 'success',
          duration: 3000,
        })
      );
      dispatch(fetchProjectDetails({ ProjectID: project.ProjectID }));
    }
  };

  // Functions to handle adding/removing members
  const handleRemoveMember = (memberID: string) => {
    if (!canEdit) return;
    setLocalProjectDetails((prevDetails) => {
      if (prevDetails) {
        return {
          ...prevDetails,
          ProjectMembers: prevDetails.ProjectMembers.filter(
            (member) => member.MemberID !== memberID
          ),
        };
      }
      return prevDetails;
    });
  };

  // Handle date changes
  const handleDateChange = (field: 'StartDate' | 'EndDate', date: Dayjs | null) => {
    if (!canEdit) return;
    if (localProjectDetails) {
      setLocalProjectDetails({
        ...localProjectDetails,
        [field]: date ? date.toISOString() : '',
      });
    }
  };

  // Function to handle service amount change
  const handleServiceAmountChange = (index: number, amount: number) => {
    if (!canEdit) return;
    setLocalProjectDetails((prevDetails) => {
      if (prevDetails) {
        const updatedServices = [...prevDetails.AllocatedServices];
        updatedServices[index].AddQueries = amount;
        return {
          ...prevDetails,
          AddQueries: updatedServices,
        };
      }
      return prevDetails;
    });
  };

  if (!localProjectDetails) {
    return <div>{t('Loading...')}</div>;
  }

  // console.log(localProjectDetails);

  // Prepare options for dropdowns
  // const projectMemberOptions: DropdownOption[] = localProjectDetails.ProjectMembers.map(
  //   (member) => ({
  //     value: member.MemberID,
  //     label: member.MemberName,
  //   })
  // );

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded shadow-md dark:shadow-dark-md">
      <div className="flex items-center justify-between mb-6">
        {isEditing ? (
          <input
            type="text"
            value={localProjectDetails.ProjectName}
            onChange={(e) => handleFieldChange('ProjectName', e.target.value)}
            className="text-xl font-semibold border rounded-md px-2 py-1 w-full"
          />
        ) : (
          <h2 className="text-xl font-semibold">{localProjectDetails.ProjectName}</h2>
        )}
        <div className="flex space-x-2">
          {canEdit && !isEditing && (
            <Button variant="primary" onClick={() => setIsEditing(true)}>
              {t('Edit')}
            </Button>
          )}
          {isEditing && (
            <>
              <Button variant="primary" onClick={handleSaveChanges}>
                {t('Save Changes')}
              </Button>
              <Button variant="secondary" onClick={() => setIsEditing(false)}>
                {t('Cancel')}
              </Button>
            </>
          )}
          {canDelete && (
            <Button variant="destructive" onClick={handleDeleteProject}>
              {t('Delete')}
            </Button>
          )}
        </div>
      </div>

      {/* Status, Priority, Category */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {/* Status */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Status')}</label>
          {isEditing ? (
            <Dropdown
              options={projectStatus}
              placeholder={t('Select Status')}
              value={localProjectDetails.StatusID}
              onSelect={(option) => handleFieldChange('StatusID', option.value)}
            />
          ) : (
            <>
             
              <Badge color='indigo'>{localProjectDetails.Status}</Badge>
            </>
            
          )}
        </div>

        {/* Priority */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Priority')}</label>
          {isEditing ? (
            <Dropdown
              options={prioritiesData}
              placeholder={t('Select Priority')}
              value={localProjectDetails.PriorityLevel}
              onSelect={(option) => handleFieldChange('PriorityLevel', option.value)}
            />
          ) : (
            <Badge color='indigo'>{localProjectDetails.PriorityName}</Badge>
          )}
        </div>

        {/* Category */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Category')}</label>
          {isEditing ? (
            <Dropdown
              options={categoriesData}
              placeholder={t('Select Category')}
              value={localProjectDetails.CategoryType}
              onSelect={(option) => handleFieldChange('CategoryType', option.value)}
            />
          ) : (
            <Badge color='indigo'>{localProjectDetails.CategoryName}</Badge>
          )}
        </div>
      </div>

      {/* Description */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700">{t('Description')}</label>
        {isEditing ? (
          <textarea
            value={localProjectDetails.ProjectDescription}
            onChange={(e) => handleFieldChange('ProjectDescription', e.target.value)}
            className="mt-1 block w-full border rounded-md px-2 py-1"
          />
        ) : (
          <p className="mt-1">{localProjectDetails.ProjectDescription}</p>
        )}
      </div>

      {/* Start Date and End Date */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* Start Date */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Start Date')}</label>
          {isEditing ? (
            <CustomDateTimePicker
              value={dayjs(localProjectDetails.StartDate)}
              onChange={(date) => handleDateChange('StartDate', date)}
              dateOnly={true}
            />
          ) : (
            <p className="mt-1">{dayjs(localProjectDetails.StartDate).format('YYYY-MM-DD')}</p>
          )}
        </div>

        {/* End Date */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('End Date')}</label>
          {isEditing ? (
            <CustomDateTimePicker
              value={dayjs(localProjectDetails.EndDate)}
              onChange={(date) => handleDateChange('EndDate', date)}
              dateOnly={true}
            />
          ) : (
            <p className="mt-1">{dayjs(localProjectDetails.EndDate).format('YYYY-MM-DD')}</p>
          )}
        </div>
      </div>

      {/* Project Members */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700">{t('Project Members')}</label>
        <div className="flex flex-wrap mt-2">
          {localProjectDetails.ProjectMembers.filter((member) => member.Role === "Member").map((member) => (
            <div key={member.MemberID + member.Role} className="flex items-center mr-4 mb-2">
              <Tooltip title={member.MemberName}>
                <Avatar className="bg-gray-500 text-white">
                  {member.MemberName
                    .split(' ')
                    .map((n) => n[0])
                    .join('')
                    .toUpperCase()}
                </Avatar>
              </Tooltip>
              {isEditing && (
                <button
                  type="button"
                  onClick={() => handleRemoveMember(member.MemberID)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Project Lead and Approver */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        {/* Project Lead */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Project Lead')}</label>
          {localProjectDetails.ProjectLead ? (
            <div className="flex items-center mt-2">
              <Avatar className="bg-gray-500 text-white">
                {localProjectDetails.ProjectLead.ProjectLeadName
                  .split(' ')
                  .map((n) => n[0])
                  .join('')
                  .toUpperCase()}
              </Avatar>
              <span className="ml-2">{localProjectDetails.ProjectLead.ProjectLeadName}</span>
            </div>
          ) : (
            <p className="mt-1">N/A</p>
          )}
        </div>

        {/* Project Approver */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('Project Approver')}
          </label>
          {localProjectDetails.ProjectApprover ? (
            <div className="flex items-center mt-2">
              <Avatar className="bg-gray-500 text-white">
                {localProjectDetails.ProjectApprover.ProjectApproverName
                  .split(' ')
                  .map((n) => n[0])
                  .join('')
                  .toUpperCase()}
              </Avatar>
              <span className="ml-2">{localProjectDetails.ProjectApprover.ProjectApproverName}</span>
            </div>
          ) : (
            <p className="mt-1">N/A</p>
          )}
        </div>
      </div>

      {/* Services */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700">{t('Services')}</label>
        <div className="space-y-4 mt-2">
          {localProjectDetails.AllocatedServices.map((service, index) => (
            <div key={index} className="flex items-center space-x-4">
              <span className="flex-grow">
                {service.ServiceName || t('Unknown Service')}
              </span>
              {isEditing ? (
                <>
                  <h3>Total</h3>
                  <input
                    type="number"
                    min={service.AllocatedQueries}
                    value={service.AllocatedQueries}
                    disabled
                    // onChange={(e) =>
                    //   handleServiceAmountChange(index, parseInt(e.target.value, 10))
                    // }
                    className="border rounded-md px-2 py-1 w-20"
                  />
                </>
              ) : (
                <>
                  <h3>Total</h3>
                  <span>{service.AllocatedQueries}</span>
                </>
              )}

              {isEditing ? (
                <>
                  <h3>Remaining</h3>
                  <input
                    type="number"
                    min="1"
                    value={service.RemainingQueries}
                    disabled
                    // onChange={(e) =>
                    //   handleServiceAmountChange(index, parseInt(e.target.value, 10))
                    // }
                    className="border rounded-md px-2 py-1 w-20"
                  />
                </>
              ) : (
                <>
                  <h3>Remaining</h3>
                  <span>{service.RemainingQueries}</span>
                </>
              )}

              {isEditing && (
                <>
                  <h3>Add</h3>
                  <input
                    type="number"
                    min='1'
                    value={service.AddQueries}
                    onChange={(e) =>
                      handleServiceAmountChange(index, parseInt(e.target.value, 10))
                    }
                    className="border rounded-md px-2 py-1 w-20"
                  />
                </>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Action Buttons */}
      {!isEditing && (
        <div className="flex justify-end space-x-2">
          {canApprove && (
            <Button variant="success" onClick={handleApproveProject}>
              {t('Approve')}
            </Button>
          )}
          <Button variant="secondary" onClick={onClose}>
            {t('Close')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ProjectView;
