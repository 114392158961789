import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import FilterSearchBar from '../../shared/TailwindComponents/FilterSearchBar';
import Badge from '../../shared/TailwindComponents/Badge';
import { formatCamelCase } from '../../../utils/functions';
import { getMyEngagements } from '../../../store/actions/marketplace2/MarketplacReceiver/MarketplaceReceiverActions';

const ClientEngagementsList: React.FC = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredEngagements, setFilteredEngagements] = useState<any[]>([]);
  const [sortKey, setSortKey] = useState<'CaseID' | 'CaseType' | 'Status'>('CaseID');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const dispatch = useAppDispatch();
  const engagements = useSelector((state: RootState) => state.marketplaceReceiver.engagements) as any[];
  const isLoading = useSelector((state: RootState) => state.marketplaceReceiver.isLoading);
  const error = useSelector((state: RootState) => state.marketplaceReceiver.error);

  useEffect(() => {
    dispatch(getMyEngagements());
  }, [dispatch]);

  const filters = [
    {
      id: 'Status',
      name: 'Status',
      options: [
        { value: 'ProposalSubmitted', label: 'Proposal Submitted' },
        { value: 'EngagementOngoing', label: 'Engagement Ongoing' },
        { value: 'ProposalDeclined', label: 'Proposal Declined' },
        // ... other statuses
      ],
    },
    // Add more filters if needed
  ];

  const sortOptions = [
    { value: 'CaseID', label: 'Case ID', current: sortKey === 'CaseID' },
    { value: 'CaseType', label: 'Case Type', current: sortKey === 'CaseType' },
    { value: 'Status', label: 'Status', current: sortKey === 'Status' },
    // ... other sort options
  ];

  const handleFilterChange = (filterName: string, values: string[]) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterName]: values,
    }));
  };

  const handleSearchChange = (query: string) => {
    setSearchTerm(query);
  };

  const handleSortChange = (value: string) => {
    setSortKey(value as any);
  };

  useEffect(() => {
    let filtered = [...engagements];

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter((item) =>
        item.CaseID.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply selected filters
    if (Object.keys(selectedFilters).length > 0) {
      Object.entries(selectedFilters).forEach(([filterName, values]) => {
        if (filterName === 'Status') {
          filtered = filtered.filter((item) => values.includes(item.Status));
        }
        // Handle other filters
      });
    }

    // Sort the engagements
    filtered.sort((a, b) => {
      let aValue: any = a[sortKey];
      let bValue: any = b[sortKey];

      if (typeof aValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredEngagements(filtered);
  }, [engagements, searchTerm, sortKey, sortOrder, selectedFilters]);

  const columns = ['LawyerName', 'CaseType', 'Status'];

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <ClipLoader color="#000" loading={true} size={30} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  const handleRowClick = (engagement: any) => {
    history.push({
      pathname: `/marketplace/engagement/${engagement.EngagementID}`,
      state: { engagement },
    });
  };

  return (
    <div className="px-4">
      <div className="mb-4">
        <FilterSearchBar
          filters={filters}
          sortOptions={sortOptions}
          selectedFilters={selectedFilters}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortChange}
          onSearchChange={handleSearchChange}
          sortValue={sortKey}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              {columns.map((col) => (
                <th
                  key={col}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-200 uppercase tracking-wider"
                >
                  {formatCamelCase(col)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
            {filteredEngagements.map((engagement) => (
              <tr
                key={engagement.EngagementID}
                onClick={() => handleRowClick(engagement)}
                className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                  {engagement.LawyerName || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                  {engagement.CaseType || 'N/A'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <Badge color={getStatusColor(engagement.Status)}>
                    {formatCamelCase(engagement.Status)}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredEngagements.length === 0 && (
          <div className="text-center py-6">
            <p className="text-gray-500">No engagements found.</p>
          </div>
        )}
      </div>
    </div>
  );

  function getStatusColor(status: string): 'green' | 'yellow' | 'red' | 'gray' | 'blue' {
    switch (status) {
      case 'EngagementOngoing':
        return 'blue';
      case 'Accepted':
      case 'InvitationAccepted':
        return 'green';
      case 'Pending':
      case 'AccessRequested':
      case 'ProposalSubmitted':
      case 'Invited':
        return 'yellow';
      case 'Declined':
      case 'InvitationDeclined':
        return 'red';
      default:
        return 'gray';
    }
  }
};

export default ClientEngagementsList;
