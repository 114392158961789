import React from 'react';
import { getUserType, isBetaEnv } from "../../../utils/authUtils";
import LawyersList from "../Client/LawyersList";
import PublishedCasesList from "../Lawyer/PublishedCasesList";
import Tabs from '../../shared/TailwindComponents/Tabs';
import { useHistory, useLocation } from 'react-router-dom';
import ClientEngagementsList from '../Client/ClientEngagementsList';
import LawyerEngagementsList from '../Lawyer/LawyerEngagementsList';
import LawyerProfile from '../Lawyer/LawyerProfile';
import LawFirmProfile from '../Lawyer/LawFirmProfile';

const Marketplace: React.FC = () => {
  const userType = getUserType();
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get('tab') || (userType.includes('Law') ? 'cases' : 'lawyers');

  const setActiveTab = (tab: string) => {
    queryParams.set('tab', tab);
    history.push({ search: queryParams.toString() });
  };

  let tabs: Array<{ label: string; value: string }> = [];
  let content: React.ReactNode = null;

  if (
    userType === 'IndividualClient' ||
    userType === 'BusinessAdmin' ||
    userType === 'BusinessEmployee'
  ) {
    // Set default active tab for clients
    if (activeTab === 'default') setActiveTab('lawyers');

    tabs = [
      { label: 'Lawyers', value: 'lawyers' },
      { label: 'Engagements', value: 'engagements' },
    ];

    if (activeTab === 'lawyers') content = <LawyersList />;
    else if (activeTab === 'engagements') content = <ClientEngagementsList />;
  } else if (
    userType === 'LawFirmAdmin' ||
    userType === 'LawFirmEmployee' ||
    userType === 'IndependentLawyer'
  ) {
    // Set default active tab for lawyers
    if (activeTab === 'default') setActiveTab('cases');

    tabs = [
      { label: 'Published Cases', value: 'cases' },
      { label: 'Engagements', value: 'engagements' },
      { label: 'My Profile', value: 'profile' },
    ];

    // Add "Company Profile" tab for LawFirmAdmin
    if (userType === 'LawFirmAdmin') {
      tabs.push({ label: 'Company Profile', value: 'companyProfile' });
    }

    if (activeTab === 'cases') content = <PublishedCasesList />;
    else if (activeTab === 'engagements') content = <LawyerEngagementsList />;
    else if (activeTab === 'profile') content = <LawyerProfile />;
    else if (activeTab === 'companyProfile' && userType === 'LawFirmAdmin') content = <LawFirmProfile />;
  } else {
    content = <div>You are not authorized to view this page.</div>;
  }

  return (
    <>
      {isBetaEnv ? (
        <div className="flex h-calc-100vh-90px  justify-center items-center text-neutral-400">Coming soon</div>
      ) : (
        <div className="px-4">
          <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
          {content}
        </div>
      )}
    </>
  );
};

export default Marketplace;
