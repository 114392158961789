import React, { useEffect, useState } from 'react';
import Input from '../shared/TailwindComponents/Input';
import Textarea from '../shared/TailwindComponents/Textarea';
import Dropdown, { DropdownOption } from '../shared/TailwindComponents/Dropdown';
import Button from '../shared/TailwindComponents/Button';
import { RootState, useAppDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchPriority, fetchProjectCategory, fetchProjectStatus } from '../../store/actions/DropdownActions';
import { showFeedbackModal } from '../../store/actions/UserFeedbackActions';
import { addProject, fetchProjects } from '../../store/actions/ProjectActions';
import { Employee } from '../Management/Employees/types';
import { fetchAllEmployees } from '../../store/actions/organization/employeeActions';
import { getAiModels } from '../../store/actions/ChatAction';
import { Dayjs } from 'dayjs';
import CustomDateTimePicker from '../shared/CustomDateTimePicker';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/solid';

interface ProjectCreateProps {
  onCancel: () => void;
  onSuccess: () => void;
}

const ProjectCreate: React.FC<ProjectCreateProps> = ({ onCancel, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    projectName: '',
    projectDescription: '',
    projectDeadline: null as Dayjs | null,
    projectStartDate: null as Dayjs | null,
    category: '',
    priority: '',
    status: '',
    projectMembers: [] as string[], // Employee IDs
    projectLead: '',
    projectApprover: '',
    services: [] as {
      ServiceType: string;
      ServiceAmount: number;
      total_available_queries: number | 'Unlimited';
    }[],
  });

  const categoriesData = useSelector((state: RootState) => state.dropdown.projectCategory) || [];
  const prioritiesData = useSelector((state: RootState) => state.dropdown.priority) || [];
  const projectStatus = useSelector((state: RootState) => state.dropdown.projectStatus) || [];
  const employees = useSelector((state: RootState) => state.employees.employees) || {};
  const employeeArray = Object.values(employees);
// Fetch services and handle loading state
const [loadingServices, setLoadingServices] = useState(true);

const [servicesData, setServicesData] = useState([]);

useEffect(() => {
  const fetchServices = async () => {
    try {
      const response = await dispatch(getAiModels());
      if (response) {
        setServicesData(response);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoadingServices(false);
    }
  };
  fetchServices();
}, [dispatch]);

const allowedServices = [
  'Law Research',
  'Tax Research',
  'Contract Analysis',
  'Legal Case Mapping',
];

const serviceOptions = servicesData
  .filter((s: any) => {
    if (s.model_display_name === 'Legal Case Mapping') {
      return s.model_name === 'LegalCaseMappingBusiness';
    }
    return allowedServices.includes(s.model_display_name);
  })
  .map((s: any) => ({
    value: s.model_id,
    label: `${s.model_display_name} (${s.total_available_queries})`,
    total_available_queries: s.total_available_queries,
  }));


  useEffect(() => {
    dispatch(fetchAllEmployees());
    dispatch(fetchProjectCategory());
    dispatch(fetchPriority());
    dispatch(fetchProjectStatus());
  }, [dispatch]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.projectName) newErrors.projectName = t('Project Name is required');
    if (!formData.projectDescription) newErrors.projectDescription = t('Project Description is required');
    if (formData.projectMembers.length === 0) newErrors.projectMembers = t('At least one project member is required');
    if (!formData.projectLead) newErrors.projectLead = t('Project Lead is required');
    formData.services.forEach((service, index) => {
      const totalAvailable = service.total_available_queries;
  
      if (!service.ServiceType)
        newErrors[`service_${index}_type`] = t('Service type is required');
  
      if (!service.ServiceAmount)
        newErrors[`service_${index}_amount`] = t('Service amount is required');
  
      if (
        totalAvailable !== 'Unlimited' &&
        service.ServiceAmount > parseInt(totalAvailable.toString(), 10)
      ) {
        newErrors[`service_${index}_amount`] = t(
          `Cannot allocate more than ${totalAvailable} queries for this service`
        );
      }
    });    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelect = (field: string, option: DropdownOption) => {
    setFormData((prev) => ({
      ...prev,
      [field]: option.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const data = {
        ProjectName: formData.projectName,
        ProjectDescription: formData.projectDescription,
        ProjectLeadID: formData.projectLead,
        ProjectApproverID: formData.projectApprover || formData.projectLead,
        CategoryType: formData.category,
        PriorityLevel: formData.priority,
        StartDate: formData.projectStartDate?.toISOString(),
        EndDate: formData.projectDeadline?.toISOString(),
        ServiceAllocation: formData.services,
        UserType: 'BusinessAdmin',
        ProjectMembers: formData.projectMembers,
      };
      const project = await dispatch(addProject(data));
      if (project) {
        dispatch(
          showFeedbackModal({
            showModal: true,
            message: t('New Project Created!'),
            modalType: 'success',
            duration: 3000,
          })
        );
        setFormData({
          projectName: '',
          projectDescription: '',
          projectDeadline: null as Dayjs | null,
          projectStartDate: null as Dayjs | null,
          category: '',
          priority: '',
          status: '',
          projectMembers: [] as string[],
          projectLead: '',
          projectApprover: '',
          services: [] as {
            ServiceType: string;
            ServiceAmount: number;
            total_available_queries: number | 'Unlimited';
          }[],
                  });
        onSuccess();
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const handleAddService = () => {
    setFormData((prev) => ({
      ...prev,
      services: [...prev.services, { ServiceType: '', ServiceAmount: 0, total_available_queries: 0 }],
    }));
  };
  
  const handleServiceSelect = (index: number, option: DropdownOption) => {
    const selectedService = serviceOptions.find((s) => s.value === option.value);
    const updatedServices = [...formData.services];
    updatedServices[index].ServiceType = option.value;
    updatedServices[index].total_available_queries = selectedService
      ? selectedService.total_available_queries
      : 'Unlimited';
    setFormData((prev) => ({
      ...prev,
      services: updatedServices,
    }));
  };  
  
  const handleServiceAmountChange = (index: number, amount: number) => {
    const updatedServices = [...formData.services];
    const totalAvailable = updatedServices[index].total_available_queries;
  
    // Validation
    if (
      totalAvailable !== 'Unlimited' &&
      amount > parseInt(totalAvailable.toString(), 10)
    ) {
      dispatch(
        showFeedbackModal({
          showModal: true,
          message: t(
            `Cannot allocate more than ${totalAvailable} queries for this service.`
          ),
          modalType: 'error',
          duration: 3000,
        })
      );
      return;
    }
  
    updatedServices[index].ServiceAmount = amount;
    setFormData((prev) => ({
      ...prev,
      services: updatedServices,
    }));
  };
  
  
  const handleRemoveService = (index: number) => {
    const updatedServices = [...formData.services];
    updatedServices.splice(index, 1);
    setFormData((prev) => ({
      ...prev,
      services: updatedServices,
    }));
  };
  
  const handleDateChange = (field: 'projectStartDate' | 'projectDeadline', date: Dayjs | null) => {
    setFormData((prev) => ({
      ...prev,
      [field]: date,
    }));
  };

  // Employee options for dropdowns
const employeeOptions = employeeArray.map((employee: Employee) => ({
  value: employee.User.UserUID,
  label: `${employee.User.FirstName} ${employee.User.LastName}`,
}));


  return (
    <div className="px-6">
      <h2 className="text-xl font-semibold mb-4">{t('Create New Project')}</h2>
      <form onSubmit={handleSubmit}>
        {/* Project Name */}
        <div className="mb-4">
          <Input
            type="text"
            name="projectName"
            label={t('Project Name')}
            placeholder={t('Enter project name')}
            value={formData.projectName}
            onChange={handleChange}
            error={errors.projectName}
          />
        </div>

        {/* Project Description */}
        <div className="mb-4">
          <Textarea
            id="projectDescription"
            name="projectDescription"
            label={t('Project Description')}
            placeholder={t('Enter project description')}
            value={formData.projectDescription}
            onChange={handleChange}
            error={errors.projectDescription}
          />
        </div>

        {/* Category */}
        <div className="mb-4">
          <Dropdown
            label={t('Category')}
            options={categoriesData}
            placeholder={t('Select Category')}
            onSelect={(option) => handleSelect('category', option)}
            error={errors.category}
          />
        </div>

        {/* Priority */}
        <div className="mb-4">
          <Dropdown
            label={t('Priority')}
            options={prioritiesData}
            placeholder={t('Select Priority')}
            onSelect={(option) => handleSelect('priority', option)}
            error={errors.priority}
          />
        </div>

      {/* Start Date */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">{t('Start Date')}</label>
        <CustomDateTimePicker
          value={formData.projectStartDate}
          onChange={(date) => handleDateChange('projectStartDate', date)}
          dateOnly={true}
        />
        {errors.projectStartDate && (
          <p className="text-red-500 text-xs mt-1">{errors.projectStartDate}</p>
        )}
      </div>

      {/* Deadline */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">{t('Deadline')}</label>
        <CustomDateTimePicker
          value={formData.projectDeadline}
          onChange={(date) => handleDateChange('projectDeadline', date)}
          dateOnly={true}
          minDateTime={formData.projectStartDate as Dayjs}
        />
        {errors.projectDeadline && (
          <p className="text-red-500 text-xs mt-1">{errors.projectDeadline}</p>
        )}
      </div>

        {/* Project Members */}
        <div className="mb-4">
          <Dropdown
            label={t('Project Members')}
            options={employeeOptions.filter((option) => !formData.projectMembers.includes(option.value))}
            placeholder={t('Select Project Members')}
            onSelect={(option) => {
              setFormData((prev) => ({
                ...prev,
                projectMembers: [...prev.projectMembers, option.value],
              }));
            }}
            multiple={false}
            error={errors.projectMembers}
          />
            {/* Display selected members with option to remove */}
            <div className="selected-members flex flex-wrap mt-2">
              {formData.projectMembers.map((memberId: any) => {
                const member = employeeArray.find(
                  (emp) => emp.User.UserUID === memberId
                );
                return (
                  <div key={memberId} className="member-item flex items-center mr-2 mb-2">
                    <span className="mr-2">
                      {member
                        ? `${member.User.FirstName} ${member.User.LastName}`
                        : t('Unknown Member')}
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        const updatedMembers = formData.projectMembers.filter(
                          (id) => id !== memberId
                        );
                        setFormData((prev) => ({ ...prev, projectMembers: updatedMembers }));
                      }}
                      className="destructive-button"
                    >
                      {t('Remove')}
                    </button>
                  </div>
                );
              })}
            </div>
        </div>

          {/* Project Lead */}
          <div className="mb-4">
            <Dropdown
              label={t('Project Lead')}
              options={formData.projectMembers.map((memberId: any) => {
                const member = employeeArray.find(
                  (emp) => emp.User.UserUID === memberId
                );
                return {
                  value: memberId,
                  label: member
                    ? `${member.User.FirstName} ${member.User.LastName}`
                    : t('Unknown Member'),
                };
              })}
              placeholder={t('Select Project Lead')}
              onSelect={(option) => handleSelect('projectLead', option)}
              error={errors.projectLead}
            />
          </div>

          {/* Project Approver */}
          <div className="mb-4">
            <Dropdown
              label={t('Project Approver')}
              options={formData.projectMembers.map((memberId: any) => {
                const member = employeeArray.find(
                  (emp) => emp.User.UserUID === memberId
                );
                return {
                  value: memberId,
                  label: member
                    ? `${member.User.FirstName} ${member.User.LastName}`
                    : t('Unknown Member'),
                };
              })}
              placeholder={t('Select Project Approver')}
              onSelect={(option) => handleSelect('projectApprover', option)}
              error={errors.projectApprover}
            />
          </div>

          {/* Service Allocation */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {t('Service Allocation')}
            </label>
            {errors.services && (
              <p className="text-red-500 text-xs mt-1">{errors.services}</p>
            )}

            <div className="space-y-4">
            {formData.services.map((service, index) => (
              <div
                key={index}
                className="flex flex-wrap items-center space-x-4 border p-4 rounded-md"
              >
                <div className="flex-grow min-w-[200px]">
                  <Dropdown
                    options={serviceOptions}
                    placeholder={t('Select Service')}
                    onSelect={(option) => handleServiceSelect(index, option)}
                    value={service.ServiceType}
                    error={errors[`service_${index}_type`]}
                  />
                </div>
                <div className="min-w-[100px]">
                  <Input
                    type="number"
                    name={`serviceAmount_${index}`}
                    placeholder={t('Amount')}
                    value={service.ServiceAmount}
                    onChange={(e) =>
                      handleServiceAmountChange(index, parseInt(e.target.value))
                    }
                    min={0}
                    error={errors[`service_${index}_amount`]}
                  />
                  {/* Display Available Queries */}
                  <p className="text-sm text-gray-500">
                     {service.total_available_queries} {t('Queries Available')}
                  </p>
                </div>
                <button
                  type="button"
                  onClick={() => handleRemoveService(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            ))}

              <button
                type="button"
                onClick={handleAddService}
                className="flex items-center text-blue-500 hover:text-blue-700"
              >
                <PlusCircleIcon className="h-5 w-5 mr-1" />
                {t('Add Service')}
              </button>
            </div>
          </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button type="submit" variant="primary">
            {t('Create')}
          </Button>
          <Button type="button" variant="secondary" onClick={onCancel} className="ml-2">
            {t('Cancel')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProjectCreate;