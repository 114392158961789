import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './ChatInterface.css';
import { RootState, useAppDispatch } from '../../store';
import { sendMessage } from '../../store/actions/HelpAction';
import { useTranslation } from 'react-i18next';
import { showAddTaskModal } from '../../store/actions/modalActions';
import { BotAction, ChatMessage } from '../../store/reducer/HelpReducer';
import { useHistory } from 'react-router-dom';
import { setSelectedModel } from '../../store/actions/ChatAction';

const ChatInterface: React.FC = () => {
  const [userInput, setUserInput] = useState('');
  const [chatStarted, setChatStarted] = useState(false);
  const [displayedTexts, setDisplayedTexts] = useState<(string | JSX.Element)[]>([]);
  const dispatch = useAppDispatch();
  const messages: ChatMessage[] = useSelector((state: RootState) => state.help.messages);
  const loading = useSelector((state: RootState) => state.help.loadingChat);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(400); 
  const [height, setHeight] = useState(400); 
  const minWidth = 350;
  const minHeight = 300;
  const maxWidth = 1000;
  const maxHeight = 650;
  const resizeRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    scrollToBottomIfNeeded();
  }, [messages.length]); 

  useEffect(() => {
    const isUserAtBottom = () => {
      const container = messageEndRef.current?.parentElement;
      if (!container) return false;

      const scrollPosition = container.scrollTop + container.clientHeight;
      const threshold = container.scrollHeight - 50;
      return scrollPosition >= threshold;
    };

    const scrollToBottomIfNeeded = () => {
      if (isUserAtBottom()) {
        scrollToBottomImmediate();
      }
    };

  }, [messages, messages.length]);

  const formatMessageText = (text: string | { text: string } | undefined) => {
    if (typeof text === 'object' && text?.text) {
      text = text.text;
    }
  
    if (typeof text !== 'string') {
      return <div>Error: Invalid message format.</div>;
    }
  
    const paragraphs = text.split('\n\n').map((paragraph, paragraphIndex) => {
      const lines = paragraph.split('\n').map((line, lineIndex) => {
        let jsxElements: (JSX.Element | string)[] = [];
  
        if (line.startsWith('### ')) {
          jsxElements.push(<h3 key={`h3-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(4)}</strong></h3>);
        } else if (line.startsWith('#### ')) {
          jsxElements.push(<h4 key={`h4-${paragraphIndex}-${lineIndex}`}><strong>{line.substring(5)}</strong></h4>);
        } else if (line.match(/^\d+\.\s/)) {
          const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<span key={`span-${paragraphIndex}-${lineIndex}-${i}`}></span>);
            } else {
              jsxElements.push(token);
            }
          });
          jsxElements = [<li key={`li-${paragraphIndex}-${lineIndex}`}>{jsxElements}</li>];
        } else {
          const tokens = line.split(/(\*\*.*?\*\*|\*.*?\*|\[doc\d+\])/g);
          tokens.forEach((token, i) => {
            if (token.startsWith('**')) {
              jsxElements.push(<strong key={`strong-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(2, -2)}</strong>);
            } else if (token.startsWith('*')) {
              jsxElements.push(<em key={`em-${paragraphIndex}-${lineIndex}-${i}`}>{token.slice(1, -1)}</em>);
            } else if (token.match(/\[doc\d+\]/)) {
              jsxElements.push(<span key={`span-${paragraphIndex}-${lineIndex}-${i}`}></span>);
            } else {
              jsxElements.push(token);
            }
          });
        }
  
        return <div key={`${paragraphIndex}-${lineIndex}`}>{jsxElements}</div>;
      });
  
      return (
        <div key={paragraphIndex}>
          {lines}
        </div>
      );
    });
  
    return <div>{paragraphs}</div>;
  };

  const scrollToBottomImmediate = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  const scrollToBottomIfNeeded = () => {
    const container = messageEndRef.current?.parentElement;
    if (!container) return;
  
    const scrollPosition = container.scrollTop + container.clientHeight;
    const threshold = container.scrollHeight - 50;
    if (scrollPosition >= threshold) {
      scrollToBottomImmediate();
    }
  };
  
  useEffect(() => {
    // Find the last bot message that needs to be animated
    const lastBotMessageIndex = messages.findIndex(
      (message) => message.sender === 'bot' && !message.animationComplete
    );
  
    if (lastBotMessageIndex === -1) return; // No message to animate
  
    const message = messages[lastBotMessageIndex];
    const fullText = typeof message.text === 'string' ? message.text : message.text.text;
    let currentLength = 0;
  
    const typingInterval = 30; // Typing speed in milliseconds
  
    // Typing animation interval
    const timer = setInterval(() => {
      currentLength += 1;
  
      setDisplayedTexts((prev) => {
        const updatedTexts = [...prev];
        const textSegment = fullText.substring(0, currentLength);
        updatedTexts[lastBotMessageIndex] = formatMessageText(textSegment);
        return updatedTexts;
      });
  
      // Smooth scrolling while typing
      scrollToBottomImmediate();
  
      // If typing animation is complete
      if (currentLength >= fullText.length) {
        clearInterval(timer);
  
        // Update the message state to mark animation as complete
        dispatch({
          type: 'UPDATE_MESSAGE_ANIMATION',
          payload: { index: lastBotMessageIndex, animationComplete: true, showActions: true },
        });
  
        // Ensure all messages are displayed with their actions
        setDisplayedTexts((prev) =>
          prev.map((text, index) => {
            if (index === lastBotMessageIndex) {
              return formatMessageText(fullText);
            }
            return text;
          })
        );
      }
    }, typingInterval);
  
    return () => clearInterval(timer);
  }, [messages]); 
  
  

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, loading]);

  const handleSendMessage = () => {
    if (userInput.trim() === '') return;
    setChatStarted(true);
    dispatch(sendMessage(userInput));
    setDisplayedTexts((prev) => [...prev, userInput]);
    setUserInput('');
    scrollToBottomImmediate(); // Scroll to bottom after sending a message
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = resizeRef.current?.offsetWidth || width;
    const startHeight = resizeRef.current?.offsetHeight || height;

    const doDrag = (dragEvent: MouseEvent) => {
      const newWidth = Math.min(
        Math.max(startWidth - (dragEvent.clientX - startX), minWidth),
        maxWidth
      );
      const newHeight = Math.min(
        Math.max(startHeight + dragEvent.clientY - startY, minHeight),
        maxHeight
      );
      setWidth(newWidth);
      setHeight(newHeight);
    };

    const stopDrag = () => {
      window.removeEventListener('mousemove', doDrag);
      window.removeEventListener('mouseup', stopDrag);
    };

    window.addEventListener('mousemove', doDrag);
    window.addEventListener('mouseup', stopDrag);
  };

  const handleCustomActions = (action: string) => {
    switch (action) {
      case "dispatch(showAddTaskModal())":
        dispatch(showAddTaskModal());
        break;
  
      case "history.push('/marketplace/lawyer/market')":
      case "/marketplace/lawyer/market":
        history.push('/marketplace/lawyer/market');
        break;
  
      case "history.push('/marketplace/lawyer/cases')":
      case "/marketplace/lawyer/cases":
        history.push('/marketplace/lawyer/cases');
        break;
  
      case "dispatch(setSelectedModel (modelname: string))":
        dispatch(setSelectedModel('modelname')); 
        break;
  
      default:
        console.error("Unknown action:", action);
    }
  };
  
  const handleFunctionExecution = (code: string) => {
    handleCustomActions(code);
  };
  
  const handleNavigation = (path: string) => {
    if (path.startsWith('history.push') || path.startsWith('/')) {
      history.push(path);
    } else {
      console.error("Invalid navigation path:", path);
    }
  };
  
  const renderActionButtons = (actions?: BotAction[]) => {
    if (!actions || actions.length === 0) {
      return null;
    }
  
    return (
      <div className="actions-container">
        {actions.map((action, index) => {
          if (action.type === 'link') {
            return (
              <button key={index} onClick={() => handleNavigation(action.content)}>
                Navigate to {action.content}
              </button>
            );
          } else if (action.type === 'function') {
            const hasSimilarLink = actions.some(
              (a) => a.type === 'link' && a.content === action.content
            );
            if (!hasSimilarLink) {
              return (
                <button key={index} onClick={() => handleFunctionExecution(action.content)}>
                  Execute Action
                </button>
              );
            }
          }
          return null;
        })}
      </div>
    );
  };
  
  return (
    <div className='chatbot-interface--wrapper'>
    <div className="chat-interface" style={{ width: `${width}px`, height: `${height}px` }} ref={resizeRef}>
      {chatStarted ? (
        <>
          <div className="message-list--support">
            {messages.map((message, index) => (
              <div key={index} className={`message--supportbot ${message.sender}`}>
                {message.sender === 'bot' && (
                  <>
                    <i className="fas fa-message-bot message-bot-icon"></i>
                    <div>
                      {formatMessageText(
                        typeof message.text === 'object' && 'text' in message.text
                          ? message.text.text
                          : message.text
                      )}
                    </div>
                    {/* Render buttons for each bot message */}
                    {renderActionButtons(message.actions)}
                  </>
                )}
                {message.sender !== 'bot' && (
                  <span>
                    {typeof message.text === 'object' && 'text' in message.text ? message.text.text : message.text}
                  </span>
                )}
              </div>
            ))}
            {loading && (
              <div className="message--supportbot bot" style={{ backgroundColor: loading && '#171717' }}>
                <i className={`fas fa-message-bot message-bot-icon ${loading ? 'typing-indicator' : ''}`}></i>
              </div>
            )}
            <div ref={messageEndRef}></div>
          </div>
        </>
      ) : (
        <div className="front-screen">
          <i className='fas fa-message-bot'></i>
          <p>{t('PONS Support Bot')}</p>
          <span>{t('Here to help you navigate the platform')}</span>
          <span>{t('Search for or ask me anything!')}</span>
        </div>
      )}
      <div className="prompt-bar">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder={t("Type a message...")}
          disabled={loading}
        />
        <button onClick={handleSendMessage} disabled={loading}><i className='fas fa-send'></i></button>
      </div>
    </div>
    <div className="chat-resize-handle" onMouseDown={handleMouseDown}>
      <i className="fa-regular fa-bars-filter"></i>
    </div>
  </div>
  );
};

export default ChatInterface;