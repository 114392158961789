import { AnyAction, Dispatch } from "redux";
import apis from "../../utils/apis";
import axios, { AxiosError } from "axios";
import { getUserID, getUserType } from "./ChatAction";
import { showFeedbackModal } from "./UserFeedbackActions";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "..";
import { BotAction, ChatMessage } from "../reducer/HelpReducer";
import { CREATE_TICKET_FAILURE, CREATE_TICKET_REQUEST, CREATE_TICKET_SUCCESS, FETCH_ALL_TICKETS_FAILURE, FETCH_ALL_TICKETS_REQUEST, FETCH_ALL_TICKETS_SUCCESS, FETCH_ALL_USER_TICKETS_FAILURE, FETCH_ALL_USER_TICKETS_REQUEST, FETCH_ALL_USER_TICKETS_SUCCESS, FETCH_CATEGORIES_FAILURE, FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_HELP_FAILURE, FETCH_HELP_REQUEST, FETCH_HELP_SUCCESS, FETCH_RESOLVED_TICKETS_FAILURE, FETCH_RESOLVED_TICKETS_REQUEST, FETCH_RESOLVED_TICKETS_SUCCESS, FETCH_TICKET_FAILURE, FETCH_TICKET_REQUEST, FETCH_TICKET_SUCCESS, FETCH_UNRESOLVED_TICKETS_FAILURE, FETCH_UNRESOLVED_TICKETS_REQUEST, FETCH_UNRESOLVED_TICKETS_SUCCESS, FETCH_USER_HELP_FAILURE, FETCH_USER_HELP_REQUEST, FETCH_USER_HELP_SUCCESS, RECEIVE_MESSAGE, RESOLVE_TICKET_FAILURE, RESOLVE_TICKET_REQUEST, RESOLVE_TICKET_SUCCESS, SEND_MESSAGE_FAILURE, SEND_MESSAGE_REQUEST, SEND_MESSAGE_SUCCESS } from "../types";
import { ErrorResponse } from "./SignInAction";
import { DropdownOption } from "../../components/shared/TailwindComponents/Dropdown";



export const sendMessage = (userInput: string) => async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  dispatch({ type: SEND_MESSAGE_REQUEST, payload: userInput });

  const formData = new FormData();
  formData.append('UserInput', userInput);
  formData.append('UserID', getUserID());
  formData.append('UserType', getUserType());

  try {
    const response = await apis({
      method: 'POST',
      url: 'support/chat',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    const chatResponse = response.data.ChatResponse;
    if (typeof chatResponse !== 'string') {
      console.error('Invalid ChatResponse format:', chatResponse);
      return;
    }


    const regex = /```json(?:\s*)([\s\S]+?)\s*```/g;

    let match;
    const actions: BotAction[] = [];
    let botInfo = chatResponse;

    // Extract JSON blocks
    while ((match = regex.exec(chatResponse)) !== null) {
      const jsonBlock = match[1]; // Extract the JSON content directly
      botInfo = botInfo.replace(match[0], ''); // Remove JSON block from text
      try {
        const jsonContent = JSON.parse(jsonBlock);

        if (jsonContent.botNavigationLink) {
          const links = Array.isArray(jsonContent.botNavigationLink)
            ? jsonContent.botNavigationLink
            : [jsonContent.botNavigationLink];
          actions.push(
            ...links.map((link: string) => ({
              type: 'link',
              content: link,
            }))
          );
        }

        if (jsonContent.botAction) {
          actions.push({
            type: 'function',
            content: jsonContent.botAction,
          });
        }
      } catch (error) {
        console.error('Error parsing JSON block:', jsonBlock, error);
      }
    }


    const formattedMessage: ChatMessage = {
      text: botInfo.trim(),
      sender: 'bot',
      animationComplete: false,
      actions,
    };

    dispatch({ type: SEND_MESSAGE_SUCCESS });
    dispatch({ type: RECEIVE_MESSAGE, payload: formattedMessage });
  } catch (error: any) {
    dispatch({ type: SEND_MESSAGE_FAILURE, payload: error.message });
  }
};


export const fetchHelp = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_HELP_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "help/getAll"
    });
    dispatch({
      type: FETCH_HELP_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_HELP_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_HELP_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchUserHelp = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_USER_HELP_REQUEST });
  const userType = getUserType();
  try {
    const response = await apis({
      method: "POST",
      url: "help/usertype/getList",
      data: { UserType: userType }
    });
    dispatch({
      type: FETCH_USER_HELP_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_USER_HELP_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_USER_HELP_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchCategories = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_CATEGORIES_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "/support/categories"
    });

    if (response && response.status === 200 && Array.isArray(response.data.categories)) {
      const dataList: DropdownOption[] = response.data.categories.map((item: any) => {
        return {
          value: item.ID,
          label: item.DisplayName || '',
          active: item.active !== undefined ? item.active === true : undefined,
        };
      });      
      dispatch({
        type: FETCH_CATEGORIES_SUCCESS,
        payload: dataList
      });
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_CATEGORIES_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const createTicket = (ticketData: any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: CREATE_TICKET_REQUEST });
  try {
    const response = await apis({
      method: 'POST',
      url: '/support/tickets/create',
      data: ticketData,
    });

    dispatch({
      type: CREATE_TICKET_SUCCESS,
      payload: response.data,
    });
    if (response.status === 200) {
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'Ticket created',
        modalType: 'success',
        duration: 3000,
      }));
      return response
    } else {
      dispatch(handleAxiosError(response))
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: CREATE_TICKET_FAILURE,
        payload: error.response.data,
      });
      dispatch(showFeedbackModal({
        showModal: true,
        message: error.response.data.message || 'Failed to create ticket. Please try again later.',
        modalType: 'error',
        duration: 5000,
      }));
    } else {
      dispatch({
        type: CREATE_TICKET_FAILURE,
        payload: 'An unknown error occurred',
      });
      dispatch(showFeedbackModal({
        showModal: true,
        message: 'An unknown error occurred. Please try again later.',
        modalType: 'error',
        duration: 5000,
      }));
    }
  }
};

export const fetchTicket = (ticketId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_TICKET_REQUEST });
  try {
    const response = await apis({
      method: "POST",
      url: "/support/tickets/get", 
      data: { TicketID: ticketId },
    });
    dispatch({
      type: FETCH_TICKET_SUCCESS,
      payload: response.data.ticket 
    });
    return { payload: response.data.ticket };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_TICKET_FAILURE,
        payload: error.response.data.message || "Failed to fetch ticket"
      });
    } else {
      dispatch({
        type: FETCH_TICKET_FAILURE,
        payload: "An unknown error occurred"
      });
    }
    return { payload: null };
  }
};

export const fetchAllUserTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_ALL_USER_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "/support/tickets",
    });
    dispatch({
      type: FETCH_ALL_USER_TICKETS_SUCCESS,
      payload: response.data.tickets
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_ALL_USER_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_ALL_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
}

export const fetchAllTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_ALL_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "/support/get_all_tickets"
    });
    dispatch({
      type: FETCH_ALL_TICKETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_ALL_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_ALL_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchResolvedTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_RESOLVED_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "/support/get_resolved_tickets"
    });
    dispatch({
      type: FETCH_RESOLVED_TICKETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_RESOLVED_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_RESOLVED_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const fetchUnresolvedTickets = () => async (dispatch: Dispatch) => {
  dispatch({ type: FETCH_UNRESOLVED_TICKETS_REQUEST });
  try {
    const response = await apis({
      method: "GET",
      url: "/support/get_unresolved_tickets"
    });
    dispatch({
      type: FETCH_UNRESOLVED_TICKETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: FETCH_UNRESOLVED_TICKETS_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: FETCH_UNRESOLVED_TICKETS_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};

export const resolveTicket = (ticketId: string) => async (dispatch: Dispatch) => {
  dispatch({ type: RESOLVE_TICKET_REQUEST });
  try {
    const response = await apis({
      method: "POST",
      url: "/support/resolve_ticket",
      data: { ticketId }
    });
    dispatch({
      type: RESOLVE_TICKET_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      dispatch({
        type: RESOLVE_TICKET_FAILURE,
        payload: error.response.data
      });
    } else {
      dispatch({
        type: RESOLVE_TICKET_FAILURE,
        payload: "An unknown error occurred"
      });
    }
  }
};


const handleAxiosError = (response: any) => {
  return async (dispatch: any) => {
    if (axios.isAxiosError(response)) {
      const axiosError = response as AxiosError;
      if (axiosError.response && axiosError.response.data) {
        let errorMessage = axiosError.response.data as ErrorResponse
        dispatch(showFeedbackModal({
          showModal: true,
          message: (errorMessage.message || 'An unknown error occurred'),
          modalType: 'error',
          duration: 3000
        }));
      } else {
        dispatch(showFeedbackModal({
          showModal: true,
          message: ('Network error, please try again'),
          modalType: 'error',
          duration: 3000
        }));
      }
    }
  }
}